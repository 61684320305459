<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Kartu Keluarga</h4></v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->
        <v-dialog v-model="dialog" persistent max-width="1200px">
          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-container class="mt-0">
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <v-text-field
                        color="teal"
                        label="Nomor KK"
                        filled
                        dense
                        maxlength="16"
                        :rules="nokkRules"
                        v-model="editedItem.nokk"
                        :counter="16"
                        required
                      ></v-text-field>

                      <v-row>
                        <v-col cols="6" md="6">
                          <v-select
                            :rules="statuskkRules"
                            v-model="editedItem.status_kk"
                            :items="itemstatuskk"
                            item-text="status_kk"
                            item-value="status_kk"
                            color="teal"
                            label="Status Kartu Keluarga (KK)"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-select
                            :rules="miskinRules"
                            v-model="editedItem.status_miskin"
                            :items="itemsmiskin"
                            color="teal"
                            item-text="status_miskin"
                            item-value="status_miskin"
                            label="Status Kemiskinan"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-select
                        :rules="jeniskkRules"
                        :items="itemsjenis"
                        v-model="editedItem.jenis_kk"
                        filled
                        item-text="jenis_kk"
                        item-value="jenis_kk"
                        label="Jenis Kartu Keluarga (KK)"
                        dense
                        color="teal"
                      ></v-select>

                      <div v-show="editedItem.id_jenisKK === 1">
                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              :value="editedItem.kota"
                              label="Kabupaten/Kota"
                              filled
                              readonly
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              :value="editedItem.kecamatan"
                              label="Kecamatan"
                              filled
                              readonly
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              :value="editedItem.kelurahan"
                              label="Kelurahan/Desa"
                              filled
                              dense
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-select
                              v-model="editedItem.id_dusun"
                              :items="itemsdusun"
                              item-text="dusun"
                              item-value="id"
                              color="teal"
                              label="Pilih Dusun"
                              filled
                              dense
                            ></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-show="editedItem.id_jenisKK === 2">
                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              :value="editedItem.kota"
                              label="Kabupaten/Kota"
                              filled
                              readonly
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              :value="editedItem.kecamatan"
                              label="Kecamatan"
                              filled
                              readonly
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.kelurahan"
                              label="Kelurahan/Desa"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.dusun"
                              label="Nama Dusun"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-show="editedItem.id_jenisKK === 3">
                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              :value="this.kota"
                              label="Kabupaten/Kota"
                              filled
                              readonly
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.kecamatan"
                              label="Kecamatan"
                              filled
                              required
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.kelurahan"
                              label="Kelurahan/Desa"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.dusun"
                              label="Nama Dusun"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-show="editedItem.id_jenisKK === 4">
                        <v-row dense>
                          <v-col cols="12" md="12">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.provinsi"
                              label="Provinsi"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.kota"
                              label="Kabupaten/Kota"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.kecamatan"
                              label="Kecamatan"
                              filled
                              required
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.kelurahan"
                              label="Kelurahan/Desa"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-text-field
                              color="teal"
                              v-model="editedItem.dusun"
                              label="Nama Dusun"
                              filled
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-file-input
                        v-model="imageData"
                        accept=".png, .jpeg, .jpg"
                        hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                        prepend-icon=""
                        prepend-inner-icon="mdi-camera"
                        label="Upload Foto KK"
                        filled
                        dense
                        @click:clear="url = ''"
                        @change="Preview_image"
                      />
                      <template v-if="this.url === ''">
                        <img
                          v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoKK/${editedItem.ft_kk}`"
                          height="300"
                        />
                      </template>
                      <template v-else>
                        <v-card
                          align="center"
                          class="col-12"
                          height="300"
                          elevation="0"
                          color="grey lighten-4"
                          tile
                        >
                          <v-img
                            max-height="300"
                            class="img"
                            :src="url"
                          ></v-img>
                        </v-card>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />

    <v-skeleton-loader
      v-if="firstLoadhitung"
      :loading="sloadinghitung"
      type="article"
    ></v-skeleton-loader>
    <v-row dense>
      <v-col cols="6" v-for="item in hasilhitung" :key="item.id">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">{{ item.jenisKK }}</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ item.countKK }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
    </v-row>
    <br />
    <v-dialog
      v-model="dialoganggota"
      max-width="1200px"
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="white">
          <v-toolbar-title class="black--text"
            ><strong>Detail Kartu Keluarga</strong></v-toolbar-title
          >

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="black" icon dark @click="dialoganggota = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="pa-4">
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  color="teal"
                  label="Nomor KK"
                  filled
                  dense
                  disabled
                  maxlength="16"
                  :rules="nokkRules"
                  v-model="editedItem.nokk"
                  :counter="16"
                  required
                ></v-text-field>

                <v-row>
                  <v-col cols="6" md="6">
                    <v-select
                      :rules="statuskkRules"
                      v-model="editedItem.status_kk"
                      :items="itemstatuskk"
                      item-text="status_kk"
                      item-value="status_kk"
                      color="teal"
                      label="Status Kartu Keluarga (KK)"
                      disabled
                      filled
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-select
                      :rules="miskinRules"
                      v-model="editedItem.status_miskin"
                      :items="itemsmiskin"
                      color="teal"
                      item-text="status_miskin"
                      item-value="status_miskin"
                      label="Status Kemiskinan"
                      filled
                      dense
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>

                <v-select
                  :rules="jeniskkRules"
                  :items="itemsjenis"
                  v-model="editedItem.jenis_kk"
                  filled
                  item-text="jenis_kk"
                  item-value="jenis_kk"
                  label="Jenis Kartu Keluarga (KK)"
                  dense
                  disabled
                  color="teal"
                ></v-select>

                <v-row>
                  <v-col cols="6" md="6" sm="6">
                    <v-text-field
                      color="teal"
                      v-model="editedItem.kota"
                      :value="editedItem.kota"
                      label="Kabupaten / Kota"
                      filled
                      dense
                      disabled
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      color="teal"
                      v-model="editedItem.kecamatan"
                      :value="editedItem.kecamatan"
                      label="Kecamatan"
                      filled
                      disabled
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="6" sm="6">
                    <v-text-field
                      color="teal"
                      v-model="editedItem.kelurahan"
                      :value="editedItem.kelurahan"
                      label="Kelurahan/Desa"
                      filled
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      color="teal"
                      v-model="editedItem.dusun"
                      :value="editedItem.dusun"
                      label="Dusun"
                      filled
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6">
                <img
                  v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoKK/${editedItem.ft_kk}`"
                  height="350"
                />
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>

          <v-list three-line subheader>
            <div class="d-flex justify-space-between pl-4 pe-4 pt-4 pb-0">
              <h3>Daftar Anggota Keluarga</h3>
              <v-btn
                @click="showTambahPenduduk(editedItem)"
                small
                class="white--text"
                color="teal"
              >
                <v-icon dense center> mdi-plus </v-icon>Anggota Keluarga
              </v-btn>
            </div>

            <v-card class="ma-3">
              <v-simple-table>
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">Nomor induk Kependudukan (NIK)</th>

                      <th class="text-left">Nama Lengkap</th>
                      <th class="text-left">Hubungan Keluarga</th>

                      <th class="text-left">Jenis Kelamin</th>
                      <th class="text-center">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in dataindividu" :key="item.id">
                      <td>{{ item.nik }}</td>
                      <td>{{ item.nama }}</td>
                      <td>{{ item.StatusKeluarga }}</td>
                      <td>{{ item.jk }}</td>
                      <td class="text-center">
                        <v-btn
                          class="lighten-4"
                          @click="showdetailPenduduk(item)"
                          outlined
                          small
                          color="blue"
                        >
                          <v-icon dense center> mdi-information </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

            <br />
            <br />
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      v-model="dialogTambahPenduduk"
      persistent
      max-width="1000px"
    >
      <v-form
        @submit.prevent="tambahPenduduk"
        v-model="valid"
        lazy-validation
        ref="form"
      >
        <v-card>
          <v-card-title
            >Form Tambah Anggota Keluarga : {{ editedItem.nokk }} -
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text elevation="0">
            <v-container>
              <div class="d-flex justify-space-between mb-4">
                <h4>1. Deskripsi Individu</h4>
              </div>

              <v-row dense>
                <v-col cols="4"
                  ><v-text-field
                    color="teal"
                    label="Nomor Induk Kependudukan (NIK)"
                    filled
                    dense
                    maxlength="16"
                    :rules="nikRules"
                    v-model="nik"
                    :counter="16"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    color="teal"
                    label="Nama Lengkap"
                    :rules="wajibRules"
                    v-model="nama"
                    filled
                    dense
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="jk"
                    :items="itemsjk"
                    item-text="jenis_kelamin"
                    item-value="jenis_kelamin"
                    color="teal"
                    label="Jenis Kelamin"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <v-col cols="4">
                  <v-text-field
                    color="teal"
                    label="Tempat Lahir"
                    :rules="wajibRules"
                    v-model="tmpt_lahir"
                    filled
                    dense
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="tgl_lahir"
                        :rules="wajibRules"
                        label="Tanggal Lahir"
                        readonly
                        filled
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      v-model="tgl_lahir"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="status_tinggal"
                    :items="itemsstatus_tinggal"
                    item-text="status_tinggal"
                    item-value="status_tinggal"
                    color="teal"
                    label="Status Tinggal"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="agama"
                    :items="itemsagama"
                    item-text="agama"
                    item-value="agama"
                    color="teal"
                    label="Agama"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="status_kawin"
                    :items="itemskawin"
                    item-text="status_kawin"
                    item-value="status_kawin"
                    color="teal"
                    label="Status Perkawinan"
                    filled
                    dense
                  ></v-select
                ></v-col>

                <template v-if="status_kawin === 'Kawin'">
                  <v-col cols="4">
                    <v-select
                      :rules="wajibRules"
                      v-model="tahun_kawin"
                      :items="years"
                      item-text="year"
                      item-value="year"
                      color="teal"
                      label="Tahun Pernikahan"
                      filled
                      dense
                    ></v-select
                  ></v-col>
                </template>

                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="kewarganegaraan"
                    :items="itemskewarganegaraan"
                    item-text="status_warganegara"
                    item-value="status_warganegara"
                    color="teal"
                    label="Kewarganegaraan"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="hak_politik"
                    :items="itemsyatidak"
                    item-text="optional"
                    item-value="optional"
                    color="teal"
                    label="Hak Pilih Politik"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <v-col cols="4">
                  <v-select
                    v-on:change="cekKeluarga(`${status_keluarga}`)"
                    :rules="wajibRules"
                    v-model="status_keluarga"
                    :items="recipes"
                    item-text="name"
                    item-value="id"
                    color="teal"
                    label="Status Hubungan Keluarga"
                    filled
                    dense
                  ></v-select
                ></v-col>
              </v-row>
              <h4>2. Deskripsi Pekerjaan dan Pendidikan</h4>
              <br />
              <v-row dense>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="status_pekerjaan"
                    :items="itemskerja"
                    item-text="kondisi_pekerjaan"
                    item-value="kondisi_pekerjaan"
                    color="teal"
                    label="Kondisi Pekerjaan"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <template v-if="status_pekerjaan === 'Bekerja'">
                  <v-col cols="4">
                    <v-select
                      :rules="wajibRules"
                      v-model="pekerjaan"
                      :items="itemspekerjaan"
                      item-text="pekerjaan"
                      item-value="pekerjaan"
                      color="teal"
                      label="Pilih Pekerjaan"
                      filled
                      dense
                    ></v-select
                  ></v-col>
                </template>

                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="pendidikan"
                    :items="itemspendidikan"
                    item-text="pendidikan"
                    item-value="pendidikan"
                    color="teal"
                    label="Pendidikan tertinggi yang ditamatkan"
                    filled
                    dense
                  ></v-select
                ></v-col>
              </v-row>

              <h4>3. Deskripsi Kesehatan</h4>
              <br />
              <v-row dense>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="status_jkn"
                    :items="itemsyatidak"
                    item-text="optional"
                    item-value="optional"
                    color="teal"
                    label="Peserta Jaminan Kesehatan Nasional"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <template v-if="status_jkn === 'Ya'">
                  <v-col cols="4">
                    <v-select
                      :rules="wajibRules"
                      v-model="id_jkn"
                      :items="itemsid_jkn"
                      item-text="JKN"
                      item-value="JKN"
                      color="teal"
                      label="Pilih Jaminan Kesehatan Nasional"
                      filled
                      multiple
                      dense
                    ></v-select
                  ></v-col>
                </template>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="penyakit"
                    :items="itemspenyakit"
                    item-text="penyakitcek"
                    item-value="penyakitcek"
                    color="teal"
                    label="Penyakit yang diderita 1 tahun terakhir"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <template v-if="penyakit === 'Ada'">
                  <v-col cols="4">
                    <v-select
                      :rules="wajibRules"
                      v-model="id_penyakit"
                      :items="itemsid_penyakit"
                      item-text="penyakit"
                      item-value="penyakit"
                      color="teal"
                      label="Pilih Penyakit yang diderita"
                      filled
                      multiple
                      dense
                    ></v-select
                  ></v-col>
                </template>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="disabilitas"
                    :items="itemsyatidak"
                    item-text="optional"
                    item-value="optional"
                    color="teal"
                    label="Disabilitas"
                    filled
                    dense
                  ></v-select
                ></v-col>
                <template v-if="disabilitas === 'Ya'">
                  <v-col cols="4">
                    <v-select
                      :rules="wajibRules"
                      v-model="id_disabilitas"
                      :items="itemsdisabilitas"
                      item-text="disabilitas"
                      item-value="disabilitas"
                      color="teal"
                      label="Pilih Disabilitas"
                      filled
                      multiple
                      dense
                    ></v-select
                  ></v-col>
                </template>
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="stanting"
                    :items="itemsyatidak"
                    item-text="optional"
                    item-value="optional"
                    color="teal"
                    label="stanting"
                    filled
                    dense
                  ></v-select
                ></v-col>
              </v-row>
              <h4>4. Foto KTP dan Foto Selfie</h4>
              <v-row dense>
                <v-col cols="6" md="6">
                  <v-file-input
                    :rules="imageRulesKtp"
                    v-model="imageDataKtp"
                    accept=".png, .jpeg, .jpg"
                    hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                    prepend-icon=""
                    prepend-inner-icon="mdi-camera"
                    label="Upload Foto KTP"
                    filled
                    dense
                    @click:clearKtp="urlktp = ''"
                    @change="Preview_imageKtp(row)"
                  />
                  <v-card
                    align="center"
                    class="col-7"
                    max-height="500"
                    elevation="0"
                    color="grey lighten-4"
                    tile
                  >
                    <v-img
                      aspect-ratio="1.4"
                      :cover="true"
                      class="img"
                      :src="urlktp"
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col cols="6" md="6">
                  <v-file-input
                    :rules="imageRulesSelfie"
                    v-model="imageDataSelfie"
                    accept=".png, .jpeg, .jpg"
                    hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                    prepend-icon=""
                    prepend-inner-icon="mdi-camera"
                    label="Upload Foto Selfie"
                    filled
                    dense
                    @click:clearSelfie="urlselfie = ''"
                    @change="Preview_imageSelfie(row)"
                  />
                  <v-card
                    align="center"
                    class="col-7"
                    max-height="500"
                    elevation="0"
                    color="grey lighten-4"
                    tile
                  >
                    <v-img
                      aspect-ratio="1.4"
                      :cover="true"
                      class="img"
                      :src="urlselfie"
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
              <br />
            </v-container>
          </v-card-text>
          <v-divider></v-divider>

          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined @click="closeTambah">Batal</v-btn>

            <v-btn
              dialogMeninggal="true"
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              class="white--text"
              color="teal"
              >Simpan Data</v-btn
            >
            <br />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog scrollable v-model="dialogpenduduk" persistent max-width="1000px">
      <v-card>
        <v-toolbar color="white" class="black--text">
          <v-row class="justify-space-between pe-3"
            ><strong class="pl-2"
              >Detail Penduduk : {{ individuItem.nama }}</strong
            >
            <v-btn
              small
              color="red"
              class="white--text"
              @click="dialogpenduduk = false"
              >X</v-btn
            ></v-row
          >
        </v-toolbar>

        <v-card-text>
          <h4 class="cyan--text pt-3 pb-2">1. Deskripsi Individu</h4>

          <v-row dense>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Nomor Induk Kependudukan (NIK)"
                :value="individuItem.nik"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Nama Lengkap"
                :value="individuItem.nama"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Jenis Kelamin"
                :value="individuItem.jk"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Tempat Lahir"
                :value="individuItem.tmpt_lahir"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Tanggal Lahir"
                :value="individuItem.tgl_lahir"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Status Tinggal"
                :value="individuItem.status_tinggal"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Agama"
                :value="individuItem.agama"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>

            <template v-if="individuItem.status_kawin === 'Tidak Kawin'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Perkawinan"
                  :value="individuItem.status_kawin"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Perkawinan"
                  :value="individuItem.status_kawin"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Tahun Pernikahan"
                  :value="individuItem.tahun_kawin"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Kewarganegaraan"
                :value="individuItem.kewarganegaraan"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Hak Pilih Politik"
                :value="individuItem.hak_politik"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <h4 class="cyan--text">2. Deskripsi Pekerjaan dan Pendidikan</h4>
          <br />
          <v-row dense>
            <template v-if="individuItem.status_pekerjaan === 'Bekerja'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Pekerjaan"
                  :value="individuItem.status_pekerjaan"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Pekerjaan"
                  :value="individuItem.pekerjaan"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="individuItem.status_pekerjaan !== 'Bekerja'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Pekerjaan"
                  :value="individuItem.status_pekerjaan"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>

            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Pendidikan tertinggi yang ditamatkan"
                :value="individuItem.pendidikan"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <h4 class="cyan--text">3. Deskripsi Kesehatan</h4>
          <br />
          <v-row dense>
            <template v-if="individuItem.status_jkn === 'Ya'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Peserta Jaminan Kesehatan Nasional"
                  :value="individuItem.status_jkn"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Jaminan Kesehatan Nasional"
                  :value="individuItem.id_jkn"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="individuItem.status_jkn !== 'Ya'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Peserta Jaminan Kesehatan Nasional"
                  :value="individuItem.status_jkn"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>

            <template v-if="individuItem.penyakit === 'Ada'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Penyakit yang diderita 1 tahun terakhir"
                  :value="individuItem.penyakit"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Penyakit yang diderita"
                  :value="individuItem.id_penyakit"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="individuItem.penyakit !== 'Ada'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Penyakit yang diderita 1 tahun terakhir"
                  :value="individuItem.penyakit"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>

            <template v-if="individuItem.disabilitas === 'Ya'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Disabilitas"
                  :value="individuItem.disabilitas"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Data Disabilitas"
                  :value="individuItem.id_disabilitas"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="individuItem.disabilitas !== 'Ya'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Disabilitas"
                  :value="individuItem.disabilitas"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Stunting"
                :value="individuItem.stanting"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <h4 class="cyan--text">4. Foto KTP dan Foto Selfie</h4>
          <br />
          <v-row dense>
            <v-col cols="12" md="6">
              <v-card color="grey lighten-4">
                <v-card-title class="layout justify-center pa-0">
                  <h5>Foto KTP</h5></v-card-title
                >
                <img
                  v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoKTP/${individuItem.foto_ktp}`"
                  style="width: 100%; height: 420px"
                />
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card color="grey lighten-4">
                <v-card-title class="layout justify-center pa-0">
                  <h5>Foto Selfie</h5></v-card-title
                >
                <img
                  v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoSelfie/${individuItem.foto_selfie}`"
                  style="width: 100%; height: 420px"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-dialog persistent v-model="dialogfotoKK" max-width="1200">
        <template>
          <v-card>
            <v-toolbar color="white" class="black--text">
              <v-row class="justify-space-between pe-3"
                >Foto Kartu Keluarga : {{ editedItem.nokk }}
                <v-btn
                  small
                  color="red"
                  class="white--text"
                  @click="closeFotoKK"
                  >X</v-btn
                ></v-row
              >
            </v-toolbar>

            <img
              v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoKK/${editedItem.ft_kk}`"
              style="width: 100%; height: 500px"
            />
          </v-card>
        </template>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="400px">
        <v-card>
          <v-card-title
            ><h5>Apakah anda yakin untuk mengahpus data ?</h5>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-spacer></v-spacer>
            <h4 class="teal--text">No KK: {{ editedItem.nokk }}</h4>
            <br />
            <p class="red--grey">
              peringatan : jika anda menghapus data KK, akan berpengaruh ke deta
              seperti : data penduduk, data bantuan, dll
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>
      <v-data-table
        :headers="headers"
        :items="hasildatas"
        v-else
        :search="search"
      >
        <template v-slot:[`item.ft_kk`]="{ item }">
          <div class="pa-2">
            <v-card @click="showfotoKK(item)" elevation="0">
              <img
                v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoKK/${item.ft_kk}`"
                style="width: 100px; height: 70px"
              />
            </v-card>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              class="lighten-4"
              @click="showdetailKK(item)"
              outlined
              small
              color="cyan"
            >
              <v-icon dense center> mdi-information </v-icon>
            </v-btn>

            <v-btn
              class="lighten-4 mx-2"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>
            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
        <!-- <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "provinsiView",
  data() {
    return {
      // Tambah penduduk
      dialogTambahPenduduk: false,
      date: null,
      menu: false,
      // IMage Preview KK
      imageData: null,
      url: "",

      // GET DATA EDIT
      itemstatuskk: {},
      itemsmiskin: {},
      itemsjenis: {},
      itemsdusun: {},
      detailusers: {},
      status_kk: "",
      status_miskin: "",
      jenis_kk: "",
      kota: "",
      kecamatan: "",
      kelurahan: "",
      id_dusun: "",

      // ITEM DATA INDIVIDU
      itemsjk: {},
      itemsstatus_tinggal: {},
      itemsagama: {},
      itemskawin: {},
      itemskewarganegaraan: {},
      itemsyatidak: {},
      itemskerja: {},
      itemspekerjaan: {},
      itemspendidikan: {},
      itemsid_jkn: {},
      itemspenyakit: [{ penyakitcek: "Ada" }, { penyakitcek: "Tidak" }],
      itemsid_penyakit: {},
      itemsdisabilitas: {},

      // GET DATA HITUNG
      hasilhitung: [],
      sloadinghitung: true,
      firstLoadhitung: true,

      // GET DATA WARGA KK

      sloading: true,
      firstLoad: true,

      // DIALOG ANGGOTA KELUARGA
      dialoganggota: false,

      // DIALOG PENDUDUK
      dialogpenduduk: false,

      dialog: false,
      dialogDelete: false,
      dialogfotoKK: false,
      search: "",
      provinsi: "",

      valid: true,

      loader: null,
      loading: false,

      // VALIDASI KK
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],
      jeniskkRules: [(v) => !!v || "Jenis Kartu Keluarga tidak boleh kosong"],
      statuskkRules: [(v) => !!v || "Status Kartu Keluarga tidak boleh kosong"],
      miskinRules: [(v) => !!v || "Status Kemiskinan tidak boleh kosong"],
      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nikRules: [
        (v) => !!v || "NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "NIK harus angka",
      ],
      imageRulesKtp: [(v) => !!v || "Foto KTP tidak boleh kosong"],
      imageRulesSelfie: [(v) => !!v || "Foto Selfie tidak boleh kosong"],
      imageRules: [(v) => !!v || "Foto Kartu Keluarga tidak boleh kosong"],
      namaKecRules: [(v) => !!v || "Nama provinsi tidak boleh kosong"],

      headers: [
        { text: "Foto KK", value: "ft_kk", width: "100px" },

        { text: "No Kartu Keluarga", value: "nokk", width: "140px" },
        { text: "Kepala Keluarga", value: "kelapa_keluarga", width: "140px" },

        { text: "Provinsi", value: "provinsi", width: "140px" },
        { text: "Kabupaten/Kota", value: "kota", width: "140px" },
        { text: "Kecamatan", value: "kecamatan", width: "140px" },
        { text: "Kelurahan/Desa", value: "kelurahan", width: "140px" },
        { text: "Dusun", value: "dusun", width: "140px" },
        { text: "Statu KK", value: "status_kk", width: "140px" },
        { text: "Jenis KK", value: "jenis_kk", width: "140px" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      individuItem: {
        id: 0,
      },
      hasildatas: [],
      dataindividu: [],
      editedIndex: -1,
      editedItem: {
        id: 0,
        provinsi: "",
        nokk: "",

        status_kk: "",
        status_miskin: "",
        no_bangunan: "",
        jenis_kk: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
        id_dusun: "",
        dusun: "",
      },
      defaultItem: {
        nokk: "",
        no_bangunan: "",
        provinsi: "",

        kota: "",
        kecamatan: "",
        kelurahan: "",
      },

      // DATA PENDUDUK
      nik: "",
      nama: "",
      jk: "",
      tmpt_lahir: "",
      status_tinggal: "",
      agama: "",
      status_kawin: "",
      tahun_kawin: "",
      kewarganegaraan: "",
      hak_politik: "",
      status_pekerjaan: "",
      pekerjaan: "",
      pendidikan: "",
      status_jkn: "",
      id_jkn: "",
      penyakit: "",
      id_penyakit: "",
      disabilitas: "",
      id_disabilitas: "",
      stanting: "",
      // foto ktp dan selfie
      // foto ktp
      imageDataKtp: null,
      urlktp: "",

      // foto selfie
      imageDataSelfie: null,
      urlselfie: "",

      recipes: [
        {
          id: 2,
          name: "ISTRI",
          type: "istri",
        },
        {
          id: 3,
          name: "ANAK",
          type: "anak",
        },
        {
          id: 4,
          name: "MENANTU",
          type: "menantu",
        },
        {
          id: 5,
          name: "CUCU",
          type: "cucu",
        },
        {
          id: 6,
          name: "ORANG TUA",
          type: "ot",
        },
        {
          id: 7,
          name: "MERTUA",
          type: "mertua",
        },
        {
          id: 8,
          name: "FAMILI LAIN",
          type: "famili",
        },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data KK"
        : "Form Edit Data Kartu Keluarga";
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1950 },
        (value, index) => 1951 + index
      );
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.row.activePicker = "YEAR"));
    },
  },

  created() {
    this.getKK();
    this.getStsKK();
    this.getJenisKK();
    this.getStatusKK();
    this.getStatusMiskin();

    this.detailUsers();
    this.getDusun();
  },

  methods: {
    cekAnggotaKK: function (item) {
      const credentials = {
        nokk: item.nokk,
      };
      AuthService.cekAnggotaKK(credentials).then(
        function (response) {
          this.dataindividu = response.message;
          // console.log(response.message);
          this.dialoganggota = true;
        }.bind(this)
      );
    },
    showdetailKK(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      // this.dataindividu = item.anggota_kk;
      this.editedItem = Object.assign({}, item);
      this.cekAnggotaKK(item);
    },
    //
    save(date) {
      this.$refs.menu.save(date);
    },
    // INDIVIDU ITEM
    getJK: function () {
      AuthService.getJK().then(
        function (response) {
          this.itemsjk = response.data.data;
        }.bind(this)
      );
    },

    Status_tinggal: function () {
      AuthService.getStatusTinggal().then(
        function (response) {
          this.itemsstatus_tinggal = response.data.data;
        }.bind(this)
      );
    },
    getAgama: function () {
      AuthService.getAgama().then(
        function (response) {
          this.itemsagama = response.data.data;
        }.bind(this)
      );
    },
    getStatusPerkawinan: function () {
      AuthService.getStatusPerkawinan().then(
        function (response) {
          this.itemskawin = response.data.data;
        }.bind(this)
      );
    },
    getKewarganegaraan: function () {
      AuthService.getKewarganegaraan().then(
        function (response) {
          this.itemskewarganegaraan = response.data.data;
        }.bind(this)
      );
    },
    getYatidak: function () {
      AuthService.getYaTidak().then(
        function (response) {
          this.itemsyatidak = response.data.data;
        }.bind(this)
      );
    },
    getKerja: function () {
      AuthService.getKondisiPekerjaan().then(
        function (response) {
          this.itemskerja = response.data.data;
        }.bind(this)
      );
    },
    getpekerjaan: function () {
      AuthService.getDetailPekerjaan().then(
        function (response) {
          this.itemspekerjaan = response.data.data;
        }.bind(this)
      );
    },
    getPendidikan: function () {
      AuthService.getPendidikan().then(
        function (response) {
          this.itemspendidikan = response.data.data;
        }.bind(this)
      );
    },
    getJKN: function () {
      AuthService.getJKN().then(
        function (response) {
          this.itemsid_jkn = response.data.data;
        }.bind(this)
      );
    },
    getJenisPenyakit: function () {
      AuthService.getJenisPenyakit().then(
        function (response) {
          this.itemsid_penyakit = response.data.data;
        }.bind(this)
      );
    },
    getDisabilitas: function () {
      AuthService.getDisabelitas().then(
        function (response) {
          this.itemsdisabilitas = response.data.data;
        }.bind(this)
      );
    },

    // KK ITEMS
    getKK: function () {
      AuthService.dataKK().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
    getStsKK: function () {
      AuthService.dataWargaStatistik().then(
        function (response) {
          this.hasilhitung = response.data.data;
          this.firstLoadhitung = false;
          this.sloadinghitung = false;
        }.bind(this)
      );
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.imageData);
    },

    Preview_imageKtp() {
      this.urlktp = URL.createObjectURL(this.imageDataKtp);
    },

    Preview_imageSelfie() {
      this.urlselfie = URL.createObjectURL(this.imageDataSelfie);
    },
    clear() {
      this.url = this.$refs.theFile = "";
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },
    getStatusKK: function () {
      AuthService.getStatuKK().then(
        function (response) {
          this.itemstatuskk = response.data.data;
        }.bind(this)
      );
    },
    getStatusMiskin: function () {
      AuthService.getStatusMiskin().then(
        function (response) {
          this.itemsmiskin = response.data.data;
        }.bind(this)
      );
    },
    getJenisKK: function () {
      AuthService.getJenisKK().then(
        function (response) {
          this.itemsjenis = response.data.data;
        }.bind(this)
      );
    },
    initialize() {
      this.hasildatas = [];
      this.hasilhitung = [];
    },

    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    showfotoKK(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogfotoKK = true;
    },

    showdetailPenduduk(item) {
      this.individuItem = Object.assign({}, item);
      this.dialogpenduduk = true;
      console.log(item.nama);
    },

    showTambahPenduduk(item) {
      this.editedIndex = this.hasildatas.indexOf(item);

      this.editedItem = Object.assign({}, item);
      this.dialogTambahPenduduk = true;
      console.log(this.editedItem.nokk);
    },
    closeTambah() {
      this.dialogTambahPenduduk = false;

      this.$refs.form.reset();
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteKK(credentials);
      this.message = response.message;
      if (response.success === true) {
        this.$notification.success(response.message, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getKK();
        this.getStsKK();
        this.$router.push("/admin-kelurahan/penduduk/kk").catch(() => {});

        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeFotoKK() {
      this.dialogfotoKK = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      console.log(this.editedItem.dusun);
      // console.log(this.editedItem.dusun);
      try {
        // proses simpan atau update

        if (this.editedIndex === -1) {
          //proses simpan
          const credentials = {
            provinsi: this.editedItem.provinsi,
          };
          const response = await AuthService.postCamat(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/dashboard/provinsi").catch(() => {});
            this.dialog = false;
            this.secretMessage = await AuthService.getCamat();
            this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();
            console.log("berhasil simpan");
            console.log(this.secretMessage.data);
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses EDIT KK
          let formData = new FormData();
          formData.append("id", this.editedItem.id);
          formData.append("foto_kk", this.imageData);
          formData.append("nokk", this.editedItem.nokk);
          formData.append("status_kk", this.editedItem.status_kk);
          formData.append("jenis_kk", this.editedItem.jenis_kk);
          formData.append("no_bangunan", this.editedItem.no_bangunan);
          formData.append("id_dusun", this.editedItem.id_dusun);
          formData.append("status_miskin", this.editedItem.status_miskin);

          // baru
          // if(this.editedItem.id_jenisKK === 1){

          // }
          // formData.append("id_dusun", this.editedItem.id_dusun);
          formData.append("provinsi", this.editedItem.provinsi);
          formData.append("kota", this.editedItem.kota);
          formData.append("kecamatan", this.editedItem.kecamatan);
          formData.append("kelurahan", this.editedItem.kelurahan);
          formData.append("dusun", this.editedItem.dusun);

          const response = await AuthService.editKK(formData);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/admin-kelurahan/penduduk/kk").catch(() => {});
            this.dialog = false;
            this.getKK();
            this.getStsKK();

            this.$refs.form.reset();

            console.log("berhasil UPDATE KK");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            // this.$refs.form.reset();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
    async tambahPenduduk() {
      this.loading = true;
      try {
        // proses tambah warga kk

        let formData = new FormData();
        formData.append("id_kk", this.editedItem.id);
        formData.append("foto_selfie", this.imageDataSelfie);
        formData.append("foto_ktp", this.imageDataKtp);

        formData.append("status_keluarga", this.status_keluarga);
        formData.append("nik", this.nik);
        formData.append("nama", this.nama);
        formData.append("jk", this.jk);
        formData.append("tmpt_lahir", this.tmpt_lahir);
        formData.append("tgl_lahir", this.tgl_lahir);
        formData.append("status_tinggal", this.status_tinggal);
        formData.append("agama", this.agama);
        formData.append("status_kawin", this.status_kawin);
        formData.append("tahun_kawin", this.tahun_kawin);

        formData.append("kewarganegaraan", this.kewarganegaraan);
        formData.append("hak_politik", this.hak_politik);
        formData.append("status_pekerjaan", this.status_pekerjaan);
        formData.append("pekerjaan", this.pekerjaan);

        formData.append("pendidikan", this.pendidikan);
        formData.append("status_jkn", this.status_jkn);
        formData.append("id_jkn", this.id_jkn);
        formData.append("penyakit", this.penyakit);
        formData.append("id_penyakit", this.id_penyakit);
        formData.append("disabilitas", this.disabilitas);
        formData.append("id_disabilitas", this.id_disabilitas);
        formData.append("stanting", this.stanting);

        const response = await AuthService.tambahWargaKK(formData);
        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 5,
          });

          this.loading = false;
          this.$router.push("/admin-kelurahan/penduduk/kk").catch(() => {});
          this.dialog = false;
          this.dialogpenduduk = false;
          this.dialogTambahPenduduk = false;
          (this.dialoganggota = false), this.getKK();
          this.getStsKK();

          this.$refs.form.reset();

          console.log("berhasil tambah penduduk KK");
        } else {
          this.$notification.error(response.message, {
            timer: 5,
          });

          this.loading = false;
          // this.$refs.form.reset();
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.getKK();
    this.getStsKK();
    this.getStatusKK();
    this.getStatusMiskin();
    this.getJenisKK();
    this.detailUsers();
    this.getDusun();

    this.getJK();
    this.Status_tinggal();
    this.getAgama();
    this.getStatusPerkawinan();
    this.getKewarganegaraan();
    this.getYatidak();
    this.getKerja();
    this.getpekerjaan();
    this.getPendidikan();
    this.getJKN();
    this.getJenisPenyakit();
    this.getDisabilitas();
  },
};
</script>
