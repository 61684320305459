<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0"
      elevation="0"
    >
      <strong>Data Statistik - Pendidikan</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="grey lighten-7"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-4">
      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,

      // Data
      hasildatas: [{}],

      // Chart

      series: [44, 55, 41, 17, 15],

      chartData: "",
      chartOptions: {
        responsive: true,

        maintainAspectRatio: false,
        cutoutPercentage: 80,

        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
            // callbacks: {
            //   label: function (context) {
            //     let label = context.label;
            //     let value = context.formattedValue;

            //     if (!label) label = "Unknown";

            //     let sum = 0;
            //     let dataArr = context.chart.data.datasets[0].data;
            //     dataArr.map((data) => {
            //       sum += Number(data);
            //     });

            //     let percentage = ((value * 100) / sum).toFixed(1) + "%";
            //     return label + ": " + percentage;
            //   },
            // },
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsDataPendidikan();
  },
  methods: {
    stsDataPendidikan: function () {
      ServicePublik.stsDataPendidikan().then(
        function (response) {
          this.hasildatas = response.data.data;

          this.firstLoad = false;
          this.sloading = false;

          const responseData = response.data.data.pendidikan;

          //   console.log(this.hasildatas);

          // chart js
          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };
        }.bind(this)
      );
    },
  },
  mounted() {
    this.renderChart(this.chartData);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
</style>
