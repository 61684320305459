<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0"
      elevation="0"
    >
      <strong>Data Statistik - Umur</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="grey lighten-7"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-4">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK PENDUDUK</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK LAKI-LAKI</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatadua"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK PEREMPUAN </strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatatiga"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,

      // chart DUA
      chartDatadua: "",
      chartDatatiga: "",

      // Chart
      hasildatas: [{}],
      series: [44, 55, 41, 17, 15],
      chartData: "",
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsDataUmur();
  },
  methods: {
    stsDataUmur: function () {
      ServicePublik.stsDataUmur().then(
        function (response) {
          this.hasildatas = response.data.data.penduduk;

          this.firstLoad = false;
          this.sloading = false;

          const responseData = response.data.data.penduduk;
          const responseDatadua = response.data.data.laki_laki;
          const responseDatatiga = response.data.data.perempuan;

          //   console.log(this.hasildatas);

          // GRAFIK SATU
          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDatadua = {
            labels: responseDatadua.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatadua.map((item) => item.total),
              },
            ],
          };
          this.chartDatatiga = {
            labels: responseDatatiga.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatatiga.map((item) => item.total),
              },
            ],
          };
        }.bind(this)
      );
    },
  },
  mounted() {
    this.renderChart(this.chartData);
    this.renderChart(this.chartDatadua);
    this.renderChart(this.chartDatatiga);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
</style>
