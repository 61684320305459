<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0"
      elevation="0"
    >
      <strong>Rumah Data - SAGASAJA</strong>
      <v-btn
        to="/rumah-data"
        depressed
        small
        class="white--text"
        color="grey lighten-7"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-4">
      <v-row>
        <template>
          <v-col
            v-for="itemdata in hasildatas"
            :key="itemdata"
            cols="12"
            md="3"
            sm="6"
            xs="6"
          >
            <v-card class="cardtop mt-6 pl-3 pr-3 pb-3 pt-3">
              <v-row align="center" justify="center">
                <v-col align="center" justify="center">
                  <h2>{{ itemdata.total }}</h2>
                </v-col>
              </v-row>
              <h5 class="text-center black--text text--accent-3 pt-4">
                {{ itemdata.nama }} <br />
              </h5>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
export default {
  name: "PublikLahan",
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,

      // Data
      hasildatas: [{}],
    };
  },

  watch: {},

  created() {
    this.getDataSagasaja();
  },
  methods: {
    // Data
    getDataSagasaja: function () {
      ServicePublik.getDataSagasaja().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          //   console.log(this.hasildatas);
        }.bind(this)
      );
    },
  },

  async mounted() {
    // this.detailUsers();
    // this.getDusun();
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
</style>
