<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0"
      elevation="0"
    >
      <strong>Data Statistik - Posyandu</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="grey lighten-7"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 12" :key="index" cols="6" md="6" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Balita</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>
      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Imunisasi</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDataa"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Jenis Imunisasi</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatab"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Kapsul Biru</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatac"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Kapsul Merah</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatad"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Obat Cacing</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatae"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Gizi</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDataf"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik BBLR</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatag"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Lingkar Kepala</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatah"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Index Kehamilan</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatai"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Resiko Kandungan</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDataj"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Riwayat Kesehatan Ibu</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatak"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Tablet Darah</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatal"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik LILLA</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatam"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Riwayat Kehamilan</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatan"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Imunisasi Ibu</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatao"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Alat Kontraespsi</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatap"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Metode Kontraespsi</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDataq"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,

      // Data
      hasildatas: [{}],

      // Chart

      series: [44, 55, 41, 17, 15],

      chartData: "",
      chartDataa: "",
      chartDatab: "",
      chartDatac: "",
      chartDatad: "",
      chartDatae: "",
      chartDataf: "",
      chartDatag: "",
      chartDatah: "",
      chartDatai: "",
      chartDataj: "",
      chartDatak: "",
      chartDatal: "",
      chartDatam: "",
      chartDatan: "",
      chartDatao: "",
      chartDatap: "",
      chartDataq: "",
      chartOptions: {
        responsive: true,

        maintainAspectRatio: false,
        cutoutPercentage: 80,

        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsPosyandu();
  },
  methods: {
    stsPosyandu: function () {
      ServicePublik.stsPosyandu().then(
        function (response) {
          this.hasildatas = response.data.data.total_balita;

          this.firstLoad = false;
          this.sloading = false;

          const responseData = response.data.data.total_balita;
          const responseDataa = response.data.data.total_imunisasi;
          const responseDatab = response.data.data.imunisasi;
          const responseDatac = response.data.data.kapsul_biru;
          const responseDatad = response.data.data.kapsul_merah;
          const responseDatae = response.data.data.obat_cacing;
          const responseDataf = response.data.data.gizi;
          const responseDatag = response.data.data.bblr;
          const responseDatah = response.data.data.lingkar;
          const responseDatai = response.data.data.index_kehamilan;
          const responseDataj = response.data.data.resiko_kandungan;
          const responseDatak = response.data.data.riwayat_kesehatan_ibu;
          const responseDatal = response.data.data.tablet_darah;
          const responseDatam = response.data.data.lilla;
          const responseDatan = response.data.data.riwayat_hamil;
          const responseDatao = response.data.data.imun_ibu;
          const responseDatap = response.data.data.alat_kontrasepsi;
          const responseDataq = response.data.data.metode_kontarepsi;

          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };

          this.chartDataa = {
            labels: responseDataa.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDataa.map((item) => item.total),
              },
            ],
          };

          this.chartDatab = {
            labels: responseDatab.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatab.map((item) => item.total),
              },
            ],
          };
          this.chartDatac = {
            labels: responseDatac.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatac.map((item) => item.total),
              },
            ],
          };
          this.chartDatad = {
            labels: responseDatad.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatad.map((item) => item.total),
              },
            ],
          };

          this.chartDatae = {
            labels: responseDatae.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatae.map((item) => item.total),
              },
            ],
          };

          this.chartDataf = {
            labels: responseDataf.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDataf.map((item) => item.total),
              },
            ],
          };

          this.chartDatag = {
            labels: responseDatag.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatag.map((item) => item.total),
              },
            ],
          };

          this.chartDatah = {
            labels: responseDatah.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatah.map((item) => item.total),
              },
            ],
          };

          this.chartDatai = {
            labels: responseDatai.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatai.map((item) => item.total),
              },
            ],
          };

          this.chartDataj = {
            labels: responseDataj.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDataj.map((item) => item.total),
              },
            ],
          };

          this.chartDatak = {
            labels: responseDatak.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatak.map((item) => item.total),
              },
            ],
          };

          this.chartDatal = {
            labels: responseDatal.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatal.map((item) => item.total),
              },
            ],
          };

          this.chartDatam = {
            labels: responseDatam.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatam.map((item) => item.total),
              },
            ],
          };

          this.chartDatan = {
            labels: responseDatan.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatan.map((item) => item.total),
              },
            ],
          };

          this.chartDatao = {
            labels: responseDatao.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatao.map((item) => item.total),
              },
            ],
          };
          this.chartDatap = {
            labels: responseDatap.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatap.map((item) => item.total),
              },
            ],
          };
          this.chartDataq = {
            labels: responseDataq.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDataq.map((item) => item.total),
              },
            ],
          };
        }.bind(this)
      );
    },
  },
  mounted() {
    this.renderChart(this.chartData);
    this.renderChart(this.chartDataa);
    this.renderChart(this.chartDatab);
    this.renderChart(this.chartDatac);
    this.renderChart(this.chartDatad);
    this.renderChart(this.chartDatae);
    this.renderChart(this.chartDataf);
    this.renderChart(this.chartDatag);
    this.renderChart(this.chartDatah);
    this.renderChart(this.chartDatai);
    this.renderChart(this.chartDataj);
    this.renderChart(this.chartDatak);
    this.renderChart(this.chartDatal);
    this.renderChart(this.chartDatam);
    this.renderChart(this.chartDatan);
    this.renderChart(this.chartDatao);
    this.renderChart(this.chartDatap);
    this.renderChart(this.chartDataq);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
</style>
