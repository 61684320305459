<template>
  <v-container class="py-6 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Form Input Data Lahan</h4></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-skeleton-loader
      v-if="sloading"
      :loading="firstLoad"
      type="article, actions"
    ></v-skeleton-loader>
    <v-card v-else elevation="1">
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="simpanLahan"
          v-model="valid"
          lazy-validation
        >
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="ladang"
                color="teal"
                label="Luas Lahan Ladang / Tegalan"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="kebun"
                color="teal"
                label="Luas Lahan Kebun"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="kolam"
                color="teal"
                label="Luas Lahan Kolam"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="tebuka"
                color="teal"
                label="Luas Lahan Terbuka"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="permukiman"
                color="teal"
                label="Luas Lahan Pemukiman"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="sawah"
                color="teal"
                label="Luas Lahan Sawah"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="semak"
                color="teal"
                label="Luas Lahan Semak Belukar"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="pemakaman"
                color="teal"
                label="Luas Lahan Pemakaman"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="hutan"
                color="teal"
                label="Luas Lahan Hutan"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="satuanRules"
                v-model="lainnya"
                color="teal"
                label="Luas Lahan Lainnya"
                filled
                dense
                hint="input angka dengan satuan (Ha)"
              ></v-text-field>
            </v-col>
          </v-row>
          <br />

          <v-divider></v-divider>
          <br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              color="teal"
              class="mr-0 white--text"
            >
              Simpan Data
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <br />
  </v-container>
</template>

<script>
import axios from "axios";
import AuthService from "@/services/AuthService.js";

export default {
  name: "LahanForm",

  data() {
    return {
      // Data Lahan
      ladang: "",
      kebun: "",
      kolam: "",
      tebuka: "",
      permukiman: "",
      sawah: "",
      semak: "",
      pemakaman: "",
      hutan: "",
      lainnya: "",

      // VALIDASI
      satuanRules: [
        (v) => /^[0-9.]+$/.test(v) || "Hanya angka dan titik koma",
        (v) => !!v || "bidang ini tidak boleh kosong",
      ],
      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],
      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],

      // Loder
      message: "",
      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,
      dialogfinal: false,
      valid: true,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    // api status kk
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },

    async simpanLahan() {
      try {
        this.loading = true;
        const credentials = {
          ladang: this.ladang,
          kebun: this.kebun,
          kolam: this.kolam,
          tebuka: this.tebuka,
          permukiman: this.permukiman,
          sawah: this.sawah,
          semak: this.semak,
          pemakaman: this.pemakaman,
          hutan: this.hutan,
          lainnya: this.lainnya,
        };

        const response = await AuthService.tambahLahan(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil simpan lahan");

          this.$router.push("/admin-kelurahan/lahan/data").catch(() => {});
          this.loading = false;
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },

  created: function () {
    this.getDusun();
  },
  async mounted() {
    this.detailUsers();
  },
};
</script>
