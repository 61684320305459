<template>
  <v-container class="py-5 px-6" fluid>
    <v-card class="rounded-card" elevation="0">
      <v-toolbar elevation="0">
        <v-toolbar-title>
          <h4>Detail Data Bangunan</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          class="ma-2 white--text"
          color="black"
          :to="'/admin-kelurahan/bangunan/data'"
          ><v-icon color="white" left>mdi-arrow-left</v-icon>kembali
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex justify-space-between mb-6">
          <v-col>
            <v-row dense>
              <v-col cols="12" md="6">
                <h4>Nomor Bangunan</h4>
              </v-col>
              <v-col cols="12" md="6">
                <h4>: {{ isibangunan.no_bangunan }}</h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <h4>Jaringan Telpon / Internet Kabel</h4>
              </v-col>
              <v-col cols="12" md="6">
                <h4>: {{ isibangunan.jaringan_telepon }}</h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <h4>Jaringan Telpon GSM</h4>
              </v-col>
              <v-col cols="12" md="6">
                <h4>: {{ isibangunan.jaringan_gsm }}</h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <h4>Jaringan Internet GSM</h4>
              </v-col>
              <v-col cols="12" md="6">
                <h4>: {{ isibangunan.internet_gsm }}</h4>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row dense>
              <v-col cols="12" md="4">
                <h4>Kabupaten/Kota</h4>
              </v-col>
              <v-col cols="12" md="8">
                <h4>: {{ isibangunan.kota }}</h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4">
                <h4>Kecamatan</h4>
              </v-col>
              <v-col cols="12" md="8">
                <h4>: {{ isibangunan.kecamatan }}</h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4">
                <h4>Kelurahan/Desa</h4>
              </v-col>
              <v-col cols="12" md="8">
                <h4>: {{ isibangunan.kelurahan }}</h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4">
                <h4>Dusun</h4>
              </v-col>
              <v-col cols="12" md="8">
                <h4>: {{ isibangunan.dusun }}</h4>
              </v-col>
            </v-row>
          </v-col>

          <h4 class="pe-5 pt-3 dark--text">
            Kategori Bangunan <br />

            <h4 v-for="items in isibangunan.kategori" :key="items">
              <v-chip
                class="mb-1"
                v-show="items === 'Sarana Umum'"
                small
                color="green"
                dark
              >
                {{ items }}
              </v-chip>

              <v-chip
                class="mb-1"
                v-show="items === 'Wirausaha'"
                small
                color="orange"
                dark
              >
                {{ items }}
              </v-chip>

              <v-chip
                class="mb-1"
                v-show="items === 'Rumah Tinggal Kosong'"
                small
                color="cyan"
                dark
              >
                {{ items }}
              </v-chip>

              <v-chip
                class="mb-1"
                v-show="items === 'Rumah Tinggal Isi'"
                small
                color="blue"
                dark
              >
                {{ items }}
              </v-chip>
            </h4>
          </h4>
        </div>
      </v-card-text>
    </v-card>

    <br />
    <v-skeleton-loader
      v-if="sloading"
      :loading="firstLoad"
      type="article, actions"
    ></v-skeleton-loader>
    <div v-for="(isibg, index) in isibangunan.kategori" :key="index" v-else>
      <v-card class="rounded-card" elevation="0">
        <v-card-text>
          <v-row v-if="isibg === 'Sarana Umum'" dense>
            <v-col cols="12" md="12">
              <h3 class="teal--text pb-2">Sarana Umum</h3>
              <v-divider class="pb-3"></v-divider>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jenis Sarana Umum"
                :value="isibangunan.saranavalue.jenis_saranaumum"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Nama Sarana Umum"
                :value="isibangunan.saranavalue.nama"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jenis Bangunan"
                :value="isibangunan.saranavalue.jenis_bangunan"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Status Bangunan"
                :value="isibangunan.saranavalue.status_bangunan"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Status Lahan"
                :value="isibangunan.saranavalue.status_lahan"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Dibangun Tahun"
                :value="isibangunan.saranavalue.tahun_bangun"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Penganggu Jawab"
                :value="isibangunan.saranavalue.penanggung_jawab"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Nomor Telpon"
                :value="isibangunan.saranavalue.no_tlp"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Link Foto 360 derajat"
                :value="isibangunan.saranavalue.link_foto"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-btn
                outlined
                color="dark"
                target="_blank"
                :href="`${isibangunan.saranavalue.link_foto}`"
              >
                <v-icon dense center> mdi-camera-iris </v-icon> &nbsp;Link 360
                derajat &nbsp;
                <v-icon dense center> mdi-arrow-right </v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="isibg === 'Wirausaha'" dense>
            <v-col cols="12" md="12">
              <h3 class="teal--text pb-2">Wirausaha</h3>
              <v-divider class="pb-3"></v-divider>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Nama Usaha"
                :value="isibangunan.wirausahavalue.nama_usaha"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Pemilik Usaha"
                :value="isibangunan.wirausahavalue.nama_pemilik"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jenis Usaha"
                :value="isibangunan.wirausahavalue.jenis_usaha"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Status Operasional"
                :value="isibangunan.wirausahavalue.status_operational"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="2">
              <v-text-field
                color="teal"
                label="Jam Buka"
                :value="isibangunan.wirausahavalue.jam_buka"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="2">
              <v-text-field
                color="teal"
                label="Jam Tutup"
                :value="isibangunan.wirausahavalue.jam_tutup"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Tahun Mulai Usaha"
                :value="isibangunan.wirausahavalue.tahun_mulai"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Sumber Modal Usaha"
                :value="isibangunan.wirausahavalue.sumber_modal"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Memiliki Ijin Usaha"
                :value="isibangunan.wirausahavalue.izin_usaha"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Mengenakan Pajak Konsumen"
                :value="isibangunan.wirausahavalue.pajak_konsumen"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Nomor Telepon"
                :value="isibangunan.wirausahavalue.no_telp"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Link Foto 360 derajat"
                :value="isibangunan.wirausahavalue.link_foto"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                outlined
                color="dark"
                target="_blank"
                :href="isibangunan.wirausahavalue.link_foto"
              >
                <v-icon dense center> mdi-camera-iris </v-icon> &nbsp;Link 360
                derajat &nbsp;
                <v-icon dense center> mdi-arrow-right </v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="isibg === 'Rumah Tinggal Kosong'" dense>
            <v-col cols="12" md="12">
              <h3 class="teal--text pb-2">Rumah Tinggal Kosong</h3>
              <v-divider class="pb-3"></v-divider>
            </v-col>

            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Nama Pemilik Rumah"
                :value="isibangunan.rtkvalue.nama_pemilik"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Secara Keseluruhan Kondisi Rumah"
                :value="isibangunan.rtkvalue.kondisi_rumah"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Status SPPT PBB"
                :value="isibangunan.rtkvalue.sptpbb"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Nomor Telepon"
                :value="isibangunan.rtkvalue.no_telp"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Link Foto 360 derajat"
                :value="isibangunan.rtkvalue.link_foto"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                outlined
                color="dark"
                target="_blank"
                :href="`${isibangunan.rtkvalue.link_foto}`"
              >
                <v-icon dense center> mdi-camera-iris </v-icon> &nbsp;Link 360
                derajat &nbsp;
                <v-icon dense center> mdi-arrow-right </v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="isibg === 'Rumah Tinggal Isi'" dense>
            <v-col cols="12" md="12">
              <h3 class="teal--text pb-2">Rumah Tinggal Isi</h3>
              <v-divider class="pb-3"></v-divider>
            </v-col>

            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Nama Pemilik Rumah"
                :value="isibangunan.rtivalue[0].nama_pemilik"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Nomor Telepon"
                :value="isibangunan.rtivalue[0].no_telp"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Tempat Tinggal yang Ditempati"
                :value="isibangunan.rtivalue[0].tempat_tinggal"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Status Lahan Tempat Tinggal yang Ditempati"
                :value="isibangunan.rtivalue[0].status_lahan"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
              <v-text-field
                color="teal"
                label="Luas Lahan Bangunan"
                :value="isibangunan.rtivalue[0].luas_lahan + format.luas"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="2">
              <v-text-field
                color="teal"
                label="Luas Lantai Bangunan"
                :value="isibangunan.rtivalue[0].luas_lantai + format.luas"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jenis Lantai Tempat Tinggal yang Ditempati"
                :value="isibangunan.rtivalue[0].jenis_lantai"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Dinding Sebagian Besar Rumah"
                :value="isibangunan.rtivalue[0].dinding"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Status Jendela"
                :value="isibangunan.rtivalue[0].jendela"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Bahan Atap"
                :value="isibangunan.rtivalue[0].atap"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Penerangan Rumah"
                :value="isibangunan.rtivalue[0].penerangan"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Energi Untuk Masak"
                :value="isibangunan.rtivalue[0].energi_masak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <template
              v-if="isibangunan.rtivalue[0].energi_masak === 'Kayu bakar'"
            >
              <v-col cols="4" md="4">
                <v-text-field
                  color="teal"
                  label="Pilih Sumber Kayu Bakar"
                  :value="isibangunan.rtivalue[0].kayu_bakar"
                  readonly
                  outlined
                  required
                  dense
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Tempat Pembuangan Sampah"
                :value="isibangunan.rtivalue[0].tempat_pembuangan_sampah"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Fasilitas MCK"
                :value="isibangunan.rtivalue[0].fasilitas_mck"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Sumber Air Mandi Terbanyak Dari"
                :value="isibangunan.rtivalue[0].sumber_air_mandi"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Fasilitas Buang Air Besar"
                :value="isibangunan.rtivalue[0].fasilitas_bab"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Sumber Air Minum Terbanyak Dari"
                :value="isibangunan.rtivalue[0].sumber_air_minum"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Tempat Pembuangan Limbah Cair"
                :value="isibangunan.rtivalue[0].tempat_limbah"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Bangunan Rumah berada di bawah SUTET/SUTT/SUTTAS"
                :value="isibangunan.rtivalue[0].rumah_sutet"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Bangunan Rumah di bantaran sungai"
                :value="isibangunan.rtivalue[0].rumah_bantaran_sungai"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Rumah di lereng bukit/gunung"
                :value="isibangunan.rtivalue[0].rumah_lereng_bukit"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Memiliki Kendaraan Bermotor"
                :value="isibangunan.rtivalue[0].kendaraan"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-for="(item, index) in isibangunan.rtivalue[0].kendaraan"
              :key="index"
              cols="2"
            >
              <v-text-field
                v-show="item === 'Mobil'"
                :rules="nomorRules"
                color="teal"
                :value="isibangunan.rtivalue[0].jumlah_mobil"
                label="Jumlah Mobil"
                outlined
                dense
                required
              ></v-text-field>
              <v-text-field
                v-show="item === 'Motor'"
                :rules="nomorRules"
                color="teal"
                :value="isibangunan.rtivalue[0].jumlah_motor"
                label="Jumlah Motor"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Secara Keseluruhan Kondisi Rumah"
                :value="isibangunan.rtivalue[0].kondisi_rumah"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-divider class="pt-0 pb-1"></v-divider>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Transportasi ke Pekerjaan Umum"
                :value="isibangunan.rtivalue[0].transportasi_umum"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Transportasi ke Lahan Pertanian"
                :value="isibangunan.rtivalue[0].transportasi_lahan_pertanian"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Transportasi ke Sekolah"
                :value="isibangunan.rtivalue[0].transportasi_sekolah"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Transportasi ke Berobat"
                :value="isibangunan.rtivalue[0].transportasi_berobat"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Transportasi ke Beribadah"
                :value="isibangunan.rtivalue[0].transportasi_beribadah"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Transportasi ke Rekreasi Terdekat"
                :value="isibangunan.rtivalue[0].transportasi_rekreasi"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Link Foto 360 derajat"
                :value="isibangunan.rtivalue[0].link_foto"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                outlined
                color="dark"
                target="_blank"
                :href="`${isibangunan.rtivalue[0].link_foto}`"
              >
                <v-icon dense center> mdi-camera-iris </v-icon> &nbsp;Link 360
                derajat &nbsp;
                <v-icon dense center> mdi-arrow-right </v-icon></v-btn
              >
              <br />
            </v-col>

            <v-col cols="12" md="12">
              <v-divider class="pt-3 pb-0"></v-divider>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Masjid"
                :value="isibangunan.rtivalue[0].akses_jalan.a1 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Musaolla / Surau"
                :value="isibangunan.rtivalue[0].akses_jalan.a2 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Kantor Desa"
                :value="isibangunan.rtivalue[0].akses_jalan.a3 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Kantor Kecamatan"
                :value="isibangunan.rtivalue[0].akses_jalan.a4 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Kantor Kota"
                :value="isibangunan.rtivalue[0].akses_jalan.a5 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke TK"
                :value="isibangunan.rtivalue[0].akses_jalan.a6 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke PAUD"
                :value="isibangunan.rtivalue[0].akses_jalan.a7 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke SD"
                :value="isibangunan.rtivalue[0].akses_jalan.a8 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke SMP"
                :value="isibangunan.rtivalue[0].akses_jalan.a9 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke SMA"
                :value="isibangunan.rtivalue[0].akses_jalan.a10 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Perguruan Tinggi"
                :value="isibangunan.rtivalue[0].akses_jalan.a11 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Sekolah Agama SD Sederajat"
                :value="isibangunan.rtivalue[0].akses_jalan.a12 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Sekolah Agama SMP Sederajat"
                :value="isibangunan.rtivalue[0].akses_jalan.a13 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Sekolah Agama SMA Sederajat"
                :value="isibangunan.rtivalue[0].akses_jalan.a14 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Pesantren"
                :value="isibangunan.rtivalue[0].akses_jalan.a15 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Pasar"
                :value="isibangunan.rtivalue[0].akses_jalan.a16 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Rumah Sakit"
                :value="isibangunan.rtivalue[0].akses_jalan.a17 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Poliklinik"
                :value="isibangunan.rtivalue[0].akses_jalan.a18 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Rumah Sakit Bersalin"
                :value="isibangunan.rtivalue[0].akses_jalan.a19 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Puskesmas"
                :value="isibangunan.rtivalue[0].akses_jalan.a20 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Puskesma Pembantu / Pustu"
                :value="isibangunan.rtivalue[0].akses_jalan.a21 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Polindes"
                :value="isibangunan.rtivalue[0].akses_jalan.a22 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Poskesdes"
                :value="isibangunan.rtivalue[0].akses_jalan.a23 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Posyandu"
                :value="isibangunan.rtivalue[0].akses_jalan.a24 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Apotik"
                :value="isibangunan.rtivalue[0].akses_jalan.a25 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Toko Obat"
                :value="isibangunan.rtivalue[0].akses_jalan.a26 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Dokter Spesialis"
                :value="isibangunan.rtivalue[0].akses_jalan.a27 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Dokter Umum"
                :value="isibangunan.rtivalue[0].akses_jalan.a28 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Bidan"
                :value="isibangunan.rtivalue[0].akses_jalan.a29 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Tenaga Kesehatan"
                :value="isibangunan.rtivalue[0].akses_jalan.a30 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                color="teal"
                label="Jarak ke Dukun"
                :value="isibangunan.rtivalue[0].akses_jalan.a31 + format.jarak"
                readonly
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-divider class="pt-0 pb-1"></v-divider>
            </v-col>
            <v-col cols="3">
              <v-sheet outlined color="teal" elevation="0" rounded>
                <v-card color="white lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">Total Kartu Keluarga</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ isibangunan.TotalKK }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card></v-sheet
              >
            </v-col>
            <v-col cols="3">
              <v-sheet outlined color="teal" elevation="0" rounded>
                <v-card color="white lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">Total Penduduk</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ isibangunan.totalPenduduk }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card></v-sheet
              >
            </v-col>
            <v-col cols="3">
              <v-sheet outlined color="teal" elevation="0" rounded>
                <v-card color="white lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">Total Laki-Laki</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ isibangunan.totalLakiLaki }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card></v-sheet
              >
            </v-col>
            <v-col cols="3">
              <v-sheet outlined color="teal" elevation="0" rounded>
                <v-card color="white lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">Total Perempuan</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ isibangunan.totalPerempuan }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card></v-sheet
              >
            </v-col>
            <v-col cols="3">
              <v-sheet outlined color="teal" elevation="0" rounded>
                <v-card color="white lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">Total Menetap</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ isibangunan.totalMenetap }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card></v-sheet
              >
            </v-col>
            <v-col cols="3">
              <v-sheet outlined color="teal" elevation="0" rounded>
                <v-card color="white lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">Total Merantau</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ isibangunan.totalMerantau }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card></v-sheet
              >
            </v-col>
            <v-col cols="3">
              <v-sheet outlined color="teal" elevation="0" rounded>
                <v-card color="white lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">Total Disabilitas</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ isibangunan.totaldisabilitas }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card></v-sheet
              >
            </v-col>
            <v-col cols="3">
              <v-sheet outlined color="teal" elevation="0" rounded>
                <v-card color="white lighten-5">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-bold mb-4">Total Stunting</div>
                      <v-list-item-title class="text-h5 mb-1"
                        >{{ isibangunan.totalStanting }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card></v-sheet
              >
            </v-col>
            <v-col cols="12" class="pt-2">
              <br />
              <h4>Daftar Kartu Keluarga</h4>
              <v-divider class="pt-3 pb-3"></v-divider>

              <div>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">Nomor Kartu Keluarga</th>
                          <th class="text-left">Kemiskinan</th>
                          <th class="text-left">Kepala Keluarga</th>
                          <th class="text-left">Jumlah Keluarga</th>

                          <th class="text-center">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in isibangunan.rtivalue[0].dataBangunan"
                          :key="item.id"
                        >
                          <td>{{ item.kartu_keluarga[0].nokk }}</td>
                          <td>{{ item.kartu_keluarga[0].status_miskin }}</td>
                          <td>{{ item.kelapa_keluarga.nama }}</td>
                          <td>{{ item.jumlah_anggota_kk }}</td>
                          <td class="text-center">
                            <v-btn
                              class="lighten-4"
                              @click="showdetailPenduduk(item)"
                              outlined
                              small
                              color="blue"
                            >
                              <v-icon dense center> mdi-information </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
              <br />
              <!-- {{ itemsbansos.length }} -->
              <div v-show="itemsbansos.length > 0">
                <h4>Penerima Bantuan Sosial</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">No KK</th>
                          <th class="text-left">Nama Kepala Keluarga</th>
                          <th class="text-left">Kategori Bantuan</th>
                          <th class="text-left">Bulan</th>
                          <th class="text-left">Besar Bantuan</th>
                          <th class="text-left">Tahun</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="items in itemsbansos" :key="items">
                          <td>{{ items.nokk }}</td>
                          <td>{{ items.nama }}</td>
                          <td>{{ items.kategori_bantuan }}</td>
                          <td>{{ items.bulan }}</td>
                          <td>
                            {{
                              (items.nilai_bantuan + "")
                                .replace(/\D/g, "")
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }}
                          </td>
                          <td>{{ items.tahun }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
              <br />

              <div v-show="itemssaga.length > 0">
                <h4>Penerima Bantuan Sagasaja</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">NIK</th>
                          <th class="text-left">Nama Penerima</th>
                          <th class="text-left">Tahun</th>
                          <th class="text-left">Perguruan Tinggi</th>
                          <th class="text-left">Asal Sekolah</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemssaga" :key="item.id">
                          <td>{{ item.nik }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.tahun }}</td>
                          <td>{{ item.perguruan_tinggi }}</td>
                          <td>{{ item.asal_sekolah }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
              <br />
              <div v-show="itemikan.length > 0">
                <h4>Perikanan - Ikan Budidaya</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">NIK</th>
                          <th class="text-left">Nama</th>
                          <th class="text-left">Jenis Budidaya</th>
                          <th class="text-left">Jumlah Produksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemikan" :key="item.id">
                          <td>{{ item.nik }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.jenis_budidaya }}</td>
                          <td>{{ item.jmlh_produksi }} Kg</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>

              <br />
              <div v-show="ikantangkaps.length > 0">
                <h4>Perikanan - Ikan Tangkap</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">NIK</th>
                          <th class="text-left">Nama</th>
                          <th class="text-left">Kategori</th>
                          <th class="text-left">Alat Tangkap</th>
                          <th class="text-left">Kapal Tangkap</th>
                          <th class="text-left">Produksi Rata-Rata Triwulan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in ikantangkaps" :key="item.id">
                          <td>{{ item.nik }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.kategori }}</td>
                          <td>{{ item.jenis_alat }}</td>
                          <td>{{ item.jenis_kapal }}</td>

                          <td>{{ item.jumlah_produksi }} Kg</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>

              <br />
              <div v-show="ibuhamils.length > 0">
                <h4>Posyandu - Ibu Hamil</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">NIK</th>
                          <th class="text-left">Nama</th>
                          <th class="text-left">Usia Kandungan</th>
                          <th class="text-left">Kondisi Kandungan</th>
                          <th class="text-left">Index Masa Tubuh</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in ibuhamils" :key="item.id">
                          <td>{{ item.nik }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.usia_kandungan }}</td>
                          <td>{{ item.kondisi_kandungan }}</td>
                          <td>{{ item.index_massa_tubuh }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>

              <br />
              <div v-show="itembalitas.length > 0">
                <h4>Posyandu - Balita</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">NIK / KIA</th>
                          <th class="text-left">Nama</th>
                          <th class="text-left">Tinggi Badan</th>
                          <th class="text-left">Berat Badan</th>
                          <th class="text-left">Lingkar Kepala</th>
                          <th class="text-left">Imunisasi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itembalitas" :key="item.id">
                          <td>{{ item.no_kia }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.tinggi_badan }}</td>
                          <td>{{ item.berat_badan }}</td>
                          <td>{{ item.lingkar_kepala }}</td>
                          <td>{{ item.imunisasi }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>

              <br />
              <div v-show="itemskabe.length > 0">
                <h4>Posyandu - Keluarga Berencana</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">NIK</th>
                          <th class="text-left">Nama</th>
                          <th class="text-left">Alat Kontrasepsi</th>
                          <th class="text-left">Jenis Kontrasepsi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemskabe" :key="item.id">
                          <td>{{ item.nik }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.alat_kontrasepsi }}</td>
                          <td>{{ item.jenis_kontrasepsi }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>

              <br />
              <div v-show="itemspkks.length > 0">
                <h4>Dasawisma - PKK</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">No KK</th>
                          <th class="text-left">Pancasila</th>
                          <th class="text-left">Gotong Royong</th>
                          <th class="text-left">Pendidikan Keterampilan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemspkks" :key="item.id">
                          <td>{{ item.nokk }}</td>
                          <td>{{ item.pancasila }}</td>
                          <td>{{ item.gotong_royong }}</td>
                          <td>{{ item.pendidikan_keterampilan }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>

              <br />
              <div v-show="ternakunggas.length > 0">
                <h4>Peternakan - Ternak Unggas</h4>
                <v-divider class="pt-3 pb-3"></v-divider>
                <v-card elevation="2" color="teal lighten-3">
                  <v-simple-table>
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left">NIK</th>
                          <th class="text-left">Nama</th>
                          <th class="text-left">Jenis Ternak</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in ternakunggas" :key="item.id">
                          <td>{{ item.nik }}</td>
                          <td>{{ item.nama }}</td>
                          <td>{{ item.jenis_ternak.split(",") }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
    </div>

    <v-card>
      <h4 class="pl-3 pt-3 dark--text">
        Lokasi Bangunan : Latitude: {{ isibangunan.lat }} , Longitude:
        {{ isibangunan.long }}
      </h4>

      <v-card-text>
        <MapsDetail
          v-bind:markerslat="markerslat"
          v-bind:markerslong="markerslong"
          @data-peta="petaValue"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="pe-3">
          <v-btn
            color="primary"
            target="_blank"
            :href="`https://maps.google.com/?q=${isibangunan.link_bangunan}`"
          >
            <v-icon dense center> mdi-map </v-icon> &nbsp;Lokasi Bangunan &nbsp;
            <v-icon dense center> mdi-arrow-right </v-icon></v-btn
          >
        </div>
      </v-card-actions>
    </v-card>

    <v-dialog scrollable v-model="dialogpenduduk" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2">Anggota Keluarga</strong>
            <v-btn
              small
              color="red"
              class="white--text"
              @click="dialogpenduduk = false"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>
        <v-card-text>
          <div v-for="itemindi in individuItem" :key="itemindi.id">
            <h3 class="cyan--text pt-3 pb-5">
              Data Individu ( {{ itemindi.StatusKeluarga }} )
            </h3>

            <v-row dense>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Nomor Induk Kependudukan (NIK)"
                  :value="itemindi.nik"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Nama Lengkap"
                  :value="itemindi.nama"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Jenis Kelamin"
                  :value="itemindi.jk"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Tempat Lahir"
                  :value="itemindi.tmpt_lahir"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Tanggal Lahir"
                  :value="itemindi.tgl_lahir"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Tinggal"
                  :value="itemindi.status_tinggal"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Agama"
                  :value="itemindi.agama"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Perkawinan"
                  :value="itemindi.status_kawin"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="itemindi.status_kawin === 'Kawin'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Tahun Pernikahan"
                    :value="itemindi.tahun_kawin"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>

              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Kewarganegaraan"
                  :value="itemindi.kewarganegaraan"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Hak Pilih Politik"
                  :value="itemindi.hak_politik"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-divider class="pa-0"></v-divider>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Pekerjaan"
                  :value="itemindi.status_pekerjaan"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <template v-if="itemindi.status_pekerjaan === 'Bekerja'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Pekerjaan"
                    :value="itemindi.pekerjaan"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>

              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Pendidikan tertinggi yang ditamatkan"
                  :value="itemindi.pendidikan"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <template v-if="itemindi.status_jkn === 'Ya'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Peserta Jaminan Kesehatan Nasional"
                    :value="itemindi.status_jkn"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Jaminan Kesehatan Nasional"
                    :value="itemindi.id_jkn"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="itemindi.status_jkn !== 'Ya'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Peserta Jaminan Kesehatan Nasional"
                    :value="itemindi.status_jkn"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>

              <template v-if="itemindi.penyakit === 'Ada'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Penyakit yang diderita 1 tahun terakhir"
                    :value="itemindi.penyakit"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Penyakit yang diderita"
                    :value="itemindi.id_penyakit"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="itemindi.penyakit !== 'Ada'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Penyakit yang diderita 1 tahun terakhir"
                    :value="itemindi.penyakit"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>

              <template v-if="itemindi.disabilitas === 'Ya'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Disabilitas"
                    :value="itemindi.disabilitas"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Data Disabilitas"
                    :value="itemindi.id_disabilitas"
                    filled
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <template v-if="itemindi.disabilitas !== 'Ya'">
                <v-col cols="4" md="4"
                  ><v-text-field
                    color="teal"
                    label="Disabilitas"
                    :value="itemindi.disabilitas"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </template>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Stunting"
                  :value="itemindi.stanting"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-card color="grey lighten-4">
                  <v-card-title class="layout justify-center pa-0">
                    <h5>Foto KTP</h5></v-card-title
                  >
                  <img
                    v-bind:src="`https://apidata.satudatapariamankota.id/storage/FotoKTP/${itemindi.foto_ktp}`"
                    style="width: 100%; height: 420px"
                  />
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card color="grey lighten-4">
                  <v-card-title class="layout justify-center pa-0">
                    <h5>Foto Selfie</h5></v-card-title
                  >
                  <img
                    v-bind:src="`https://apidata.satudatapariamankota.id/storage/FotoSelfie/${itemindi.foto_selfie}`"
                    style="width: 100%; height: 420px"
                  />
                </v-card>
              </v-col>
              <v-col>
                <v-divider></v-divider>
                <br />
              </v-col>
            </v-row>
          </div>
          <br />
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import MapsDetail from "@/components/MapsDetail.vue";
export default {
  components: {
    MapsDetail,
  },
  name: "BangunanDetail",
  props: ["id"],
  data() {
    return {
      // cek edit
      tampungstep: [
        {
          item: "",
          status: "",
        },
      ],
      itemsbansos: [{}],
      itemssaga: [{}],

      // Detail Data Master
      isibangunan: [{}],
      markerslat: "",
      markerslong: "",

      //items
      itemsdusun: [{}],
      itemsinet: [{}],

      // Items SARANA UMUM
      itemssarana: [{}],
      itemsjenisbangunan: [{}],
      itemsstatusbangunan: [{}],
      itemsstatuslahan: [{}],

      // Items Wirausaha
      itemsoperasional: [{}],
      itemsmodalusaha: [{}],
      itemsyatidak: [{}],
      // Items Rumah kosong
      itemskondisirumah: [{}],
      itemspbb: [{}],

      // Items Rumah Tinggal Isi
      itemstempat_tinggal: [{}],
      itemsstatus_lahan: [{}],
      itemsjenis_lantai: [{}],
      itemsdinding: [{}],
      itemsjendela: [{}],
      itemsbahan_atap: [{}],
      itemspenerangan_rumah: [{}],
      itemsenergi_masak: [{}],
      itemssumber_kayu: [{}],
      itemsbuang_sampah: [{}],
      itemsmck: [{}],
      itemsair_mandi: [{}],
      itemsair_besar: [{}],
      itemsair_minum: [{}],
      itemsbuang_limbah: [{}],
      itemskendaraan: [{}],
      itemstransportasi: [{}],

      // step 1
      e1: 1,
      toStep: null,
      itemskatbangunan: [{}],

      editBang: {
        no_bangunan: "",
        kategori_bangunan: "",
        jaringan_telepon: "",
        jaringan_gsm: "",
        internet_gsm: "",
        lat: "",
        lng: "",
        kelurahan: "",
        kecamatan: "",
        kota: "",
        id_dusun: "",
      },

      cekado: {
        saranastep: "",
        wirastep: "",
        rtkstep: "",
        rtistep: "",
      },
      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      // Loading Data
      sloading: true,
      firstLoad: true,
      valid: true,
      loader: null,
      loading: false,
      dialogfinal: false,
      // VALIDATION
      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      format: {
        jarak: "  KM",
        luas: " m2",
      },
      dialogpenduduk: false,
      individuItem: [{}],

      //baru revisi

      itemikan: [{}],
      ikantangkaps: [{}],
      ibuhamils: [{}],
      itembalitas: [{}],
      itemskabe: [{}],
      itemspkks: [{}],
      ternakunggas: [{}],
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getDetailBang();

    this.detailUsers();
    this.getDusun();
    this.getBantuanByKK();
    this.getSagasajaByKK();
    this.getIkanBudidayaByKK();
    this.getIkanTangkapByKK();
    this.getIbuHamilByKK();
    this.getDataBalitaByKK();
    this.getDataKBByKK();
    this.getDataPKKByKK();
    this.getTernakUnggasByKK();
  },
  methods: {
    getTernakUnggasByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getTernakUnggasByKK(credentials).then(
        function (response) {
          this.ternakunggas = response.data;

          console.log(response.data);
        }.bind(this)
      );
    },
    getDataPKKByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getDataPKKByKK(credentials).then(
        function (response) {
          this.itemspkks = response.data;
        }.bind(this)
      );
    },
    getDataKBByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getDataKBByKK(credentials).then(
        function (response) {
          this.itemskabe = response.data;
        }.bind(this)
      );
    },
    getDataBalitaByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getDataBalitaByKK(credentials).then(
        function (response) {
          this.itembalitas = response.data;
        }.bind(this)
      );
    },
    getIbuHamilByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getIbuHamilByKK(credentials).then(
        function (response) {
          this.ibuhamils = response.data;
        }.bind(this)
      );
    },
    getIkanTangkapByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getIkanTangkapByKK(credentials).then(
        function (response) {
          this.ikantangkaps = response.data;
        }.bind(this)
      );
    },
    showdetailPenduduk(item) {
      this.individuItem = item.anggota_kk;
      (this.dialogpenduduk = true), console.log(item.anggota_kk);
    },
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
        }.bind(this)
      );
    },

    getDetailBang: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.detailBangunan(credentials).then(
        function (response) {
          this.isibangunan = response.data;
          console.log("Data Detail Bangunan");
          // console.log(this.isibangunan.kategori);

          this.markerslat = this.isibangunan.lat;
          this.markerslong = this.isibangunan.long;

          // Bangunan awal
          this.editBang.no_bangunan = this.isibangunan.no_bangunan;
          this.editBang.kategori_bangunan = this.isibangunan.kategori;
          this.editBang.jaringan_telepon = this.isibangunan.jaringan_telepon;
          this.editBang.jaringan_gsm = this.isibangunan.jaringan_gsm;
          this.editBang.internet_gsm = this.isibangunan.internet_gsm;
          this.editBang.id_dusun = this.isibangunan.id_dusun;

          this.cekado.wirastep =
            this.isibangunan.kategori.includes("Wirausaha");
          this.cekado.saranastep =
            this.isibangunan.kategori.includes("Sarana Umum");
          this.cekado.rtkstep = this.isibangunan.kategori.includes(
            "Rumah Tinggal Kosong"
          );

          this.cekado.rtistep =
            this.isibangunan.kategori.includes("Rumah Tinggal Isi");

          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    getBantuanByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getBantuanByKK(credentials).then(
        function (response) {
          this.itemsbansos = response.data.data;
        }.bind(this)
      );
    },
    getSagasajaByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getSagasajaByKK(credentials).then(
        function (response) {
          this.itemssaga = response.data.data;
        }.bind(this)
      );
    },
    getIkanBudidayaByKK: function () {
      const credentials = {
        id: this.id,
      };
      AuthService.getIkanBudidayaByKK(credentials).then(
        function (response) {
          this.itemikan = response.data;
        }.bind(this)
      );
    },

    getKategoriBangunan: function () {
      AuthService.getKategoriBangunan().then(
        function (response) {
          this.itemskatbangunan = response.data.data;
        }.bind(this)
      );
    },

    dialogFinal(dialogFinal) {
      this.dialogfinal = true;
      console.log("PREVIEW DATA");
    },
    // VALUE SARANA UMUM
    petaValue(petaValue) {
      this.isibangunan.lat = petaValue.latitude;
      this.isibangunan.long = petaValue.longitude;
    },

    nextPetama() {
      this.e1 = 2;
      console.log("ini step pertama");
      this.toStep = this.isibangunan.kategori.length - 1;

      console.log(this.kategori_bangunan);
    },
    saranaUmumValue(saranaValue) {
      this.isisaranaumum = saranaValue;
      console.log("ini di SARANA");
      console.log(saranaValue);
    },
    rktValue(rktValue) {
      this.isirkt = rktValue;
      console.log("ini di RKT");
      console.log(rktValue);
    },
    rtiValue(rtiValue) {
      this.isirti = rtiValue;
      console.log("ini di RTI");
      console.log(rtiValue);
    },
    wirausahaValue(wirausahaValue) {
      this.isiwirausaha = wirausahaValue;
      console.log("ini di Wirausaha Value");
      console.log(wirausahaValue);
    },
    stepValue(stepSebelum) {
      this.e1 = stepSebelum;
      console.log("ini step");
      console.log(stepSebelum);
    },
    stepLanjutValue(stepLanjut) {
      this.e1 = stepLanjut;
      console.log("ini step");
      console.log(stepLanjut);
    },

    // SARANAUMUM
    getJenusSaranaUmum: function () {
      AuthService.getJenusSaranaUmum().then(
        function (response) {
          this.itemssarana = response.data.data;
        }.bind(this)
      );
    },
    getJenisBangunan: function () {
      AuthService.getJenisBangunan().then(
        function (response) {
          this.itemsjenisbangunan = response.data.data;
        }.bind(this)
      );
    },

    getStatusBangunan: function () {
      AuthService.getStatusBangunan().then(
        function (response) {
          this.itemsstatusbangunan = response.data.data;
        }.bind(this)
      );
    },
    getStatusLahan: function () {
      AuthService.getStatusLahan().then(
        function (response) {
          this.itemsstatuslahan = response.data.data;
        }.bind(this)
      );
    },

    // WIRAUSAHA

    getStatusOperational: function () {
      AuthService.getStatusOperational().then(
        function (response) {
          this.itemsoperasional = response.data.data;
        }.bind(this)
      );
    },
    getSumberModal: function () {
      AuthService.getSumberModal().then(
        function (response) {
          this.itemsmodalusaha = response.data.data;
        }.bind(this)
      );
    },
    getYatidak: function () {
      AuthService.getYaTidak().then(
        function (response) {
          this.itemsyatidak = response.data.data;
        }.bind(this)
      );
    },
    getStatusJaringan: function () {
      AuthService.getStatusJaringan().then(
        function (response) {
          this.itemsinet = response.data.data;
        }.bind(this)
      );
    },

    // ITEM RUMAH KOSONG
    getKondisiRumah: function () {
      AuthService.getKondisiRumah().then(
        function (response) {
          this.itemskondisirumah = response.data.data;
        }.bind(this)
      );
    },
    getStatusSPTPBB: function () {
      AuthService.getStatusSPTPBB().then(
        function (response) {
          this.itemspbb = response.data.data;
        }.bind(this)
      );
    },

    // ITEM RUMAH TINGGAL ISI
    getTempatTinggal: function () {
      AuthService.getTempatTinggal().then(
        function (response) {
          this.itemstempat_tinggal = response.data.data;
        }.bind(this)
      );
    },
    getStatusLahanB: function () {
      AuthService.getStatusLahanB().then(
        function (response) {
          this.itemsstatus_lahan = response.data.data;
        }.bind(this)
      );
    },
    getJenisLantai: function () {
      AuthService.getJenisLantai().then(
        function (response) {
          this.itemsjenis_lantai = response.data.data;
        }.bind(this)
      );
    },
    getJenisDinding: function () {
      AuthService.getJenisDinding().then(
        function (response) {
          this.itemsdinding = response.data.data;
        }.bind(this)
      );
    },
    getStatusJendela: function () {
      AuthService.getStatusJendela().then(
        function (response) {
          this.itemsjendela = response.data.data;
        }.bind(this)
      );
    },
    getBahanAtap: function () {
      AuthService.getBahanAtap().then(
        function (response) {
          this.itemsbahan_atap = response.data.data;
        }.bind(this)
      );
    },
    getPeneranganRumah: function () {
      AuthService.getPeneranganRumah().then(
        function (response) {
          this.itemspenerangan_rumah = response.data.data;
        }.bind(this)
      );
    },

    getEnergiMasak: function () {
      AuthService.getEnergiMasak().then(
        function (response) {
          this.itemsenergi_masak = response.data.data;
        }.bind(this)
      );
    },
    getKayuBakar: function () {
      AuthService.getKayuBakar().then(
        function (response) {
          this.itemssumber_kayu = response.data.data;
        }.bind(this)
      );
    },
    getPembuanganSampah: function () {
      AuthService.getPembuanganSampah().then(
        function (response) {
          this.itemsbuang_sampah = response.data.data;
        }.bind(this)
      );
    },
    getFasilitasMCK: function () {
      AuthService.getFasilitasMCK().then(
        function (response) {
          this.itemsmck = response.data.data;
        }.bind(this)
      );
    },
    getSumberAirMandi: function () {
      AuthService.getSumberAirMandi().then(
        function (response) {
          this.itemsair_mandi = response.data.data;
        }.bind(this)
      );
    },
    getFasilitasBAB: function () {
      AuthService.getFasilitasBAB().then(
        function (response) {
          this.itemsair_besar = response.data.data;
        }.bind(this)
      );
    },
    getSumberAirMinum: function () {
      AuthService.getSumberAirMinum().then(
        function (response) {
          this.itemsair_minum = response.data.data;
        }.bind(this)
      );
    },
    getLimbahCair: function () {
      AuthService.getLimbahCair().then(
        function (response) {
          this.itemsbuang_limbah = response.data.data;
        }.bind(this)
      );
    },
    getTransportasi: function () {
      AuthService.getTransportasi().then(
        function (response) {
          this.itemstransportasi = response.data.data;
        }.bind(this)
      );
    },

    validate() {
      this.$refs.form.validate();
    },
    async updateBangungan() {
      // console.log(this.isibangunan.kategori);
      try {
        this.loading = true;
        const credentials = {
          id: this.isibangunan.id,
          no_bangunan: this.editBang.no_bangunan,
          jaringan_telepon: this.editBang.jaringan_telepon,
          jaringan_gsm: this.editBang.jaringan_gsm,
          internet_gsm: this.editBang.internet_gsm,
          kategori: this.isibangunan.kategori,
          link_bangunan: this.link_bangunan,
          lat: this.isibangunan.lat,
          long: this.isibangunan.long,
          id_dusun: this.editBang.id_dusun,
          saranavalue: [this.isisaranaumum],
          rtkvalue: [this.isirkt],
          wirausahavalue: [this.isiwirausaha],
          rtivalue: [this.isirti],
        };

        const response = await AuthService.editBangunan(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil update banguanan");
          this.dialogfinal = false;
          this.$router.push("/admin-kelurahan/bangunan/data").catch(() => {});
          this.loading = false;
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
          this.dialogfinal = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.getKategoriBangunan();
    this.detailUsers();
    this.getDusun();

    // sarana umum
    this.getJenusSaranaUmum();
    this.getJenisBangunan();
    this.getStatusBangunan();
    this.getStatusLahan();

    // wirausaha
    this.getStatusOperational();
    this.getSumberModal();
    this.getYatidak();
    this.getStatusJaringan();

    // rumah kosong
    this.getKondisiRumah();
    this.getStatusSPTPBB();

    // rumah tinggal isi
    this.getTempatTinggal();
    this.getStatusLahanB();
    this.getJenisLantai();
    this.getJenisDinding();
    this.getStatusJendela();
    this.getBahanAtap();
    this.getPeneranganRumah();

    this.getEnergiMasak();
    this.getKayuBakar();
    this.getPembuanganSampah();
    this.getFasilitasMCK();
    this.getSumberAirMandi();
    this.getFasilitasBAB();
    this.getSumberAirMinum();
    this.getLimbahCair();
    this.getTransportasi();
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
