<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Akses Jalan</h4></v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>

    <br />
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title
          ><h5>Apakah yakin hapus data akses jalan ?</h5>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-list-item-content>
            <v-list-item-title>
              <h5 class="teal--text">Nama Jalan</h5></v-list-item-title
            >
            <v-list-item-subtitle>
              <h3 class="teal--text">
                {{ editedItem.nama_jalan }}
              </h3></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="deleteItemConfirm()"
            >Hapus Data</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="dialogEdit" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Edit Data Akses Jalan : {{ editedItem.nama_jalan }}</strong
            >
            <v-btn small color="red" class="white--text" @click="closeEdit"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="updateJalan"
            v-model="valid"
            lazy-validation
          >
            <br />

            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.nama_jalan"
                  color="teal"
                  label="Nama Jalan"
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.panjang_jalan"
                  color="teal"
                  label="Panjang Jalan"
                  filled
                  hint=" satuan (Meter)"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  :rules="nomorRules"
                  v-model="editedItem.lebar_jalan"
                  color="teal"
                  label="Lebar Jalan"
                  filled
                  hint=" satuan (Meter)"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.jenis_jalan"
                  :items="itemsjenis"
                  item-text="jenis_jalan"
                  item-value="jenis_jalan"
                  color="teal"
                  label="Pilih Jenis Jalan"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.status_jalan"
                  :items="itemsstatus"
                  item-text="status_jalan"
                  item-value="status_jalan"
                  color="teal"
                  label="Pilih Status Jalan"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.dibuat"
                  :items="years"
                  item-text="year"
                  item-value="year"
                  color="teal"
                  label="Dibuat Tahun"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.perbaikan"
                  :items="years"
                  item-text="year"
                  item-value="year"
                  color="teal"
                  label="Tahun Perbaikan Terakhir"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.link"
                  color="teal"
                  label="Link Embed Video 360 derajat"
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col dense cols="12" md="4">
                <v-text-field
                  color="teal"
                  label="Latitude"
                  filled
                  dense
                  :rules="wajibRules"
                  v-model="editedItem.lat"
                  required
                ></v-text-field>
              </v-col>
              <v-col dense cols="12" md="4">
                <v-text-field
                  color="teal"
                  label="Longitude"
                  filled
                  dense
                  :rules="wajibRules"
                  v-model="editedItem.long"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <MapMitraEdit
                  v-bind:markerslat="editedItem.lat"
                  v-bind:markerslong="editedItem.long"
                  @data-peta="petaValue"
                />
              </v-col>

              <!-- <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.lat"
                  color="teal"
                  label="Latitude Kordinat"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="wajibRules"
                  v-model="editedItem.long"
                  color="teal"
                  label="Longitude Kordinat"
                  filled
                  dense
                ></v-text-field>
              </v-col> -->
            </v-row>
            <br />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                :disabled="!valid"
                @click="validate"
                type="submit"
                color="teal"
                class="mr-0 white--text"
              >
                Update Data
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogDetail" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row class="justify-space-between pt-3 pb-3 pe-3"
            ><strong class="pl-2"
              >Detail Data Akses Jalan : {{ editedItem.nama_jalan }}</strong
            >
            <v-btn
              small
              color="red"
              class="white--text"
              @click="dialogDetail = false"
              >X</v-btn
            ></v-row
          ></v-card-title
        >

        <v-divider></v-divider>

        <v-card-text>
          <br />
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="editedItem.nama_jalan"
                color="teal"
                label="Nama Jalan"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :rules="nomorRules"
                v-model="editedItem.panjang_jalan"
                color="teal"
                label="Panjang Jalan"
                filled
                hint=" satuan (Meter)"
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :rules="nomorRules"
                v-model="editedItem.lebar_jalan"
                color="teal"
                label="Lebar Jalan"
                filled
                readonly
                hint=" satuan (Meter)"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="editedItem.jenis_jalan"
                :items="itemsjenis"
                item-text="jenis_jalan"
                item-value="jenis_jalan"
                color="teal"
                label="Pilih Jenis Jalan"
                filled
                readonly
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="editedItem.status_jalan"
                :items="itemsstatus"
                item-text="status_jalan"
                item-value="status_jalan"
                color="teal"
                label="Pilih Status Jalan"
                filled
                readonly
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="editedItem.dibuat"
                :items="years"
                item-text="year"
                item-value="year"
                color="teal"
                label="Dibuat Tahun"
                filled
                readonly
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :rules="wajibRules"
                v-model="editedItem.perbaikan"
                :items="years"
                item-text="year"
                item-value="year"
                color="teal"
                label="Tahun Perbaikan Terakhir"
                filled
                dense
                readonly
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="editedItem.link"
                color="teal"
                label="Link Embed Video 360 derajat"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="editedItem.lat"
                color="teal"
                label="Latitude Kordinat"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :rules="wajibRules"
                v-model="editedItem.long"
                color="teal"
                label="Longitude Kordinat"
                filled
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              target="_blank"
              :href="`https://maps.google.com/?q=${editedItem.lokasi}`"
            >
              <v-icon dense center> mdi-map </v-icon> &nbsp;Lokasi Akses
              Jalan&nbsp; <v-icon dense center> mdi-arrow-right </v-icon></v-btn
            >
          </v-card-actions>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-card class="rounded-card">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              class="lighten-4 mx-2"
              @click="detailData(item)"
              outlined
              small
              color="cyan"
            >
              <v-icon dense center> mdi-information </v-icon>
            </v-btn>
            <v-btn
              class="lighten-4 mx-2"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>

            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import MapMitraEdit from "@/components/MapMitraEdit.vue";
export default {
  name: "JalanData",
  components: {
    MapMitraEdit,
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1800 },
        (value, index) => 1801 + index
      );
    },
  },
  data() {
    return {
      // Items jalan
      itemsjenis: [{}],
      itemsstatus: [{}],
      // Dialog
      dialogEdit: false,
      dialogDetail: false,

      // Statistik
      hitungtb: "",
      hasilhitung: [],

      // Loading Data Bangunan
      sloading: true,
      firstLoad: true,

      // Data Bangunan
      hasildatas: [],

      dialog: false,

      search: "",

      valid: true,

      loader: null,
      loading: false,
      dialogDelete: false,

      // VALIDASI

      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],

      nomorRules: [
        (v) => !!v || "bidang ini tidak boleh kosong",
        (v) => Number.isInteger(Number(v)) || "bidang ini harus angka",
      ],
      headers: [
        { text: "Nama Jalan", value: "nama_jalan" },
        { text: "Panjang Jalan", value: "panjang_jalan" },
        { text: "Lebar Jalan", value: "lebar_jalan" },

        { text: "Jenis", value: "jenis_jalan" },
        { text: "Status", value: "status_jalan" },
        { text: "Dibuat Tahun", value: "dibuat" },
        { text: "Diperbaiki Tahun", value: "perbaikan" },

        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      individuItem: {
        id: 0,
      },

      editedIndex: -1,
      editedItem: {
        id: 0,
        nama_jalan: "",
        panjang_jalan: "",
        lebar_jalan: "",
        jenis_jalan: "",
        status_jalan: "",
        dibuat: "",
        perbaikan: "",
        link: "",
        lat: "",
        long: "",
      },
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getAksesJalanAll();
    this.getJenisJalan();
    this.getStatusJalan();
    this.detailUsers();
    this.getDusun();
  },
  methods: {
    petaValue(petaValue) {
      this.editedItem.lat = petaValue.latitude;
      this.editedItem.long = petaValue.longitude;
    },
    // Data Bangunan
    getAksesJalanAll: function () {
      AuthService.getAksesJalanAll().then(
        function (response) {
          this.hasildatas = response.data.message;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },
    getJenisJalan: function () {
      AuthService.getJenisJalan().then(
        function (response) {
          this.itemsjenis = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          console.log(response.data.data);
        }.bind(this)
      );
    },
    getStatusJalan: function () {
      AuthService.getStatusJalan().then(
        function (response) {
          this.itemsstatus = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
          console.log(response.data.data);
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
      console.log(this.editedItem);
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);

      this.dialogDelete = true;
    },
    detailData(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogDetail = true;

      console.log(item.nama_jalan);
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteAksesJalan(credentials);
      this.message = response.data;
      if (response.success === false) {
        this.$notification.success(response.data, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getAksesJalanAll();

        this.$router.push("/admin-kelurahan/akses-jalan/data").catch(() => {});

        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.dialogDelete = false;
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    closeEdit() {
      this.dialogEdit = false;

      this.$refs.form.reset();
    },

    validate() {
      this.$refs.form.validate();
    },
    async updateJalan() {
      this.loading = true;

      try {
        const credentials = {
          id: this.editedItem.id,

          nama: this.editedItem.nama_jalan,
          panjangJalan: this.editedItem.panjang_jalan,
          lebarJalan: this.editedItem.lebar_jalan,
          jenis: this.editedItem.jenis_jalan,
          status: this.editedItem.status_jalan,
          thn_dibuat: this.editedItem.dibuat,
          thn_perbaikan: this.editedItem.perbaikan,

          link: this.editedItem.link,
          lat: this.editedItem.lat,
          long: this.editedItem.long,
        };

        const response = await AuthService.editAksesJalan(credentials);

        this.message = response.message;
        if (response.success === true) {
          this.$notification.success(response.message, {
            timer: 10,
          });
          console.log("berhasil update bansos");
          this.getAksesJalanAll();
          this.getJenisJalan();
          this.getStatusJalan();

          this.$router
            .push("/admin-kelurahan/akses-jalan/data")
            .catch(() => {});
          this.loading = false;
          this.closeEdit();
        } else {
          this.$notification.error(response.message, {
            timer: 10,
          });
          this.loading = false;
          this.closeEdit();
        }
      } catch {
        this.loading = false;
        this.closeEdit();
      }
    },
  },

  async mounted() {
    this.detailUsers();
    this.getDusun();
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 7px;
}
</style>
