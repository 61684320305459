<template>
  <v-container class="py-5 px-6" fluid>
    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-row v-else>
      <template>
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-card class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card">
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <h2>{{ this.hasildatas.total_kk }}</h2>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              TOTAL KK <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-card class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card">
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <h2>{{ this.hasildatas.total_penduduk }}</h2>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              TOTAL PENDUDUK <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-card class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card">
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <h2>{{ this.hasildatas.total_bangunan }}</h2>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              TOTAL BANGUNAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-card class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card">
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <h2>{{ this.hasildatas.total_miskin }}</h2>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              TOTAL PENDUDUK MISKIN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-card class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card">
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <h2>{{ this.hasildatas.total_sangat_miskin }}</h2>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              TOTAL PENDUDUK SANGAT MISKIN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-card class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card">
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <h2>{{ this.hasildatas.total_stunting }}</h2>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              TOTAL STUNTING<br />
            </h5>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import AuthService from "@/services/AuthService.js";
import ServicePublik from "@/services/ServicePublik.js";
export default {
  name: "HomeKelurahan",
  data: () => {
    return {
      show1: true,
      show2: false,

      errors: null,
      loader: null,
      loading: false,
      valid: true,

      email: "",
      password: "",

      emailRules: [
        (v) => !!v || "E-mail  tidak boleh kosong",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],

      passwordRules: [(v) => !!v || "Password tidak boleh kosong"],

      // Loading Data
      sloading: true,
      firstLoad: true,

      // Data
      hasildatas: [{}],

      sloadingdua: true,
      firstLoaddua: true,

      // Data
      hasildatasdua: [{}],
      totalbangun: null,
      totalstun: null,
    };
  },
  created() {
    this.dashboard();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    dashboard: function () {
      ServicePublik.dashboard().then(
        function (response) {
          this.hasildatas = response.data.data.dashboard;
          this.firstLoad = false;
          this.sloading = false;
          console.log(this.hasildatas);
        }.bind(this)
      );
    },
  },
};
</script>
