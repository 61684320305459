<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0"
      elevation="0"
    >
      <strong>Data Statistik - Rumah Tinggal Isi</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="grey lighten-7"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-4">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK KONDISI RUMAH</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK STATUS TEMPAT TINGGAL</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatadua"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK STATUS LAHAN</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatatiga"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK JENIS LANTAI</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDataempat"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK JENIS DINDING</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatalima"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK JENIS JENDELA</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDataenam"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK BAHAN ATAP</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatatujuh"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK PENERANGAN RUMAH</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatalapan"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>

    <v-divider></v-divider>
    <v-card v-if="this.sloadingdua === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoaddua"
              :loading="sloadingdua"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-4">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK ENERGI MASAK</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatax"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card v-if="this.sloadingdua === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoaddua"
              :loading="sloadingdua"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK PEMBUANGAN SAMPAH</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDataduax"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK FASILITAS MCK</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatatigax"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <!-- <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK SUMBER AIR MANDI</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDataempatx"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card> -->
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK FASILITAS BUANG AIR BESAR</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatalimax"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>

    <v-divider></v-divider>
    <v-card v-if="this.sloadingtiga === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoadtiga"
              :loading="sloadingtiga"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-4">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK SUMBER AIR MANDI</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDataz"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card v-if="this.sloadingtiga === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 4" :key="index" cols="6" md="3" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoadtiga"
              :loading="sloadingtiga"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <v-card v-else class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK PEMBUANGAN LIMBAH</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDataduaz"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK SUTET</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatatigaz"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK BANTARAN SUNGAI</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDataempatz"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK LERENG BUKIT</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatalimaz"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK JARINGAN TELEPON</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDataenamz"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK JARINGAN GSM</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatatujuhz"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
    <v-divider></v-divider>
    <v-card class="pa-1">
      <v-chip class="ma-2" color="brown" label text-color="white">
        <v-icon left> mdi-label </v-icon>
        <strong> GRAFIK STATUS JARINGAN</strong>
      </v-chip>

      <Pie
        :chart-options="chartOptions"
        :chart-data="chartDatalapanz"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        color="primary"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
      />
    </v-card>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,
      sloadingdua: true,
      firstLoaddua: true,
      sloadingtiga: true,
      firstLoadtiga: true,

      // chart DUA
      chartDatadua: "",
      chartDatatiga: "",
      chartDataempat: "",
      chartDatalima: "",
      chartDataenam: "",
      chartDatatujuh: "",
      chartDatalapan: "",

      chartDataduax: "",
      chartDatatigax: "",
      chartDataempatx: "",
      chartDatalimax: "",

      chartDataduaz: "",
      chartDatatigaz: "",
      chartDataempatz: "",
      chartDatalimaz: "",
      chartDataenamz: "",
      chartDatatujuhz: "",
      chartDatalapanz: "",

      // Chart
      hasildatas: [{}],
      hasildatasdua: [{}],
      hasildatastiga: [{}],
      series: [44, 55, 41, 17, 15],
      chartData: "",
      chartDatax: "",
      chartDataz: "",
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsDataRumahTinggalIsi();
    this.stsDataRumahTinggalIsiI();
    this.stsDataRumahTinggalIsiII();
  },
  methods: {
    stsDataRumahTinggalIsi: function () {
      ServicePublik.stsDataRumahTinggalIsi().then(
        function (response) {
          this.hasildatas = response.data.data.kondisi_rumah;

          this.firstLoad = false;
          this.sloading = false;

          const responseData = response.data.data.kondisi_rumah;
          const responseDatadua = response.data.data.status_tempat;
          const responseDatatiga = response.data.data.status_lahan;
          const responseDataempat = response.data.data.jenis_lantai;
          const responseDatalima = response.data.data.jenis_dinding;
          const responseDataenam = response.data.data.status_jendela;
          const responseDatatujuh = response.data.data.bahan_atap;
          const responseDatalapan = response.data.data.penerangan_rumah;
          //   console.log(this.hasildatas);

          // GRAFIK SATU
          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDatadua = {
            labels: responseDatadua.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatadua.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TIGA
          this.chartDatatiga = {
            labels: responseDatatiga.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatiga.map((item) => item.total),
              },
            ],
          };

          // GRAFIK EMPAT
          this.chartDataempat = {
            labels: responseDataempat.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataempat.map((item) => item.total),
              },
            ],
          };

          // GRAFIK LIMA
          this.chartDatalima = {
            labels: responseDatalima.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalima.map((item) => item.total),
              },
            ],
          };

          // GRAFIK ENAM
          this.chartDataenam = {
            labels: responseDataenam.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataenam.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TUJUH
          this.chartDatatujuh = {
            labels: responseDatatujuh.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatujuh.map((item) => item.total),
              },
            ],
          };

          // GRAFIK LAPAN
          this.chartDatalapan = {
            labels: responseDatalapan.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalapan.map((item) => item.total),
              },
            ],
          };
        }.bind(this)
      );
    },
    stsDataRumahTinggalIsiI: function () {
      ServicePublik.stsDataRumahTinggalIsiI().then(
        function (response) {
          this.hasildatasdua = response.data.data.energi_masak;

          this.firstLoaddua = false;
          this.sloadingdua = false;

          const responseDatax = response.data.data.energi_masak;
          const responseDataduax = response.data.data.pembuangan_sampah;
          const responseDatatigax = response.data.data.fasilitas_mck;
          const responseDataempatx = response.data.data.sumber_air_mandi;
          const responseDatalimax = response.data.data.fasilitas_bab;

          //   console.log(this.hasildatas);

          // GRAFIK SATU
          this.chartDatax = {
            labels: responseDatax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatax.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDataduax = {
            labels: responseDataduax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataduax.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TIGA
          this.chartDatatigax = {
            labels: responseDatatigax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatigax.map((item) => item.total),
              },
            ],
          };

          // GRAFIK EMPAT
          this.chartDataempatx = {
            labels: responseDataempatx.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataempatx.map((item) => item.total),
              },
            ],
          };

          // GRAFIK LIMA
          this.chartDatalimax = {
            labels: responseDatalimax.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalimax.map((item) => item.total),
              },
            ],
          };
        }.bind(this)
      );
    },
    stsDataRumahTinggalIsiII: function () {
      ServicePublik.stsDataRumahTinggalIsiII().then(
        function (response) {
          this.hasildatastiga = response.data.data.sumber_air_mandi;

          this.firstLoadtiga = false;
          this.sloadingtiga = false;

          const responseDataz = response.data.data.sumber_air_mandi;
          const responseDataduaz = response.data.data.pembuangan_limbah;
          const responseDatatigaz = response.data.data.sutet;
          const responseDataempatz = response.data.data.bantaran_sungai;
          const responseDatalimaz = response.data.data.lereng_bukit;
          const responseDataenamz = response.data.data.jaringan_telepon;
          const responseDatatujuhz = response.data.data.jaringan_gsm;
          const responseDatalapanz = response.data.data.status_jaringan;

          //   console.log(this.hasildatas);

          // GRAFIK SATU
          this.chartDataz = {
            labels: responseDataz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataz.map((item) => item.total),
              },
            ],
          };
          // GRAFIK DUA
          this.chartDataduaz = {
            labels: responseDataduaz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataduaz.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TIGA
          this.chartDatatigaz = {
            labels: responseDatatigaz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatigaz.map((item) => item.total),
              },
            ],
          };

          // GRAFIK EMPAT
          this.chartDataempatz = {
            labels: responseDataempatz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataempatz.map((item) => item.total),
              },
            ],
          };

          // GRAFIK LIMA
          this.chartDatalimaz = {
            labels: responseDatalimaz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalimaz.map((item) => item.total),
              },
            ],
          };

          // GRAFIK ENAM
          this.chartDataenamz = {
            labels: responseDataenamz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDataenamz.map((item) => item.total),
              },
            ],
          };

          // GRAFIK TUJUH
          this.chartDatatujuhz = {
            labels: responseDatatujuhz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatatujuhz.map((item) => item.total),
              },
            ],
          };

          // GRAFIK LAPAN
          this.chartDatalapanz = {
            labels: responseDatalapanz.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                ],
                data: responseDatalapanz.map((item) => item.total),
              },
            ],
          };
        }.bind(this)
      );
    },
  },
  mounted() {
    // ronde satu
    this.renderChart(this.chartData);
    this.renderChart(this.chartDatadua);
    this.renderChart(this.chartDatatiga);
    this.renderChart(this.chartDataempat);
    this.renderChart(this.chartDatalima);
    this.renderChart(this.chartDataenam);
    this.renderChart(this.chartDatatujuh);
    this.renderChart(this.chartDatalapan);

    //ronde duo
    this.renderChart(this.chartDatax);
    this.renderChart(this.chartDataduax);
    this.renderChart(this.chartDatatigax);
    this.renderChart(this.chartDataempatx);
    this.renderChart(this.chartDatalimax);

    // ronde tiga
    this.renderChart(this.chartDataz);
    this.renderChart(this.chartDataduaz);
    this.renderChart(this.chartDatatigaz);
    this.renderChart(this.chartDataempatz);
    this.renderChart(this.chartDatalimaz);
    this.renderChart(this.chartDataenamz);
    this.renderChart(this.chartDatatujuhz);
    this.renderChart(this.chartDatalapanz);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
</style>
