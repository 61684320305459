import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

import PublicLayout from "../layouts/LoginLay.vue";
import DepanLayout from "../layouts/DepanLay.vue";
import Login from "../views/Auth/LoginView.vue";

import DashDesaLay from "../layouts/DashDesaLay.vue";
import AppLayout from "../layouts/DashboardLay.vue";
import Notfound from "../views/Error/404.vue";
import Beranda from "../views/HomeView.vue";

import Provinsi from "../views/ProvinsiView.vue";
import Kota from "../views/KotaView.vue";
import Kecamatan from "../views/KecamatanView.vue";
import Kelurahan from "../views/Kelurahan.vue";
import Dusun from "../views/DusunView.vue";
import Pengguna from "../views/PenggunaView.vue";

//Base Rute Provinsi
import HomeProvinsi from "../views/provinsi/HomeProvinsi.vue";

//Base Rute Kabupaten Kota
import HomeKota from "../views/kota/HomeKota.vue";

//Base Rute Kecamatan
import HomeKecamatan from "../views/kecamatan/HomeKecamatan.vue";

//Base Rute Kelurahan
import HomeKelurahan from "../views/kelurahan/HomeKelurahan.vue";

// Rute Penduduk
import PendudukFormKel from "../views/kelurahan/PendudukFormKel.vue";
import PendudukKK from "../views/kelurahan/PendudukKK.vue";
import PendudukWarga from "../views/kelurahan/PendudukWarga.vue";

// Rute Bangunan
import BangunanForm from "../views/kelurahan/BangunanForm.vue";
import BangunanData from "../views/kelurahan/BangunanData.vue";
import SaranaUmumData from "../views/kelurahan/SaranaData.vue";
import WirausahaData from "../views/kelurahan/WirausahaData.vue";
import RumahKosongData from "../views/kelurahan/RumahKosongData.vue";
import RumahIsiData from "../views/kelurahan/RumahIsiData.vue";
import BangunanEdit from "../views/kelurahan/BangunanEdit.vue";
import BangunanDetail from "../views/kelurahan/BangunanDetail.vue";

// Rute Bantuan Sosial
import BansosData from "../views/kelurahan/bansos/DataBansos.vue";
import BansosForm from "../views/kelurahan/bansos/BansosForm.vue";

// Rute Akses Jalan
import JalanData from "../views/kelurahan/jalan/JalanData.vue";
import JalanForm from "../views/kelurahan/jalan/JalanForm.vue";

// Rute Data Lahan
import LahanData from "../views/kelurahan/lahan/LahanData.vue";
import LahanForm from "../views/kelurahan/lahan/LahanForm.vue";

// Rute Data DTKS
import DtksData from "../views/kelurahan/dtks/DtksData.vue";
import DtksForm from "../views/kelurahan/dtks/DtksForm.vue";

// Rute Data Sagasaja
import SagaData from "../views/kelurahan/sagasaja/SagaData.vue";
import SagaForm from "../views/kelurahan/sagasaja/SagasajaForm.vue";

// Rute Data Spt-PBB
import SpptData from "../views/kelurahan/pbb/SpptData.vue";
import SpptForm from "../views/kelurahan/pbb/SpptForm.vue";

// Rute Data Perikanan
import IkanBudidaya from "../views/kelurahan/perikanan/IkanBudidaya.vue";
import IkanTangkap from "../views/kelurahan/perikanan/IkanTangkap.vue";

// Rute Data Dasawisma
import DataPkk from "../views/kelurahan/dasawisma/DataPkk.vue";
import KelahiranBayi from "../views/kelurahan/dasawisma/KelahiranBayi.vue";
import KematianBayi from "../views/kelurahan/dasawisma/KematianBayi.vue";

// Rute Peternakan
import TernakUnggas from "../views/kelurahan/peternakan/TernakUnggas.vue";
import DataPopulasi from "../views/kelurahan/peternakan/DataPopulasi.vue";
import DataMitra from "../views/kelurahan/peternakan/DataMitra.vue";
import UsulanBantuan from "../views/kelurahan/peternakan/UsulanBantuan.vue";
import TernakSakit from "../views/kelurahan/peternakan/TernakSakit.vue";
import DataWalet from "../views/kelurahan/peternakan/DataWalet.vue";
import HewanPeliharaan from "../views/kelurahan/peternakan/HewanPeliharaan.vue";
import KelompokTernak from "../views/kelurahan/peternakan/KelompokTernak.vue";
import DataUph from "../views/kelurahan/peternakan/DataUph.vue";

// Rute Pertanian
import PotensiTani from "../views/kelurahan/pertanian/PotensiTani.vue";
import KelompokTani from "../views/kelurahan/pertanian/KelompokTani.vue";
import LahanSawah from "../views/kelurahan/pertanian/LahanSawah.vue";
import BantuanTani from "../views/kelurahan/pertanian/BantuanTani.vue";
import SakitTanam from "../views/kelurahan/pertanian/SakitTanam.vue";
import DataAlsintan from "../views/kelurahan/pertanian/DataAlsintan.vue";
import PascaPanen from "../views/kelurahan/pertanian/PascaPanen.vue";

// Rute Posyandu
import DataBalita from "../views/kelurahan/posyandu/DataBalita.vue";
import IbuHamil from "../views/kelurahan/posyandu/IbuHamil.vue";
import DataKb from "../views/kelurahan/posyandu/DataKb.vue";

// HOME PUBLIC RUMAH DATA
import HomePublic from "../views/public/HomePublik.vue";
import RumahData from "../views/public/RumahData.vue";
import PublikLahan from "../views/public/PublikLahan.vue";
import PublikJalan from "../views/public/PublikJalan.vue";
import PublikBangunan from "../views/public/PublikBangunan.vue";
import PublikRumahIsi from "../views/public/PublikRumahIsi.vue";
import PublikRumahKosong from "../views/public/PublikRumahKosong.vue";
import PublikWirausaha from "../views/public/PublikWirausaha.vue";
import PublikSaranaUmum from "../views/public/PublikSaranaUmum.vue";
import PublikSPPT from "../views/public/PublikSPPT.vue";
import PublikPenduduk from "../views/public/PublikPenduduk.vue";
import PublikUmur from "../views/public/PublikUmur.vue";
import PublikKesehatan from "../views/public/PublikKesehatan.vue";
import PublikPendidikan from "../views/public/PublikPendidikan.vue";
import PublikSagaSaja from "../views/public/PublikSagaSaja.vue";
import PublikBansos from "../views/public/PublikBansos.vue";
import PublikDasawisma from "../views/public/PublikDasawisma.vue";
import PublikPosyandu from "../views/public/PublikPosyandu.vue";
import PublikPertanian from "../views/public/PublikPertanian.vue";
import PublikTernak from "../views/public/PublikTernak.vue";
import PublikPerikanan from "../views/public/PublikPerikanan.vue";

// HOME PUBLIK STATISTIK DATA
import StatistikData from "../views/public/statistik/StatistikData.vue";
import StsLahan from "../views/public/statistik/StsLahan.vue";
import StsJalan from "../views/public/statistik/StsJalan.vue";
import StsBangunan from "../views/public/statistik/StsBangunan.vue";
import StsRumahIsi from "../views/public/statistik/StsRumahIsi.vue";
import StsRumahKosong from "../views/public/statistik/StsRumahKosong.vue";
import StsWirausaha from "../views/public/statistik/StsWirausaha.vue";
import StsSarana from "../views/public/statistik/StsSarana.vue";
import StsPBB from "../views/public/statistik/StsPBB.vue";
import StsPenduduk from "../views/public/statistik/StsPenduduk.vue";
import StsUmur from "../views/public/statistik/StsUmur.vue";
import StsKesehatan from "../views/public/statistik/StsKesehatan.vue";
import StsPendidikan from "../views/public/statistik/StsPendidikan.vue";
import StsSaga from "../views/public/statistik/StsSaga.vue";
import StsBansos from "../views/public/statistik/StsBansos.vue";
import StsDasa from "../views/public/statistik/StsDasa.vue";
import StsPosyandu from "../views/public/statistik/StsPosyandu.vue";
import StsPertanian from "../views/public/statistik/StsPertanian.vue";
import StsTernak from "../views/public/statistik/StsTernak.vue";
import StsIkan from "../views/public/statistik/StsIkan.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: DepanLayout,
    children: [
      {
        path: "/",
        name: "Beranda Desa",
        component: HomePublic,
      },
      {
        path: "/rumah-data",
        name: "Rumah Data",
        component: RumahData,
      },
      {
        path: "/statistik-data",
        name: "Data Statistik",
        component: StatistikData,
      },
      {
        path: "/rumah-data/lahan",
        name: "Rumah Data | Data Lahan",
        component: PublikLahan,
      },
      {
        path: "/rumah-data/akses-jalan",
        name: "Rumah Data | Data Akses Jalan",
        component: PublikJalan,
      },
      {
        path: "/rumah-data/bangunan",
        name: "Rumah Data | Data Bangunan",
        component: PublikBangunan,
      },
      {
        path: "/rumah-data/bangunan/rumah-isi",
        name: "Rumah Data | Data Bangunan | Rumah Isi",
        component: PublikRumahIsi,
      },
      {
        path: "/rumah-data/bangunan/rumah-kosong",
        name: "Rumah Data | Data Bangunan | Rumah Kosong",
        component: PublikRumahKosong,
      },
      {
        path: "/rumah-data/bangunan/wirausaha",
        name: "Rumah Data | Data Bangunan | Wirausaha",
        component: PublikWirausaha,
      },
      {
        path: "/rumah-data/bangunan/sarana-umum",
        name: "Rumah Data | Data Bangunan | Sarana Umum",
        component: PublikSaranaUmum,
      },
      {
        path: "/rumah-data/sppt-pbb",
        name: "Rumah Data | Data SPPT PBB",
        component: PublikSPPT,
      },
      {
        path: "/rumah-data/penduduk",
        name: "Rumah Data | Data Penduduk",
        component: PublikPenduduk,
      },
      {
        path: "/rumah-data/umur",
        name: "Rumah Data | Data Umur",
        component: PublikUmur,
      },
      {
        path: "/rumah-data/kesehatan",
        name: "Rumah Data | Data Kesehatan",
        component: PublikKesehatan,
      },
      {
        path: "/rumah-data/pendidikan",
        name: "Rumah Data | Data Pendidikan",
        component: PublikPendidikan,
      },
      {
        path: "/rumah-data/sagasaja",
        name: "Rumah Data | Data Sagasaja",
        component: PublikSagaSaja,
      },
      {
        path: "/rumah-data/bansos",
        name: "Rumah Data | Data Bantuan Sosial",
        component: PublikBansos,
      },
      {
        path: "/rumah-data/dasawisma",
        name: "Rumah Data | Data Dasawisma PKK",
        component: PublikDasawisma,
      },
      {
        path: "/rumah-data/posyandu",
        name: "Rumah Data | Data Posyandu",
        component: PublikPosyandu,
      },
      {
        path: "/rumah-data/pertanian",
        name: "Rumah Data | Data Pertanian",
        component: PublikPertanian,
      },
      {
        path: "/rumah-data/peternakan",
        name: "Rumah Data | Data Peternakan",
        component: PublikTernak,
      },
      {
        path: "/rumah-data/perikanan",
        name: "Rumah Data | Data Perikanan",
        component: PublikPerikanan,
      },

      // Statistik Data
      {
        path: "/statistik-data/lahan",
        name: "Statistik | Data Lahan",
        component: StsLahan,
      },
      {
        path: "/statistik-data/akses-jalan",
        name: "Statistik | Data Akses Jalan",
        component: StsJalan,
      },
      {
        path: "/statistik-data/bangunan",
        name: "Statistik | Data Bangunan",
        component: StsBangunan,
      },
      {
        path: "/statistik-data/bangunan/rumah-isi",
        name: "Statistik | Data Rumah Tinggal Isi",
        component: StsRumahIsi,
      },
      {
        path: "/statistik-data/bangunan/rumah-kosong",
        name: "Statistik | Data Rumah Tinggal Kosong",
        component: StsRumahKosong,
      },
      {
        path: "/statistik-data/bangunan/wirausaha",
        name: "Statistik | Data Wirausaha",
        component: StsWirausaha,
      },
      {
        path: "/statistik-data/bangunan/sarana-umum",
        name: "Statistik | Data Sarana Umum",
        component: StsSarana,
      },
      {
        path: "/statistik-data/sppt-pbb",
        name: "Statistik | Data SPPT PBB",
        component: StsPBB,
      },
      {
        path: "/statistik-data/penduduk",
        name: "Statistik | Data Penduduk",
        component: StsPenduduk,
      },
      {
        path: "/statistik-data/umur",
        name: "Statistik | Data Umur",
        component: StsUmur,
      },
      {
        path: "/statistik-data/kesehatan",
        name: "Statistik | Data Kesehatan",
        component: StsKesehatan,
      },
      {
        path: "/statistik-data/pendidikan",
        name: "Statistik | Data Pendidikan",
        component: StsPendidikan,
      },
      {
        path: "/statistik-data/sagasaja",
        name: "Statistik | Data Sagasaja",
        component: StsSaga,
      },
      {
        path: "/statistik-data/bansos",
        name: "Statistik | Data Bantuan Sosial",
        component: StsBansos,
      },
      {
        path: "/statistik-data/dasawisma",
        name: "Statistik | Data Dsawisma",
        component: StsDasa,
      },
      {
        path: "/statistik-data/posyandu",
        name: "Statistik | Data Posyandu",
        component: StsPosyandu,
      },
      {
        path: "/statistik-data/pertanian",
        name: "Statistik | Data Pertanian",
        component: StsPertanian,
      },
      {
        path: "/statistik-data/peternakan",
        name: "Statistik | Data Peternakan",
        component: StsTernak,
      },
      {
        path: "/statistik-data/perikanan",
        name: "Statistik | Data Perikanan",
        component: StsIkan,
      },
    ],
  },

  // Rute Auth
  {
    path: "/login",
    name: "Home",
    component: PublicLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
    ],
  },

  // RUTE KELURAHAN
  {
    path: "/admin-kelurahan",
    name: "Admin Kelurahan / Desa",
    component: DashDesaLay,
    children: [
      {
        name: "Beranda",
        path: "beranda",
        component: HomeKelurahan,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Form Penduduk",
        path: "penduduk/create",
        component: PendudukFormKel,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kartu Keluarga",
        path: "penduduk/kk",
        component: PendudukKK,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Penduduk",
        path: "pendudukwarga",
        component: PendudukWarga,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // BANGUNAN
      {
        name: "Form Bangunan",
        path: "bangunan/create",
        component: BangunanForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Bangunan",
        path: "bangunan/data",
        component: BangunanData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Sarana Umum",
        path: "bangunan/data/sarana-umum",
        component: SaranaUmumData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Wirausaha",
        path: "bangunan/data/wirausaha",
        component: WirausahaData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Rumah Tinggal Kosong",
        path: "bangunan/data/rumah-tinggal-kosong",
        component: RumahKosongData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Rumah Tinggal Isi",
        path: "bangunan/data/rumah-tinggal-isi",
        component: RumahIsiData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Bangunan",
        path: "bangunan/edit/:id",
        component: BangunanEdit,
        props: true,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Detail Data Bangunan",
        path: "bangunan/detail/:id",
        component: BangunanDetail,
        props: true,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // BANTUAN SOSIAL
      {
        name: "Form Bantuan Sosial",
        path: "bansos/create",
        component: BansosForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Bantuan Sosial",
        path: "bansos/data",
        component: BansosData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      // AKSES JALAN
      {
        name: "Form Akses Jalan",
        path: "akses-jalan/create",
        component: JalanForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Akses Jalan",
        path: "akses-jalan/data",
        component: JalanData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data LAHAN
      {
        name: "Form Data Lahan",
        path: "lahan/create",
        component: LahanForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Lahan",
        path: "lahan/data",
        component: LahanData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data DTKS
      {
        name: "Form Data DTKS",
        path: "dtks/create",
        component: DtksForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data DTKS",
        path: "dtks/data",
        component: DtksData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data SAGASAJA
      {
        name: "Form Data Sagasaja",
        path: "sagasaja/create",
        component: SagaForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Sagasaja",
        path: "sagasaja/data",
        component: SagaData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data SPPT-PBB
      {
        name: "Form Data SPPT-PBB",
        path: "sppt-pbb/create",
        component: SpptForm,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data SPPT-PBB",
        path: "sppt-pbb/data",
        component: SpptData,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data Perikanan
      {
        name: "Data Ikan Budidaya",
        path: "perikanan/ikan-budidaya",
        component: IkanBudidaya,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Ikan Tangkap",
        path: "perikanan/ikan-tangkap",
        component: IkanTangkap,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data Dasawisma
      {
        name: "Data PKK",
        path: "dasawisma/pkk",
        component: DataPkk,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kelahiran Bayi",
        path: "dasawisma/kelahiran-bayi",
        component: KelahiranBayi,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kematian Bayi",
        path: "dasawisma/kematian-bayi",
        component: KematianBayi,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Data Peternakan
      {
        name: "Data Ternak Unggas",
        path: "peternakan/ternak-unggas",
        component: TernakUnggas,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Populasi",
        path: "peternakan/populasi",
        component: DataPopulasi,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kemitraan",
        path: "peternakan/kemitraan",
        component: DataMitra,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Usulan Bantuan",
        path: "peternakan/usulan-bantuan",
        component: UsulanBantuan,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Penyakit Ternak",
        path: "peternakan/penyakit-ternak",
        component: TernakSakit,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Walet",
        path: "peternakan/walet",
        component: DataWalet,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Hewan Peliharaan",
        path: "peternakan/hewan-peliharaan",
        component: HewanPeliharaan,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kelompok  Ternak",
        path: "peternakan/kelompok-ternak",
        component: KelompokTernak,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data UPH ",
        path: "peternakan/uph",
        component: DataUph,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      // Rute Pertanian

      {
        name: "Data Potensi Petani ",
        path: "pertanian/potensi-petani",
        component: PotensiTani,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kelompok Tani ",
        path: "pertanian/kelompok-tani",
        component: KelompokTani,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Kepemilikan Lahan Sawah ",
        path: "pertanian/lahan-sawah",
        component: LahanSawah,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Bantuan Pertanian ",
        path: "pertanian/bantuan-pertanian",
        component: BantuanTani,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Penyakit Tanaman ",
        path: "pertanian/penyakit-tanaman",
        component: SakitTanam,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Alsintan ",
        path: "pertanian/alsintan",
        component: DataAlsintan,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Pasca Panen ",
        path: "pertanian/pasca-panen",
        component: PascaPanen,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // Rute Posyandu
      {
        name: "Data Balita ",
        path: "posyandu/balita",
        component: DataBalita,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Ibu Hamil ",
        path: "posyandu/ibu-hamil",
        component: IbuHamil,
        meta: {
          kelurahan: true,
          login: true,
        },
      },
      {
        name: "Data Keluarga Berencana ",
        path: "posyandu/kb",
        component: DataKb,
        meta: {
          kelurahan: true,
          login: true,
        },
      },

      // 404 NOT FOUND
      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },

  // RUTE SUPERADMIN
  {
    path: "/dashboard",
    name: "Dashboard",
    component: AppLayout,

    children: [
      {
        name: "Beranda ",
        path: "beranda",
        component: Beranda,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Provinsi",
        path: "Provinsi",
        component: Provinsi,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Kota",
        path: "Kota",
        component: Kota,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Kecamatan",
        path: "Kecamatan",
        component: Kecamatan,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Kelurahan",
        path: "Kelurahan",
        component: Kelurahan,
        meta: {
          superadmin: true,
          login: true,
        },
      },
      {
        name: "Dusun",
        path: "Dusun",
        component: Dusun,
        meta: {
          superadmin: true,
          login: true,
        },
      },

      {
        name: "Pengguna ",
        path: "pengguna",
        component: Pengguna,
        meta: {
          superadmin: true,
          provinsi: true,
          login: true,
        },
      },

      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },

  // RUTE PROVINSI
  {
    path: "/admin-provinsi",
    name: "Admin Provinsi",
    component: AppLayout,
    children: [
      {
        name: "Beranda",
        path: "beranda",
        component: HomeProvinsi,
        meta: {
          provinsi: true,
          login: true,
        },
      },
      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },

  // RUTE KOTA
  {
    path: "/admin-kota",
    name: "Admin Kota",
    component: AppLayout,
    children: [
      {
        name: "Beranda",
        path: "beranda",
        component: HomeKota,
        meta: {
          kota: true,
          login: true,
        },
      },
      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },
  // RUTE KECAMATAN
  {
    path: "/admin-kecamatan",
    name: "Admin Kecamatan",
    component: AppLayout,
    children: [
      {
        name: "Beranda",
        path: "beranda",
        component: HomeKecamatan,
        meta: {
          kecamatan: true,
          login: true,
        },
      },
      {
        name: "erorr4040",
        path: "*",
        redirect: "/404",
      },
    ],
  },

  {
    path: "/404",
    component: Notfound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  // next();
  const status = JSON.parse(window.localStorage.getItem("lbUser"));
  if (to.meta.login) {
    if (to.meta.superadmin && status === "superadmin") {
      next();
    } else if (to.meta.provinsi && status === "provinsi") {
      next();
    } else if (to.meta.kota && status === "kota") {
      next();
    } else if (to.meta.kecamatan && status === "kecamatan") {
      next();
    } else if (to.meta.kelurahan && status === "kelurahan") {
      next();
    } else {
      next({ name: "erorr4040" });
    }
  } else {
    next();
  }
  // console.log(status);
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
