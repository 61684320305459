import { render, staticRenderFns } from "./StsRumahKosong.vue?vue&type=template&id=358b2e3b&scoped=true&"
import script from "./StsRumahKosong.vue?vue&type=script&lang=js&"
export * from "./StsRumahKosong.vue?vue&type=script&lang=js&"
import style0 from "./StsRumahKosong.vue?vue&type=style&index=0&id=358b2e3b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "358b2e3b",
  null
  
)

export default component.exports