import { render, staticRenderFns } from "./StsPosyandu.vue?vue&type=template&id=157747ad&scoped=true&"
import script from "./StsPosyandu.vue?vue&type=script&lang=js&"
export * from "./StsPosyandu.vue?vue&type=script&lang=js&"
import style0 from "./StsPosyandu.vue?vue&type=style&index=0&id=157747ad&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "157747ad",
  null
  
)

export default component.exports