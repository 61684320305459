<template>
  <v-container centered class="pl-8 pr-8 pt-12">
    <v-card
      class="cardbor pa-4 d-flex justify-space-between mb-0"
      elevation="0"
    >
      <strong>Data Statistik - Pertanian</strong>
      <v-btn
        to="/statistik-data"
        depressed
        small
        class="white--text"
        color="grey lighten-7"
      >
        <v-icon left small color="white"> mdi-arrow-left-thick </v-icon> Back
      </v-btn>
    </v-card>

    <br />

    <v-card v-if="this.sloading === true" class="pa-4">
      <v-progress-linear indeterminate color="orange"></v-progress-linear>
      <v-row>
        <template>
          <v-col v-for="index in 12" :key="index" cols="6" md="6" sm="6" xs="6">
            <v-skeleton-loader
              v-if="firstLoad"
              :loading="sloading"
              type="article"
            ></v-skeleton-loader>
          </v-col>
        </template>
      </v-row>
    </v-card>
    <template v-else>
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Usaha Pertanian</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>
      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Luas Serangan</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDataa"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Kebutuhan Alsintan</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatab"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Produksi Pasca Panen</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatac"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>

      <br />
      <v-card class="pa-4">
        <v-chip class="ma-2" color="brown" label text-color="white">
          <v-icon left> mdi-label </v-icon>
          <strong> Grafik Pemilik Sawah</strong>
        </v-chip>
        <Pie
          :chart-options="chartOptions"
          :chart-data="chartDatad"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          color="primary"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
        />
      </v-card>
    </template>
  </v-container>
</template>

<script>
import ServicePublik from "@/services/ServicePublik.js";
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

export default {
  name: "PublikLahan",
  components: { Pie },
  computed: {},
  data() {
    return {
      // Loading Data
      sloading: true,
      firstLoad: true,

      // Data
      hasildatas: [{}],

      // Chart

      series: [44, 55, 41, 17, 15],

      chartData: "",
      chartDataa: "",
      chartDatab: "",
      chartDatac: "",
      chartDatad: "",
      chartOptions: {
        responsive: true,

        maintainAspectRatio: false,
        cutoutPercentage: 80,

        plugins: {
          legend: {
            position: "bottom",
            display: true,
            labels: {},
          },

          tooltip: {
            enabled: true,
            position: "nearest",
          },
        },
      },
    };
  },

  watch: {},

  created() {
    this.stsPertanian();
  },
  methods: {
    stsPertanian: function () {
      ServicePublik.stsPertanian().then(
        function (response) {
          this.hasildatas = response.data.data.usaha_tani;

          this.firstLoad = false;
          this.sloading = false;

          const responseData = response.data.data.usaha_tani;
          const responseDataa = response.data.data.luas_srangan;
          const responseDatab = response.data.data.kebutuhan_alsintan;
          const responseDatac = response.data.data.produksi_pasca;
          const responseDatad = response.data.data.pemilik_sawah;

          this.chartData = {
            labels: responseData.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseData.map((item) => item.total),
              },
            ],
          };

          this.chartDataa = {
            labels: responseDataa.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDataa.map((item) => item.total),
              },
            ],
          };

          this.chartDatab = {
            labels: responseDatab.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatab.map((item) => item.total),
              },
            ],
          };

          this.chartDatac = {
            labels: responseDatac.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatac.map((item) => item.total),
              },
            ],
          };

          this.chartDatad = {
            labels: responseDatad.map(
              (item) => item.nama + " : " + item.total + " " + "%"
            ),
            datasets: [
              {
                borderColor: "rgba(200, 200, 200, 0.75)",
                label: "Total Data",

                backgroundColor: [
                  "#41B883",
                  "#b52d07",
                  "#b57007",
                  "#127347",
                  "#f589a8",
                  "#174ceb",
                  "#91a8ed",
                  "#c591ed",
                  "#eb0cdf",
                  "#6b284c",
                  "#46408c",
                  "#46408c",
                  "#ff3598",
                  "#1a89dd",
                  "#721b23",
                  "#5f239c",
                  "#0b5bb3",
                  "#875a65",
                  "#875a65",
                  "#4656d4",
                  "#85ecbf",
                ],
                data: responseDatad.map((item) => item.total),
              },
            ],
          };
        }.bind(this)
      );
    },
  },
  mounted() {
    this.renderChart(this.chartData);
    this.renderChart(this.chartDataa);
    this.renderChart(this.chartDatab);
    this.renderChart(this.chartDatac);
    this.renderChart(this.chartDatad);
  },
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
.theme--light .cardbor {
  border-left: 5px solid teal !important;
}
.theme--light .cardtop {
  border-bottom: 3px solid rgb(70, 100, 96) !important;
}
</style>
