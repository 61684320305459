<template>
  <v-container class="py-6 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Form Input Data Penduduk</h4></v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />
    <v-skeleton-loader
      v-if="sloading"
      :loading="firstLoad"
      type="article, actions"
    ></v-skeleton-loader>
    <v-stepper v-else elevation="1" v-model="e1">
      <v-stepper-header elevation="0">
        <v-stepper-step color="teal" :complete="e1 > 1" step="1">
          Data Kartu Keluarga
        </v-stepper-step>

        <v-divider></v-divider>

        <v-divider></v-divider>

        <v-stepper-step color="teal" step="2">Data Individu</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-form @submit.prevent="handleSubmit" lazy-validation ref="form">
          <v-stepper-content step="1">
            <v-card elevation="0" class="mb-2" color="white">
              <v-row dense>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    color="teal"
                    label="Nomor KK"
                    filled
                    dense
                    maxlength="16"
                    :rules="nokkRules"
                    v-model="nokk"
                    :counter="16"
                    required
                  ></v-text-field>

                  <v-row dense>
                    <v-col cols="6" md="6">
                      <v-select
                        :rules="statuskkRules"
                        v-model="status_kk"
                        :items="itemstatuskk"
                        item-text="status_kk"
                        item-value="status_kk"
                        color="teal"
                        label="Status Kartu Keluarga (KK)"
                        filled
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-select
                        :rules="miskinRules"
                        v-model="status_miskin"
                        :items="itemsmiskin"
                        color="teal"
                        item-text="status_miskin"
                        item-value="status_miskin"
                        label="Status Kemiskinan"
                        filled
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-select
                        :rules="jeniskkRules"
                        :items="itemsjenis"
                        v-model="jenis_kk"
                        filled
                        item-text="jenis_kk"
                        item-value="id"
                        return-object
                        label="Jenis Kartu Keluarga (KK)"
                        dense
                        color="teal"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <div v-show="this.jenis_kk.id === 1">
                    <v-row dense>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          :value="this.kota"
                          label="Kabupaten/Kota"
                          filled
                          readonly
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          :value="this.kecamatan"
                          label="Kecamatan"
                          filled
                          readonly
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          :value="this.kelurahan"
                          label="Kelurahan/Desa"
                          filled
                          dense
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-select
                          :rules="dusunRules"
                          v-model="id_dusun"
                          :items="itemsdusun"
                          item-text="dusun"
                          item-value="id"
                          return-object
                          color="teal"
                          label="Pilih Dusun"
                          filled
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-show="this.jenis_kk.id === 2">
                    <v-row dense>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          :value="this.kota"
                          label="Kabupaten/Kota"
                          filled
                          readonly
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          :value="this.kecamatan"
                          label="Kecamatan"
                          filled
                          readonly
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_kelurahan"
                          label="Kelurahan/Desa"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_dusun"
                          label="Nama Dusun"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-show="this.jenis_kk.id === 3">
                    <v-row dense>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          :value="this.kota"
                          label="Kabupaten/Kota"
                          filled
                          readonly
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_kecamatan"
                          label="Kecamatan"
                          filled
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_kelurahan"
                          label="Kelurahan/Desa"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_dusun"
                          label="Nama Dusun"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-show="this.jenis_kk.id === 4">
                    <v-row dense>
                      <v-col cols="12" md="12">
                        <v-text-field
                          color="teal"
                          v-model="n_provinsi"
                          label="Provinsi"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_kota"
                          label="Kabupaten/Kota"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_kecamatan"
                          label="Kecamatan"
                          filled
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_kelurahan"
                          label="Kelurahan/Desa"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          color="teal"
                          v-model="n_dusun"
                          label="Nama Dusun"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                    :rules="imageRules"
                    v-model="imageData"
                    accept=".png, .jpeg, .jpg"
                    hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                    prepend-icon=""
                    prepend-inner-icon="mdi-camera"
                    label="Upload Foto KK"
                    filled
                    dense
                    @click:clear="url = ''"
                    @change="Preview_image"
                  />
                  <v-card
                    align="center"
                    class="col-12"
                    height="300"
                    elevation="0"
                    color="grey lighten-4"
                    tile
                  >
                    <v-img max-height="300" class="img" :src="url"></v-img>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br />
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  v-if="
                    nokk !== '' &&
                    url !== '' &&
                    status_kk !== '' &&
                    jenis_kk !== '' &&
                    id_dusun !== ''
                  "
                  :disabled="!valid"
                  class="ma-2 white--text"
                  @click="e1 = 2"
                  color="teal"
                  >Selanjutnya<v-icon color="white" right
                    >mdi-arrow-right</v-icon
                  >
                </v-btn>
                <v-btn v-else disabled class="ma-2 white--text" color="teal"
                  >Selanjutnya<v-icon color="white" right
                    >mdi-arrow-right</v-icon
                  >
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <!-- IKO CEK PACAH KK -->
            <div v-if="status_kk !== 'Pemecahan'">
              <div v-for="row in textRows" :key="row.index">
                <v-sheet outlined color="grey" rounded>
                  <v-card elevation="0">
                    <v-container>
                      <div class="d-flex justify-space-between mb-4">
                        <h4>1. Deskripsi Individu</h4>
                        <v-btn small @click="deleteTextRow(row)" class="pink">
                          <v-icon small color="white">mdi-delete</v-icon></v-btn
                        >
                      </div>

                      <v-row dense>
                        <v-col cols="4"
                          ><v-text-field
                            color="teal"
                            label="Nomor NIK / KIA"
                            filled
                            dense
                            maxlength="16"
                            :rules="nikRules"
                            v-model="row.nik"
                            :counter="16"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            color="teal"
                            label="Nama Lengkap"
                            :rules="wajibRules"
                            v-model="row.nama"
                            filled
                            dense
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.jk"
                            :items="itemsjk"
                            item-text="jenis_kelamin"
                            item-value="jenis_kelamin"
                            color="teal"
                            label="Jenis Kelamin"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <v-col cols="4">
                          <v-text-field
                            color="teal"
                            label="Tempat Lahir"
                            :rules="wajibRules"
                            v-model="row.tmpt_lahir"
                            filled
                            dense
                          ></v-text-field
                        ></v-col>
                        <v-col cols="4">
                          <v-menu
                            ref="menu"
                            v-model="row.menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="row.tgl_lahir"
                                :rules="wajibRules"
                                label="Tanggal Lahir"
                                readonly
                                filled
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>

                            <v-date-picker
                              v-model="row.tgl_lahir"
                              :active-picker.sync="row.activePicker"
                              :max="
                                new Date(
                                  Date.now() -
                                    new Date().getTimezoneOffset() * 60000
                                )
                                  .toISOString()
                                  .substr(0, 10)
                              "
                              min="1900-01-01"
                              @change="save"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.status_tinggal"
                            :items="itemsstatus_tinggal"
                            item-text="status_tinggal"
                            item-value="status_tinggal"
                            color="teal"
                            label="Status Tinggal"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.agama"
                            :items="itemsagama"
                            item-text="agama"
                            item-value="agama"
                            color="teal"
                            label="Agama"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.status_kawin"
                            :items="itemskawin"
                            item-text="status_kawin"
                            item-value="status_kawin"
                            color="teal"
                            label="Status Perkawinan"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <template v-if="row.status_kawin === 'Kawin'">
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.tahun_kawin"
                              :items="years"
                              item-text="year"
                              item-value="year"
                              color="teal"
                              label="Tahun Pernikahan"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                        </template>

                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.kewarganegaraan"
                            :items="itemskewarganegaraan"
                            item-text="status_warganegara"
                            item-value="status_warganegara"
                            color="teal"
                            label="Kewarganegaraan"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.hak_politik"
                            :items="itemsyatidak"
                            item-text="optional"
                            item-value="optional"
                            color="teal"
                            label="Hak Pilih Politik"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <v-col cols="4">
                          <v-select
                            v-on:change="cekKeluarga(`${row.status_keluarga}`)"
                            :rules="wajibRules"
                            v-model="row.status_keluarga"
                            :items="recipes"
                            item-text="name"
                            item-value="id"
                            color="teal"
                            label="Status Hubungan Keluarga"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                      </v-row>
                      <h4>2. Deskripsi Pekerjaan dan Pendidikan</h4>
                      <br />
                      <v-row dense>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.status_pekerjaan"
                            :items="itemskerja"
                            item-text="kondisi_pekerjaan"
                            item-value="kondisi_pekerjaan"
                            color="teal"
                            label="Kondisi Pekerjaan"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <template v-if="row.status_pekerjaan === 'Bekerja'">
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.pekerjaan"
                              :items="itemspekerjaan"
                              item-text="pekerjaan"
                              item-value="pekerjaan"
                              color="teal"
                              label="Pilih Pekerjaan"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                        </template>

                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.pendidikan"
                            :items="itemspendidikan"
                            item-text="pendidikan"
                            item-value="pendidikan"
                            color="teal"
                            label="Pendidikan tertinggi yang ditamatkan"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                      </v-row>

                      <h4>3. Deskripsi Kesehatan</h4>
                      <br />
                      <v-row dense>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.status_jkn"
                            :items="itemsyatidak"
                            item-text="optional"
                            item-value="optional"
                            color="teal"
                            label="Peserta Jaminan Kesehatan Nasional"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <template v-if="row.status_jkn === 'Ya'">
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.id_jkn"
                              :items="itemsid_jkn"
                              item-text="JKN"
                              item-value="JKN"
                              color="teal"
                              label="Pilih Jaminan Kesehatan Nasional"
                              filled
                              multiple
                              dense
                            ></v-select
                          ></v-col>
                        </template>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.penyakit"
                            :items="itemspenyakit"
                            item-text="penyakitcek"
                            item-value="penyakitcek"
                            color="teal"
                            label="Penyakit yang diderita 1 tahun terakhir"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <template v-if="row.penyakit === 'Ada'">
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.id_penyakit"
                              :items="itemsid_penyakit"
                              item-text="penyakit"
                              item-value="penyakit"
                              color="teal"
                              label="Pilih Penyakit yang diderita"
                              filled
                              multiple
                              dense
                            ></v-select
                          ></v-col>
                        </template>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.disabilitas"
                            :items="itemsyatidak"
                            item-text="optional"
                            item-value="optional"
                            color="teal"
                            label="Disabilitas"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                        <template v-if="row.disabilitas === 'Ya'">
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.id_disabilitas"
                              :items="itemsdisabilitas"
                              item-text="disabilitas"
                              item-value="disabilitas"
                              color="teal"
                              label="Pilih Disabilitas"
                              filled
                              multiple
                              dense
                            ></v-select
                          ></v-col>
                        </template>
                        <v-col cols="4">
                          <v-select
                            :rules="wajibRules"
                            v-model="row.stanting"
                            :items="itemsyatidak"
                            item-text="optional"
                            item-value="optional"
                            color="teal"
                            label="Stunting"
                            filled
                            dense
                          ></v-select
                        ></v-col>
                      </v-row>
                      <h4>4. Foto KTP dan Foto Selfie</h4>
                      <v-row dense>
                        <v-col cols="6" md="6">
                          <v-file-input
                            :rules="imageRulesKtp"
                            v-model="row.imageDataKtp"
                            accept=".png, .jpeg, .jpg"
                            hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                            prepend-icon=""
                            prepend-inner-icon="mdi-camera"
                            label="Upload Foto KTP"
                            filled
                            dense
                            @click:clearKtp="row.urlktp = ''"
                            @change="Preview_imageKtp(row)"
                          />
                          <v-card
                            align="center"
                            height="400"
                            elevation="0"
                            color="grey lighten-4"
                            tile
                          >
                            <v-img
                              max-height="400"
                              class="img"
                              :cover="true"
                              :src="row.urlktp"
                            ></v-img>
                          </v-card>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-file-input
                            :rules="imageRulesSelfie"
                            v-model="row.imageDataSelfie"
                            accept=".png, .jpeg, .jpg"
                            hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                            prepend-icon=""
                            prepend-inner-icon="mdi-camera"
                            label="Upload Foto Selfie"
                            filled
                            dense
                            @click:clearSelfie="row.urlselfie = ''"
                            @change="Preview_imageSelfie(row)"
                          />
                          <v-card
                            align="center"
                            height="400"
                            elevation="0"
                            color="grey lighten-4"
                            tile
                          >
                            <v-img
                              max-height="400"
                              :cover="true"
                              class="img"
                              :src="row.urlselfie"
                            ></v-img>
                          </v-card>
                        </v-col>
                      </v-row>
                      <br />
                    </v-container>
                  </v-card>
                </v-sheet>
                <br />
              </div>
            </div>

            <div v-else>
              <div v-for="row in textRows" :key="row.index">
                <v-sheet outlined color="grey" rounded>
                  <v-card elevation="0">
                    <v-col cols="6">
                      <div class="d-flex align-start mb-2">
                        <v-text-field
                          class="mr-1"
                          placeholder="Nomor Induk Kependudukan (NIK)"
                          filled
                          dense
                          :rules="nikRules"
                          v-model="row.ceknik"
                          maxlength="16"
                          :counter="16"
                          color="teal"
                        ></v-text-field>
                        <v-btn
                          :loading="loading"
                          :disabled="!valid"
                          dark
                          @click="cekWarga(row)"
                          outlined
                          color="teal"
                        >
                          CEK DATA
                        </v-btn>
                      </div>
                    </v-col>
                    <v-container>
                      <v-divider></v-divider>

                      <div v-if="row.ceknikst === true">
                        <div class="d-flex justify-space-between mb-4 mt-4">
                          <h4>Hasil Pencarian Data Individu</h4>
                          <v-btn small @click="deleteTextRow(row)" class="pink">
                            <v-icon small color="white"
                              >mdi-delete</v-icon
                            ></v-btn
                          >
                        </div>
                        <v-row dense>
                          <v-col cols="4"
                            ><v-text-field
                              color="teal"
                              label="Nomor Induk Kependudukan (NIK)"
                              filled
                              dense
                              maxlength="16"
                              :rules="nikRules"
                              v-model="row.nik"
                              :counter="16"
                              disabled
                            ></v-text-field>
                          </v-col>

                          <v-col cols="4">
                            <v-text-field
                              color="teal"
                              label="Nama Lengkap"
                              :rules="wajibRules"
                              v-model="row.nama"
                              filled
                              dense
                              disabled
                            ></v-text-field
                          ></v-col>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.jk"
                              :items="itemsjk"
                              item-text="jenis_kelamin"
                              item-value="jenis_kelamin"
                              color="teal"
                              label="Jenis Kelamin"
                              filled
                              dense
                              disabled
                            ></v-select
                          ></v-col>
                          <v-col cols="4">
                            <v-text-field
                              color="teal"
                              label="Tempat Lahir"
                              :rules="wajibRules"
                              v-model="row.tmpt_lahir"
                              filled
                              dense
                              disabled
                            ></v-text-field
                          ></v-col>
                          <v-col cols="4">
                            <v-text-field
                              color="teal"
                              label="Tanggal Lahir"
                              :rules="wajibRules"
                              v-model="row.tgl_lahir"
                              filled
                              dense
                              disabled
                            ></v-text-field
                          ></v-col>

                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.status_tinggal"
                              :items="itemsstatus_tinggal"
                              item-text="status_tinggal"
                              item-value="status_tinggal"
                              color="teal"
                              label="Status Tinggal"
                              filled
                              disabled
                            ></v-select
                          ></v-col>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.agama"
                              :items="itemsagama"
                              item-text="agama"
                              item-value="agama"
                              color="teal"
                              label="Agama"
                              filled
                              disabled
                              dense
                            ></v-select
                          ></v-col>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.status_kawin"
                              :items="itemskawin"
                              item-text="status_kawin"
                              item-value="status_kawin"
                              color="teal"
                              label="Status Perkawinan"
                              filled
                              dense
                              disabled
                            ></v-select
                          ></v-col>
                          <template v-if="row.status_kawin === 'Kawin'">
                            <v-col cols="4">
                              <v-text-field
                                color="teal"
                                label="Tahun Pernikahan"
                                :rules="wajibRules"
                                v-model="row.tahun_kawin"
                                filled
                                dense
                                disabled
                              ></v-text-field
                            ></v-col>
                          </template>
                          <v-col cols="4">
                            <v-text-field
                              color="teal"
                              label="Kewarganegaraan"
                              :rules="wajibRules"
                              v-model="row.kewarganegaraan"
                              filled
                              dense
                              disabled
                            ></v-text-field
                          ></v-col>

                          <v-col cols="4">
                            <v-select
                              v-on:change="
                                cekKeluarga(`${row.status_keluarga}`)
                              "
                              :rules="wajibRules"
                              v-model="row.status_keluarga"
                              :items="recipes"
                              item-text="name"
                              item-value="id"
                              color="teal"
                              label="Status Hubungan Keluarga"
                              filled
                              dense
                              required
                            ></v-select
                          ></v-col>
                        </v-row>
                        <br />
                        <h4 class="pb-3">Foto KTP dan Foto Selfie</h4>
                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-card
                              align="center"
                              height="400"
                              elevation="0"
                              color="grey lighten-4"
                              tile
                            >
                              <img
                                style="width: 100%; height: 420px"
                                v-bind:src="`https://apidata.satudatapariamankota.id/storage/FotoKTP/${row.foto_ktp}`"
                              />
                            </v-card>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-card
                              align="center"
                              height="400"
                              elevation="0"
                              color="grey lighten-4"
                              tile
                            >
                              <img
                                style="width: 100%; height: 420px"
                                v-bind:src="`https://apidata.satudatapariamankota.id/storage/FotoSelfie/${row.foto_selfie}`"
                              />
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else-if="row.ceknikst === false">
                        <h4 class="cyan--text">
                          Data Individu Tidak Ditemukan, Mohon Input Manual
                        </h4>
                        <div class="d-flex justify-space-between mb-4 mt-4">
                          <h4>1. Deskripsi Individu</h4>
                          <v-btn small @click="deleteTextRow(row)" class="pink">
                            <v-icon small color="white"
                              >mdi-delete</v-icon
                            ></v-btn
                          >
                        </div>
                        <v-row dense>
                          <v-col cols="4"
                            ><v-text-field
                              color="teal"
                              label="Nomor Induk Kependudukan (NIK)"
                              filled
                              dense
                              maxlength="16"
                              :rules="nikRules"
                              v-model="row.nik"
                              :counter="16"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="4">
                            <v-text-field
                              color="teal"
                              label="Nama Lengkap"
                              :rules="wajibRules"
                              v-model="row.nama"
                              filled
                              dense
                            ></v-text-field
                          ></v-col>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.jk"
                              :items="itemsjk"
                              item-text="jenis_kelamin"
                              item-value="jenis_kelamin"
                              color="teal"
                              label="Jenis Kelamin"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <v-col cols="4">
                            <v-text-field
                              color="teal"
                              label="Tempat Lahir"
                              :rules="wajibRules"
                              v-model="row.tmpt_lahir"
                              filled
                              dense
                            ></v-text-field
                          ></v-col>
                          <v-col cols="4">
                            <v-menu
                              ref="menu"
                              v-model="row.menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="row.tgl_lahir"
                                  :rules="wajibRules"
                                  label="Tanggal Lahir"
                                  readonly
                                  filled
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>

                              <v-date-picker
                                v-model="row.tgl_lahir"
                                :active-picker.sync="row.activePicker"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                                min="1900-01-01"
                                @change="save"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.status_tinggal"
                              :items="itemsstatus_tinggal"
                              item-text="status_tinggal"
                              item-value="status_tinggal"
                              color="teal"
                              label="Status Tinggal"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.agama"
                              :items="itemsagama"
                              item-text="agama"
                              item-value="agama"
                              color="teal"
                              label="Agama"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.status_kawin"
                              :items="itemskawin"
                              item-text="status_kawin"
                              item-value="status_kawin"
                              color="teal"
                              label="Status Perkawinan"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <template v-if="row.status_kawin === 'Kawin'">
                            <v-col cols="4">
                              <v-select
                                :rules="wajibRules"
                                v-model="row.tahun_kawin"
                                :items="years"
                                item-text="year"
                                item-value="year"
                                color="teal"
                                label="Tahun Pernikahan"
                                filled
                                dense
                              ></v-select
                            ></v-col>
                          </template>

                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.kewarganegaraan"
                              :items="itemskewarganegaraan"
                              item-text="status_warganegara"
                              item-value="status_warganegara"
                              color="teal"
                              label="Kewarganegaraan"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.hak_politik"
                              :items="itemsyatidak"
                              item-text="optional"
                              item-value="optional"
                              color="teal"
                              label="Hak Pilih Politik"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <v-col cols="4">
                            <v-select
                              v-on:change="
                                cekKeluarga(`${row.status_keluarga}`)
                              "
                              :rules="wajibRules"
                              v-model="row.status_keluarga"
                              :items="recipes"
                              item-text="name"
                              item-value="id"
                              color="teal"
                              label="Status Hubungan Keluarga"
                              filled
                              dense
                              required
                            ></v-select
                          ></v-col>
                        </v-row>
                        <h4>2. Deskripsi Pekerjaan dan Pendidikan</h4>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.status_pekerjaan"
                              :items="itemskerja"
                              item-text="kondisi_pekerjaan"
                              item-value="kondisi_pekerjaan"
                              color="teal"
                              label="Kondisi Pekerjaan"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <template v-if="row.status_pekerjaan === 'Bekerja'">
                            <v-col cols="4">
                              <v-select
                                :rules="wajibRules"
                                v-model="row.pekerjaan"
                                :items="itemspekerjaan"
                                item-text="pekerjaan"
                                item-value="pekerjaan"
                                color="teal"
                                label="Pilih Pekerjaan"
                                filled
                                dense
                              ></v-select
                            ></v-col>
                          </template>

                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.pendidikan"
                              :items="itemspendidikan"
                              item-text="pendidikan"
                              item-value="pendidikan"
                              color="teal"
                              label="Pendidikan tertinggi yang ditamatkan"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                        </v-row>

                        <h4>3. Deskripsi Kesehatan</h4>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.status_jkn"
                              :items="itemsyatidak"
                              item-text="optional"
                              item-value="optional"
                              color="teal"
                              label="Peserta Jaminan Kesehatan Nasional"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <template v-if="row.status_jkn === 'Ya'">
                            <v-col cols="4">
                              <v-select
                                :rules="wajibRules"
                                v-model="row.id_jkn"
                                :items="itemsid_jkn"
                                item-text="JKN"
                                item-value="JKN"
                                color="teal"
                                label="Pilih Jaminan Kesehatan Nasional"
                                filled
                                multiple
                                dense
                              ></v-select
                            ></v-col>
                          </template>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.penyakit"
                              :items="itemspenyakit"
                              item-text="penyakitcek"
                              item-value="penyakitcek"
                              color="teal"
                              label="Penyakit yang diderita 1 tahun terakhir"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <template v-if="row.penyakit === 'Ada'">
                            <v-col cols="4">
                              <v-select
                                :rules="wajibRules"
                                v-model="row.id_penyakit"
                                :items="itemsid_penyakit"
                                item-text="penyakit"
                                item-value="penyakit"
                                color="teal"
                                label="Pilih Penyakit yang diderita"
                                filled
                                multiple
                                dense
                              ></v-select
                            ></v-col>
                          </template>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.disabilitas"
                              :items="itemsyatidak"
                              item-text="optional"
                              item-value="optional"
                              color="teal"
                              label="Disabilitas"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                          <template v-if="row.disabilitas === 'Ya'">
                            <v-col cols="4">
                              <v-select
                                :rules="wajibRules"
                                v-model="row.id_disabilitas"
                                :items="itemsdisabilitas"
                                item-text="disabilitas"
                                item-value="disabilitas"
                                color="teal"
                                label="Pilih Disabilitas"
                                filled
                                multiple
                                dense
                              ></v-select
                            ></v-col>
                          </template>
                          <v-col cols="4">
                            <v-select
                              :rules="wajibRules"
                              v-model="row.stanting"
                              :items="itemsyatidak"
                              item-text="optional"
                              item-value="optional"
                              color="teal"
                              label="Stunting"
                              filled
                              dense
                            ></v-select
                          ></v-col>
                        </v-row>
                        <h4>4. Foto KTP dan Foto Selfie</h4>
                        <v-row dense>
                          <v-col cols="6" md="6">
                            <v-file-input
                              :rules="imageRulesKtp"
                              v-model="row.imageDataKtp"
                              accept=".png, .jpeg, .jpg"
                              hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                              prepend-icon=""
                              prepend-inner-icon="mdi-camera"
                              label="Upload Foto KTP"
                              filled
                              dense
                              @click:clearKtp="row.urlktp = ''"
                              @change="Preview_imageKtp(row)"
                            />
                            <v-card
                              align="center"
                              max-height="400"
                              elevation="0"
                              color="grey lighten-4"
                              tile
                            >
                              <v-img
                                max-height="400"
                                class="img"
                                :cover="true"
                                :src="row.urlktp"
                              ></v-img>
                            </v-card>
                          </v-col>
                          <v-col cols="6" md="6">
                            <v-file-input
                              :rules="imageRulesSelfie"
                              v-model="row.imageDataSelfie"
                              accept=".png, .jpeg, .jpg"
                              hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                              prepend-icon=""
                              prepend-inner-icon="mdi-camera"
                              label="Upload Foto Selfie"
                              filled
                              dense
                              @click:clearSelfie="row.urlselfie = ''"
                              @change="Preview_imageSelfie(row)"
                            />
                            <v-card
                              align="center"
                              max-height="400"
                              elevation="0"
                              color="grey lighten-4"
                              tile
                            >
                              <v-img
                                max-height="400"
                                :cover="true"
                                class="img"
                                :src="row.urlselfie"
                              ></v-img>
                            </v-card>
                          </v-col>
                        </v-row>
                        <br />
                      </div>
                      <div v-else>
                        <div class="d-flex justify-space-between mb-4 mt-4">
                          <h4 class="white--text">1.Deskripsi Individu</h4>
                          <v-btn small @click="deleteTextRow(row)" class="pink">
                            <v-icon small color="white"
                              >mdi-delete</v-icon
                            ></v-btn
                          >
                        </div>
                      </div>

                      <br />
                    </v-container>
                  </v-card>
                </v-sheet>
                <br />
              </div>
            </div>

            <div class="pa-2">
              <v-btn
                small
                class="white--text"
                @click="addTextRow()"
                color="indigo"
              >
                <v-icon color="white">mdi-plus</v-icon>Lainya
              </v-btn>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-btn @click="e1 = 1" outlined color="grey">
                <v-icon color="black" left>mdi-arrow-left</v-icon> Sebelumnya
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn
                dialog="true"
                :loading="loading"
                :disabled="!valid"
                @click="validate"
                type="submit"
                class="ma-2 white--text"
                color="teal"
              >
                Simpan Data
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-form>
      </v-stepper-items>
    </v-stepper>
    <br />

    <br />
  </v-container>
</template>

<script>
import axios from "axios";
import AuthService from "@/services/AuthService.js";

export default {
  name: "PendudukFormKel",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1950 },
        (value, index) => 1951 + index
      );
    },
  },
  data() {
    return {
      // CEK NIK
      ceknik: "",
      hasilcek: {},
      ceknikst: null,

      activePicker: null,
      date: null,
      menu: false,
      // DATE TGL LAHIR
      e1: 1,
      valid: true,
      // foto kk
      imageData: null,
      url: "",

      // foto ktp
      imageDataKtp: null,
      urlktp: "",

      // foto selfie
      imageDataSelfie: null,
      urlselfie: "",

      // ITEM DATA KK
      detailusers: {},
      itemstatuskk: {},
      itemsmiskin: {},
      itemsjenis: {},
      itemsdusun: {},
      // ITEM DATA INDIVIDU
      itemsjk: {},
      itemsstatus_tinggal: {},
      itemsagama: {},
      itemskawin: {},
      itemskewarganegaraan: {},
      itemsyatidak: {},
      itemskerja: {},
      itemspekerjaan: {},
      itemspendidikan: {},
      itemsid_jkn: {},
      itemspenyakit: [{ penyakitcek: "Ada" }, { penyakitcek: "Tidak" }],
      itemsid_penyakit: {},
      itemsdisabilitas: {},

      // FIELD DATA KK
      nokk: "",
      status_kk: "",
      status_miskin: "",
      jenis_kk: [{}],
      no_bangunan: "",
      provinsi: "",
      kota: "",
      kecamatan: "",
      kelurahan: "",
      dusun: "",
      id_dusun: [{}],

      // baru
      n_provinsi: "",
      n_kota: "",
      n_kecamatan: "",
      n_kelurahan: "",
      n_dusun: "",

      // VALIDASI

      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],
      jeniskkRules: [(v) => !!v || "Jenis Kartu Keluarga tidak boleh kosong"],
      statuskkRules: [(v) => !!v || "Status Kartu Keluarga tidak boleh kosong"],
      miskinRules: [(v) => !!v || "Status Kemiskinan tidak boleh kosong"],
      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      imageRules: [(v) => !!v || "Foto Kartu Keluarga tidak boleh kosong"],
      imageRulesKtp: [(v) => !!v || "Foto KTP tidak boleh kosong"],
      imageRulesSelfie: [(v) => !!v || "Foto Selfie tidak boleh kosong"],

      nikRules: [
        (v) => !!v || "NIK tidak boleh kosong",
        (v) => (v && v.length == 16) || "NIK harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "NIK harus angka",
      ],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],

      // FIELD DATA INDIVIDU
      rowType: "typeText",
      nik: "",
      nama: "",
      jk: "",
      tmpt_lahir: "",

      agama: "",
      status_kawin: "",
      tahun_kawin: "",
      kewarganegaraan: "",
      hak_politik: "",
      status_pekerjaan: "",
      pekerjaan: "",
      pendidikan: "",

      status_jkn: "",
      id_jkn: "",
      penyakit: "",
      id_penyakit: "",
      disabilitas: "",
      id_disabilitas: "",
      stanting: "",
      tgl_lahir: null,

      textRows: [
        {
          ceknik: "",
          ceknikst: "null",
          hasilcek: {},

          date: null,

          status_keluarga: "",
          tgl_lahir: null,
          activePicker: null,
          menu: false,
          nik: "",
          nama: "",
          jk: "",
          tmpt_lahir: "",

          status_tinggal: "",
          agama: "",
          status_kawin: "",
          tahun_kawin: "",
          kewarganegaraan: "",
          hak_politik: "",
          status_pekerjaan: "",
          pekerjaan: "",
          pendidikan: "",

          status_jkn: "",
          id_jkn: "",
          penyakit: "",
          id_penyakit: "",
          disabilitas: "",
          id_disabilitas: "",
          stanting: "",

          // foto ktp dan selfie
          // foto ktp
          valid: true,
          imageDataKtp: null,
          urlktp: "",

          // foto selfie
          imageDataSelfie: null,
          urlselfie: "",
        },
      ],

      removeTextRow: false,

      // dialog: false,
      // dialogDelete: false,

      message: "",
      loader: null,
      loading: false,
      sloading: true,
      firstLoad: true,

      recipes: [
        {
          id: 1,
          name: "KK",
          type: "kk",
          disabled: false,
        },
        {
          id: 2,
          name: "ISTRI",
          type: "istri",
        },
        {
          id: 3,
          name: "ANAK",
          type: "anak",
        },
        {
          id: 4,
          name: "MENANTU",
          type: "menantu",
        },
        {
          id: 5,
          name: "CUCU",
          type: "cucu",
        },
        {
          id: 6,
          name: "ORANG TUA",
          type: "ot",
        },
        {
          id: 7,
          name: "MERTUA",
          type: "mertua",
        },
        {
          id: 8,
          name: "FAMILI LAIN",
          type: "famili",
        },
      ],
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.row.activePicker = "YEAR"));
    },
  },
  methods: {
    // CEK NIK HASIL
    cekWarga: function (row) {
      const credentials = {
        nik: row.ceknik,
      };
      AuthService.getWargaById(credentials).then(
        function (response) {
          // this.textRows.hasilcek = response.data;

          this.textRows.forEach((cekisi) => {
            cekisi.hasilcek = response.data;
            row.ceknikst = response.success;
            if (row.ceknikst === true) {
              row.nik = response.data.nik;
              row.nama = response.data.nama;
              row.jk = response.data.jk;
              row.tmpt_lahir = response.data.tmpt_lahir;
              row.tgl_lahir = response.data.tgl_lahir;
              row.status_tinggal = response.data.status_tinggal;
              row.agama = response.data.agama;
              row.status_kawin = response.data.status_kawin;
              row.tahun_kawin = response.data.tahun_kawin;
              row.kewarganegaraan = response.data.kewarganegaraan;
              row.status_keluarga = "";
              row.foto_ktp = response.data.foto_ktp;
              row.foto_selfie = response.data.foto_selfie;

              // nOT tAMPIL
              row.hak_politik = response.data.hak_politik;
              row.status_pekerjaan = response.data.status_pekerjaan;
              row.pekerjaan = response.data.pekerjaan;
              row.pendidikan = response.data.pendidikan;
              row.status_jkn = response.data.status_jkn;
              row.id_jkn = response.data.id_jkn;
              row.penyakit = response.data.penyakit;
              row.id_penyakit = response.data.id_penyakit;
              row.disabilitas = response.data.disabilitas;
              row.id_disabilitas = response.data.id_disabilitas;
              row.status_meniggal = response.data.status_meniggal;
              row.stanting = response.data.stanting;

              row.valid = true;
              row.imageDataKtp = null;
              row.urlktp = "";
              row.imageDataSelfie = null;
              row.urlselfie = "";
            } else {
              row.nik = "";
              row.nama = "";
              row.jk = "";
              row.tmpt_lahir = "";
              row.tgl_lahir = "";
              row.status_tinggal = "";
              row.agama = "";
              // row.status_kawin = "";
              // row.tahun_kawin = "";
              row.kewarganegaraan = "";
              row.status_keluarga = "";

              // row.imageDataKtp = null;
              // row.urlktp = "";
              // row.imageDataSelfie = null;
              // row.urlselfie = "";
              // row.valid = true;
            }

            console.log(row.ceknikst);
          });

          // console.log(row);
          // console.log(row.ceknikst);
        }.bind(this)
      );
    },

    checkIsItemDisabled(item) {
      return item.id === 1;
    },
    cekKeluarga(e) {
      if (e.length == 0) {
        this.recipes.forEach((item) => (item.disabled = false));
      } else {
        let chosen = this.recipes.filter((item) => item.id == e[0]);

        // const newArray = this.textRows.filter((value, index) => {
        //   if (value.status_keluarga == 1) {
        //     console.log("kk");
        //   }
        // });

        this.textRows.forEach((cekisi) => {
          console.log(cekisi.status_keluarga);
        });

        this.recipes.forEach((item) => {
          if (chosen[0].id == this.textRows[0]["status_keluarga"]) {
            // chosen[0].disabled = false;
            // console.log(chosen[0].disabled);
            if (chosen[0].id == 1) {
              chosen[0].disabled = true;
              // console.log(chosen[0].disabled);
              console.log("iko kk");
            } else if (chosen[0].id == 2) {
              const newArray = this.textRows.filter((value, index) => {
                if (value.status_keluarga == 1) {
                  chosen[0].disabled = false;
                  console.log(value.status_keluarga);
                } else {
                  item.disabled = false;
                  console.log("iko istri");
                  console.log(chosen[0].id);
                }
              });
            }
          } else {
            if (chosen[0].id == 2 && this.textRows[0]["status_keluarga"] == 1) {
              // chosen[0].disabled = false;
              console.log("istri lanjut");
              // console.log(chosen[0].id);
            } else if (
              chosen[0].id == 3 &&
              this.textRows[0]["status_keluarga"] == 1
            ) {
              // chosen[0].disabled = false;
              console.log("anak lanjut");
              // console.log(chosen[0].id);
            } else {
              const newArray = this.textRows.filter((value, index) => {
                if (value.status_keluarga == 1) {
                  chosen[0].disabled = true;
                  console.log("kk");
                }
              });
            }
            //  else if (
            //   chosen[0].id == 1 &&
            //   this.textRows[0]["status_keluarga"] != 1
            // ) {
            //   // chosen[0].disabled = false;
            //   chosen[0].disabled = true;
            //   console.log("baned kk oi");
            //   // console.log(chosen[0].id);
            // }
          }
          // console.log(this.textRows[0]["status_keluarga"]);
          // console.log(chosen[0].id);
          // if (chosen[0].id == this.textRows[0]["status_keluarga"]) {
          //   if (chosen[0].id == 1) {
          //     chosen[0].disabled = false;
          //     // console.log(chosen[0].disabled);
          //     // console.log("iko kk");
          //   } else if (chosen[0].id == 2) {
          //     chosen[0].disabled = false;
          //   }
          //   // else if (
          //   //   chosen[0].id == 2 &&
          //   //   this.recipes[0]["disabled"] == true
          //   // ) {
          //   //   this.recipes[0]["disabled"] = true;
          //   //   // console.log(this.recipes[0]["disabled"]);
          //   // } else if (
          //   //   chosen[0].id == 2 &&
          //   //   this.recipes[0]["disabled"] == false
          //   // ) {
          //   //   this.recipes[0]["disabled"] = false;
          //   // }
          //   // chosen[0].disabled = true;
          //   // console.log(this.textRows[0]["status_keluarga"]);
          //   // console.log(chosen[0].id);
          //   // }
          //   //  else if (item.id == 2) {
          //   //   if (chosen[0].id == 2) {
          //   //     chosen[0].disabled = true;
          //   //   } else {
          //   //     item.disabled = false;
          //   //   }
          // } else {
          //   if (chosen[0].id == this.textRows[0]["status_keluarga"]) {
          //     // chosen[0].disabled = false;
          //     // console.log(chosen[0].disabled);
          //     console.log(" oke");
          //   } else {
          //     console.log(" ado isi");
          //   }
          //   // item.disabled = false;
          //   // console.log(chosen[0].disabled);
          // }
        });
      }

      // const items = this.textRows;
      // for (let i = 0; i < items.length; i++) {
      //   var hasil = `[${i}] [${items[i]["status_keluarga"]}]`;

      //   if (items[i]["status_keluarga"] == "KK") {
      //     console.log(hasil);
      //   } else {
      //     console.log("hasil");
      //   }
      //   // console.log(hasil);
      //   // console.log(items.length);
      // }

      // for (let i = 0; i < items.length; i++) {
      //   var aa = items[i]["status_keluarga"];
      //   // console.log(items[i]["status_keluarga"]);
      //   if (aa == "KK") {
      //     // console.log(aa);
      //     // for (let i = 0; i < this.itemkeluarga.length; i++) {
      //     //   for (let key of Object.keys(this.itemkeluarga[i])) {
      //     //     if (key === "disable") {
      //     //       this.itemkeluarga[0][key] = true;
      //     //     }
      //     //   }
      //     // }
      //   } else {
      //     console.log("bukan KK");
      //     // for (let i = 0; i < this.itemkeluarga.length; i++) {
      //     //   for (let key of Object.keys(this.itemkeluarga[i])) {
      //     //     if (key === "disable") {
      //     //       this.itemkeluarga[0][key] = false;
      //     //     }
      //     //   }
      //     // }
      //   }
      // }

      // if (items[i]["status_keluarga"] == "KK") {
      //   for (let i = 0; i < this.itemkeluarga.length; i++) {
      //     for (let key of Object.keys(this.itemkeluarga[i])) {
      //       if (key === "disable") {
      //         this.itemkeluarga[0][key] = true;
      //       }
      //     }
      //   }
      // } else if (items[i]["status_keluarga"] == "ISTRI") {
      //   for (let i = 0; i < this.itemkeluarga.length; i++) {
      //     for (let key of Object.keys(this.itemkeluarga[i])) {
      //       if (key == "disable") {
      //         this.itemkeluarga[1][key] = true;
      //       }
      //     }
      //   }
      // }
      // console.log(a);
      // this.cekkk = true;

      // else {
      //   for (let i = 0; i < this.itemkeluarga.length; i++) {
      //     for (let key of Object.keys(this.itemkeluarga[i])) {
      //       if (key == "disable") {
      //         this.itemkeluarga[0][key] = false;
      //         this.itemkeluarga[1][key] = false;
      //       }
      //     }
      //   }
      // }

      // else if (a == "ISTRI") {
      //   for (let i = 0; i < this.itemkeluarga.length; i++) {
      //     for (let key of Object.keys(this.itemkeluarga[i])) {
      //       if (key == "disable") {
      //         this.itemkeluarga[1][key] = true;
      //       }
      //     }
      //   }
      // }
      // else {
      //   for (let i = 0; i < this.itemkeluarga.length; i++) {
      //     for (let key of Object.keys(this.itemkeluarga[i])) {
      //       if (key == "disable") {
      //         this.itemkeluarga[0][key] = false;
      //         this.itemkeluarga[1][key] = false;
      //       }
      //     }
      //   }
      // }

      // console.log(this.itemkeluarga.disable);

      // (this.state = null), this.getStates();
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    // api status kk
    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            (this.provinsi = this.detailusers.provinsi),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },

    getDusun: function () {
      AuthService.getDusunByid().then(
        function (response) {
          this.itemsdusun = response.data.data;
          console.log(this.itemsdusun);
        }.bind(this)
      );
    },
    getStatusKK: function () {
      AuthService.getStatuKK().then(
        function (response) {
          this.itemstatuskk = response.data.data;
        }.bind(this)
      );
    },
    getStatusMiskin: function () {
      AuthService.getStatusMiskin().then(
        function (response) {
          this.itemsmiskin = response.data.data;
        }.bind(this)
      );
    },
    getJenisKK: function () {
      AuthService.getJenisKK().then(
        function (response) {
          this.itemsjenis = response.data.data;
        }.bind(this)
      );
    },

    getJK: function () {
      AuthService.getJK().then(
        function (response) {
          this.itemsjk = response.data.data;
        }.bind(this)
      );
    },
    status_tinggal: function () {
      AuthService.getStatusTinggal().then(
        function (response) {
          this.itemsstatus_tinggal = response.data.data;
        }.bind(this)
      );
    },
    getAgama: function () {
      AuthService.getAgama().then(
        function (response) {
          this.itemsagama = response.data.data;
        }.bind(this)
      );
    },
    getStatusPerkawinan: function () {
      AuthService.getStatusPerkawinan().then(
        function (response) {
          this.itemskawin = response.data.data;
        }.bind(this)
      );
    },
    getKewarganegaraan: function () {
      AuthService.getKewarganegaraan().then(
        function (response) {
          this.itemskewarganegaraan = response.data.data;
        }.bind(this)
      );
    },
    getYatidak: function () {
      AuthService.getYaTidak().then(
        function (response) {
          this.itemsyatidak = response.data.data;
        }.bind(this)
      );
    },
    getKerja: function () {
      AuthService.getKondisiPekerjaan().then(
        function (response) {
          this.itemskerja = response.data.data;
        }.bind(this)
      );
    },
    getpekerjaan: function () {
      AuthService.getDetailPekerjaan().then(
        function (response) {
          this.itemspekerjaan = response.data.data;
        }.bind(this)
      );
    },
    getPendidikan: function () {
      AuthService.getPendidikan().then(
        function (response) {
          this.itemspendidikan = response.data.data;
        }.bind(this)
      );
    },
    getJKN: function () {
      AuthService.getJKN().then(
        function (response) {
          this.itemsid_jkn = response.data.data;
        }.bind(this)
      );
    },
    getJenisPenyakit: function () {
      AuthService.getJenisPenyakit().then(
        function (response) {
          this.itemsid_penyakit = response.data.data;
        }.bind(this)
      );
    },
    getDisabilitas: function () {
      AuthService.getDisabelitas().then(
        function (response) {
          this.itemsdisabilitas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },

    addTextRow() {
      this.textRows.push({ ceknikst: null });
      if (this.textRows.length > 1) {
        this.removeTextRow = true;
      }
    },
    deleteTextRow(row) {
      if (this.textRows.length > 1) {
        this.removeTextRow = true;
        this.textRows.splice(this.textRows.indexOf(row), 1);
      }
      if (this.textRows.length <= 1) {
        this.removeTextRow = false;
      }
    },

    validate() {
      this.$refs.form.validate();
      this.e1 = 2;
    },
    async handleSubmit() {
      if (this.status_kk !== "Pemecahan") {
        this.loading = true;
        try {
          let formData = new FormData();
          formData.append("foto_kk", this.imageData);
          formData.append("nokk", this.nokk);
          formData.append("status_kk", this.status_kk);
          formData.append("jenis_kk", this.jenis_kk.jenis_kk);

          formData.append("status_miskin", this.status_miskin);
          // baru

          if (this.jenis_kk.id === 1) {
            formData.append("id_dusun", this.id_dusun.id);
            formData.append("provinsi", this.provinsi);
            formData.append("kota", this.kota);
            formData.append("kecamatan", this.kecamatan);
            formData.append("kelurahan", this.kelurahan);
            formData.append("dusun", this.id_dusun.dusun);
          } else if (this.jenis_kk.id === 2) {
            formData.append("id_dusun", 0);
            formData.append("provinsi", this.provinsi);
            formData.append("kota", this.kota);
            formData.append("kecamatan", this.kecamatan);
            formData.append("kelurahan", this.n_kelurahan);
            formData.append("dusun", this.n_dusun);
          } else if (this.jenis_kk.id === 3) {
            formData.append("id_dusun", 0);
            formData.append("provinsi", this.provinsi);
            formData.append("kota", this.kota);
            formData.append("kecamatan", this.n_kecamatan);
            formData.append("kelurahan", this.n_kelurahan);
            formData.append("dusun", this.n_dusun);
          } else {
            formData.append("id_dusun", 0);
            formData.append("provinsi", this.n_provinsi);
            formData.append("kota", this.n_kota);
            formData.append("kecamatan", this.n_kecamatan);
            formData.append("kelurahan", this.n_kelurahan);
            formData.append("dusun", this.n_dusun);
          }

          // array data
          const items = this.textRows;
          for (let i = 0; i < items.length; i++) {
            for (let key of Object.keys(items[i])) {
              formData.append(`data[${i}][${key}]`, items[i][key]);
            }
          }
          // untuk foto ktp dan foto selfie
          for (let i = 0; i < items.length; i++) {
            formData.append(`foto_ktp[${i}]`, items[i]["imageDataKtp"]);
            formData.append(`foto_selfie[${i}]`, items[i]["imageDataSelfie"]);
            console.log(`foto_ktp[${i}]`, items[i]["imageDataKtp"]);
          }

          const response = await AuthService.simpanWarga(formData);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/admin-kelurahan/penduduk/kk").catch(() => {});
            this.dialog = false;
            // this.secretMessage = await AuthService.getCamat();
            // this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();
            console.log("berhasil simpan");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } catch (error) {
          this.message = error.response.data.message;
          this.$notification.error(error.response.message, {
            timer: 5,
          });
          this.loading = false;
        }
      } else {
        this.loading = true;
        try {
          let formData = new FormData();
          formData.append("foto_kk", this.imageData);
          formData.append("nokk", this.nokk);
          formData.append("status_kk", this.status_kk);
          formData.append("jenis_kk", this.jenis_kk.jenis_kk);

          formData.append("status_miskin", this.status_miskin);
          // baru

          if (this.jenis_kk.id === 1) {
            formData.append("id_dusun", this.id_dusun.id);
            formData.append("provinsi", this.provinsi);
            formData.append("kota", this.kota);
            formData.append("kecamatan", this.kecamatan);
            formData.append("kelurahan", this.kelurahan);
            formData.append("dusun", this.id_dusun.dusun);
          } else if (this.jenis_kk.id === 2) {
            formData.append("id_dusun", 0);
            formData.append("provinsi", this.provinsi);
            formData.append("kota", this.kota);
            formData.append("kecamatan", this.kecamatan);
            formData.append("kelurahan", this.n_kelurahan);
            formData.append("dusun", this.n_dusun);
          } else if (this.jenis_kk.id === 3) {
            formData.append("id_dusun", 0);
            formData.append("provinsi", this.provinsi);
            formData.append("kota", this.kota);
            formData.append("kecamatan", this.n_kecamatan);
            formData.append("kelurahan", this.n_kelurahan);
            formData.append("dusun", this.n_dusun);
          } else {
            formData.append("id_dusun", 0);
            formData.append("provinsi", this.n_provinsi);
            formData.append("kota", this.n_kota);
            formData.append("kecamatan", this.n_kecamatan);
            formData.append("kelurahan", this.n_kelurahan);
            formData.append("dusun", this.n_dusun);
          }
          // array data
          const items = this.textRows;
          for (let i = 0; i < items.length; i++) {
            for (let key of Object.keys(items[i])) {
              formData.append(`data[${i}][${key}]`, items[i][key]);
            }
          }
          // untuk foto ktp dan foto selfie
          for (let i = 0; i < items.length; i++) {
            formData.append(`foto_ktp[${i}]`, items[i]["imageDataKtp"]);
            formData.append(`foto_selfie[${i}]`, items[i]["imageDataSelfie"]);
            console.log(`foto_ktp[${i}]`, items[i]["imageDataKtp"]);
          }

          const response = await AuthService.pecahKK(formData);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/admin-kelurahan/penduduk/kk").catch(() => {});
            this.dialog = false;
            // this.secretMessage = await AuthService.getCamat();
            // this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();
            console.log("berhasil simpan pemecahan");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } catch (error) {
          this.message = error.response.data.message;
          this.$notification.error(error.response.message, {
            timer: 5,
          });
          this.loading = false;
        }
      }
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.imageData);
    },
    Preview_imageKtp(row) {
      this.textRows.forEach((item) => {
        row.urlktp = URL.createObjectURL(row.imageDataKtp);
        // item.urlktp = URL.createObjectURL(item.imageDataKtp);
      });
    },

    Preview_imageSelfie(row) {
      this.textRows.forEach((item) => {
        row.urlselfie = URL.createObjectURL(row.imageDataSelfie);
      });
    },

    clear() {
      this.url = this.$refs.theFile = "";
    },
    clearKtp() {
      // this.textRows.forEach((item) => {
      //   item.urlktp = this.$refs.theFile = "";
      // });
      // this.urlktp = this.$refs.theFile = "";
    },
    clearSelfie() {
      this.urlselfie = this.$refs.theFile = "";
    },
  },
  created: function () {
    this.getStatusKK();
    this.getStatusMiskin();
    this.getJenisKK();
    this.detailUsers();
    this.getDusun();
    this.getJK();
    this.status_tinggal();
    this.getAgama();
    this.getStatusPerkawinan();
    this.getKewarganegaraan();
    this.getYatidak();
    this.getKerja();
    this.getpekerjaan();
    this.getPendidikan();
    this.getJKN();
    this.getJenisPenyakit();
    this.getDisabilitas();
  },
};
</script>
