<template>
  <v-container class="py-5 px-6" fluid>
    <v-card elevation="0" color="grey lighten-4">
      <v-toolbar elevation="0">
        <v-toolbar-title><h4>Data Penduduk</h4></v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- start modal dialog vuetyfy --->
        <v-dialog v-model="dialog" persistent max-width="1200px">
          <v-card>
            <v-card-title
              ><h5>{{ formTitle }}</h5>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              @submit.prevent="handleSubmit"
              v-model="valid"
              lazy-validation
              ref="form"
            >
              <v-card-text>
                <v-container class="mt-0">
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <v-text-field
                        color="teal"
                        label="Nomor KK"
                        filled
                        dense
                        maxlength="16"
                        :rules="nokkRules"
                        v-model="editedItem.nokk"
                        :counter="16"
                        required
                      ></v-text-field>
                      <v-text-field
                        color="teal"
                        label="Nomor Bangunan"
                        filled
                        dense
                        maxlength="6"
                        :rules="nobangRules"
                        v-model="editedItem.no_bangunan"
                        :counter="6"
                        required
                      ></v-text-field>
                      <v-row>
                        <v-col cols="6" md="6">
                          <v-select
                            :rules="statuskkRules"
                            v-model="editedItem.status_kk"
                            :items="itemstatuskk"
                            item-text="status_kk"
                            item-value="status_kk"
                            color="teal"
                            label="Status Kartu Keluarga (KK)"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-select
                            :rules="miskinRules"
                            v-model="editedItem.status_miskin"
                            :items="itemsmiskin"
                            color="teal"
                            item-text="status_miskin"
                            item-value="status_miskin"
                            label="Status Kemiskinan"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-select
                        :rules="jeniskkRules"
                        :items="itemsjenis"
                        v-model="editedItem.jenis_kk"
                        filled
                        item-text="jenis_kk"
                        item-value="jenis_kk"
                        label="Jenis Kartu Keluarga (KK)"
                        dense
                        color="teal"
                      ></v-select>

                      <v-row>
                        <v-col cols="6" md="6" sm="6">
                          <v-text-field
                            color="teal"
                            v-model="editedItem.kota"
                            :value="editedItem.kota"
                            label="Kabupaten / Kota"
                            filled
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-text-field
                            color="teal"
                            v-model="editedItem.kecamatan"
                            :value="editedItem.kecamatan"
                            label="Kecamatan"
                            filled
                            readonly
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" md="6" sm="6">
                          <v-text-field
                            color="teal"
                            v-model="editedItem.kelurahan"
                            :value="editedItem.kelurahan"
                            label="Kelurahan/Desa"
                            filled
                            dense
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-select
                            :rules="dusunRules"
                            v-model="editedItem.id_dusun"
                            :items="itemsdusun"
                            item-text="dusun"
                            item-value="id"
                            color="teal"
                            label="Pilih Dusun"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-file-input
                        :rules="imageRules"
                        v-model="imageData"
                        accept=".png, .jpeg, .jpg"
                        hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                        prepend-icon=""
                        prepend-inner-icon="mdi-camera"
                        label="Upload Foto KK"
                        filled
                        dense
                        @click:clear="url = ''"
                        @change="Preview_image"
                      />
                      <template v-if="this.url === ''">
                        <v-card
                          align="center"
                          class="col-7"
                          max-height="400"
                          elevation="0"
                          color="grey lighten-4"
                          tile
                        >
                          <img
                            v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoKK/${editedItem.ft_kk}`"
                            style="width: 100%; height: 400px"
                          />
                        </v-card>
                      </template>
                      <template v-else>
                        <v-card
                          align="center"
                          class="col-7"
                          max-height="400"
                          elevation="0"
                          color="grey lighten-4"
                          tile
                        >
                          <v-img
                            aspect-ratio="1.6"
                            :cover="true"
                            class="img"
                            :src="url"
                          ></v-img>
                        </v-card>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <small>(*) tidak boleh kosong</small>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="close" outlined color="grey">
                  Batal
                </v-btn>
                <v-btn
                  v-if="this.editedIndex === -1"
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Simpan Data
                </v-btn>
                <v-btn
                  v-else
                  dialog="true"
                  :loading="loading"
                  :disabled="!valid"
                  @click="validate"
                  type="submit"
                  class="ma-2 white--text"
                  color="teal"
                >
                  Update Data
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <!-- end modal dialog vuetyfy --->
      </v-toolbar>
    </v-card>

    <br />

    <v-skeleton-loader
      v-if="firstLoadhitung"
      :loading="sloadinghitung"
      type="article"
    ></v-skeleton-loader>
    <v-row dense>
      <v-col cols="4" v-for="item in hasilhitung" :key="item.id">
        <v-sheet outlined color="teal" elevation="0" rounded>
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-bold mb-4">{{ item.nama }}</div>
                <v-list-item-title class="text-h5 mb-1"
                  >{{ item.total }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card></v-sheet
        >
      </v-col>
    </v-row>
    <br />

    <v-dialog scrollable v-model="dialogpenduduk" persistent max-width="1000px">
      <v-card>
        <v-toolbar color="white" class="black--text">
          <v-row class="justify-space-between pe-3"
            ><strong class="pl-2"
              >Detail Penduduk : {{ editedItem.nama }}</strong
            >
            <v-btn
              small
              color="red"
              class="white--text"
              @click="dialogpenduduk = false"
              >X</v-btn
            ></v-row
          >
        </v-toolbar>

        <v-card-text>
          <h4 class="cyan--text pt-3 pb-2">1. Deskripsi Individu</h4>

          <v-row dense>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Nomor Induk Kependudukan (NIK)"
                :value="editedItem.nik"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Nama Lengkap"
                :value="editedItem.nama"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Jenis Kelamin"
                :value="editedItem.jk"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Tempat Lahir"
                :value="editedItem.tmpt_lahir"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Tanggal Lahir"
                :value="editedItem.tgl_lahir"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Status Tinggal"
                :value="editedItem.status_tinggal"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Agama"
                :value="editedItem.agama"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <template v-if="editedItem.tahun_kawin === null">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Perkawinan"
                  :value="editedItem.status_kawin"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="editedItem.tahun_kawin !== null">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Perkawinan"
                  :value="editedItem.status_kawin"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Tahun Pernikahan"
                  :value="editedItem.tahun_kawin"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Kewarganegaraan"
                :value="editedItem.kewarganegaraan"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Hak Pilih Politik"
                :value="editedItem.hak_politik"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <h4 class="cyan--text">2. Deskripsi Pekerjaan dan Pendidikan</h4>
          <br />
          <v-row dense>
            <template v-if="editedItem.status_pekerjaan === 'Bekerja'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Pekerjaan"
                  :value="editedItem.status_pekerjaan"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Pekerjaan"
                  :value="editedItem.pekerjaan"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="editedItem.status_pekerjaan !== 'Bekerja'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Status Pekerjaan"
                  :value="editedItem.status_pekerjaan"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>

            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Pendidikan tertinggi yang ditamatkan"
                :value="editedItem.pendidikan"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <h4 class="cyan--text">3. Deskripsi Kesehatan</h4>
          <br />
          <v-row dense>
            <template v-if="editedItem.status_jkn === 'Ya'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Peserta Jaminan Kesehatan Nasional"
                  :value="editedItem.status_jkn"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4" md="4">
                <!-- <v-select
                  v-model="editedItem.id_jkn"
                  :items="itemsjkn"
                  item-text="JKN"
                  item-value="JKN"
                  attach
                  chips
                  label="Chips"
                  multiple
                ></v-select> -->
                <v-select
                  v-model="editedItem.id_jkn"
                  :items="itemsjkn"
                  item-text="JKN"
                  item-value="JKN"
                  color="teal"
                  label="Jaminan Kesehatan Nasional"
                  filled
                  multiple
                  dense
                  readonly
                ></v-select
              ></v-col>
            </template>
            <template v-if="editedItem.status_jkn !== 'Ya'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Peserta Jaminan Kesehatan Nasional"
                  :value="editedItem.status_jkn"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>

            <template v-if="editedItem.penyakit === 'Ada'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Penyakit yang diderita 1 tahun terakhir"
                  :value="editedItem.penyakit"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Penyakit yang diderita"
                  :value="editedItem.id_penyakit"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="editedItem.penyakit !== 'Ada'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Penyakit yang diderita 1 tahun terakhir"
                  :value="editedItem.penyakit"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>

            <template v-if="editedItem.disabilitas === 'Ya'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Disabilitas"
                  :value="editedItem.disabilitas"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Data Disabilitas"
                  :value="editedItem.id_disabilitas"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="editedItem.disabilitas !== 'Ya'">
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Disabilitas"
                  :value="editedItem.disabilitas"
                  filled
                  dense
                  readonly
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="4" md="4"
              ><v-text-field
                color="teal"
                label="Stunting"
                :value="editedItem.stanting"
                filled
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <h4 class="cyan--text">4. Foto KTP dan Foto Selfie</h4>
          <br />
          <v-row dense>
            <v-col cols="12" md="6">
              <v-card color="grey lighten-4">
                <v-card-title class="layout justify-center pa-0">
                  <h5>Foto KTP</h5></v-card-title
                >
                <img
                  v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoKTP/${editedItem.foto_ktp}`"
                  style="width: 100%; height: 420px"
                />
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card color="grey lighten-4">
                <v-card-title class="layout justify-center pa-0">
                  <h5>Foto Selfie</h5></v-card-title
                >
                <img
                  v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoSelfie/${editedItem.foto_selfie}`"
                  style="width: 100%; height: 420px"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogEdit" persistent max-width="1000px">
      <v-form
        @submit.prevent="editPendudukSubmit"
        v-model="valid"
        lazy-validation
        ref="form"
      >
        <v-card>
          <v-card-title>Form Edit Data Penduduk</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <h4 class="cyan--text pt-3 pb-2">1. Deskripsi Individu</h4>

            <v-row dense>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  label="Nomor Induk Kependudukan (NIK)"
                  v-model="editedItem.nik"
                  filled
                  :rules="wajibRules"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4"
                ><v-text-field
                  color="teal"
                  :rules="wajibRules"
                  label="Nama Lengkap"
                  v-model="editedItem.nama"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.jk"
                  :items="itemsjk"
                  item-text="jenis_kelamin"
                  item-value="jenis_kelamin"
                  color="teal"
                  label="Jenis Kelamin"
                  filled
                  dense
                ></v-select>
              </v-col>

              <v-col cols="4" md="4"
                ><v-text-field
                  :rules="wajibRules"
                  color="teal"
                  label="Tempat Lahir"
                  v-model="editedItem.tmpt_lahir"
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.tgl_lahir"
                      :rules="wajibRules"
                      label="Tanggal Lahir"
                      readonly
                      filled
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="editedItem.tgl_lahir"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="4" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.status_tinggal"
                  :items="itemsstatus_tinggal"
                  item-text="status_tinggal"
                  item-value="status_tinggal"
                  color="teal"
                  label="Status Tinggal"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="4" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.agama"
                  :items="itemsagama"
                  item-text="agama"
                  item-value="agama"
                  color="teal"
                  label="Agama"
                  filled
                  dense
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.status_kawin"
                  :items="itemskawin"
                  item-text="status_kawin"
                  item-value="status_kawin"
                  color="teal"
                  label="Status Perkawinan"
                  filled
                  dense
                ></v-select
              ></v-col>
              <template v-if="editedItem.status_kawin === 'Kawin'">
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.tahun_kawin"
                    :items="years"
                    item-text="year"
                    item-value="year"
                    color="teal"
                    label="Tahun Pernikahan"
                    filled
                    dense
                  ></v-select
                ></v-col>
              </template>

              <v-col cols="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.kewarganegaraan"
                  :items="itemskewarganegaraan"
                  item-text="status_warganegara"
                  item-value="status_warganegara"
                  color="teal"
                  label="Kewarganegaraan"
                  filled
                  dense
                ></v-select
              ></v-col>
              <v-col cols="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.hak_politik"
                  :items="itemsyatidak"
                  item-text="optional"
                  item-value="optional"
                  color="teal"
                  label="Pilih Hak Politik"
                  filled
                  dense
                ></v-select
              ></v-col>
              <v-col cols="4">
                <v-select
                  v-on:change="cekKeluarga(`${row.status_keluarga}`)"
                  :rules="wajibRules"
                  v-model="editedItem.status_keluarga"
                  :items="recipes"
                  item-text="name"
                  item-value="id"
                  color="teal"
                  label="Status Hubungan Keluarga"
                  filled
                  dense
                ></v-select
              ></v-col>
            </v-row>
            <h4 class="cyan--text">2. Deskripsi Pekerjaan dan Pendidikan</h4>
            <br />
            <v-row dense>
              <v-col cols="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.status_pekerjaan"
                  :items="itemskerja"
                  item-text="kondisi_pekerjaan"
                  item-value="kondisi_pekerjaan"
                  color="teal"
                  label="Kondisi Pekerjaan"
                  filled
                  dense
                ></v-select
              ></v-col>
              <template v-if="editedItem.status_pekerjaan === 'Bekerja'">
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.pekerjaan"
                    :items="itemspekerjaan"
                    item-text="pekerjaan"
                    item-value="pekerjaan"
                    color="teal"
                    label="Pilih Pekerjaan"
                    filled
                    dense
                  ></v-select
                ></v-col>
              </template>

              <v-col cols="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.pendidikan"
                  :items="itemspendidikan"
                  item-text="pendidikan"
                  item-value="pendidikan"
                  color="teal"
                  label="Pendidikan tertinggi yang ditamatkan"
                  filled
                  dense
                ></v-select
              ></v-col>
            </v-row>
            <h4 class="cyan--text">3. Deskripsi Kesehatan</h4>
            <br />
            <v-row dense>
              <v-col cols="4" md="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.status_jkn"
                  :items="itemsyatidak"
                  item-text="optional"
                  item-value="optional"
                  color="teal"
                  label="Peserta Jaminan Kesehatan Nasional"
                  filled
                  dense
                ></v-select>
              </v-col>
              <template v-if="editedItem.status_jkn === 'Ya'">
                <v-col cols="4">
                  <v-select
                    v-model="editedItem.id_jkn"
                    :items="itemsjkn"
                    item-text="JKN"
                    item-value="JKN"
                    filled
                    dense
                    label="Pilih Jaminan Kesehatan Nasional"
                    multiple
                  ></v-select>
                </v-col>
              </template>
              <v-col cols="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.penyakit"
                  :items="itemspenyakit"
                  item-text="penyakitcek"
                  item-value="penyakitcek"
                  color="teal"
                  label="Penyakit yang diderita 1 tahun terakhir"
                  filled
                  dense
                ></v-select
              ></v-col>
              <template v-if="editedItem.penyakit === 'Ada'">
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.id_penyakit"
                    :items="itemsid_penyakit"
                    item-text="penyakit"
                    item-value="penyakit"
                    color="teal"
                    label="Pilih Penyakit yang diderita"
                    filled
                    multiple
                    dense
                  ></v-select
                ></v-col>
              </template>

              <v-col cols="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.disabilitas"
                  :items="itemsyatidak"
                  item-text="optional"
                  item-value="optional"
                  color="teal"
                  label="Disabilitas"
                  filled
                  dense
                ></v-select
              ></v-col>
              <template v-if="editedItem.disabilitas === 'Ya'">
                <v-col cols="4">
                  <v-select
                    :rules="wajibRules"
                    v-model="editedItem.id_disabilitas"
                    :items="itemsdisabilitas"
                    item-text="disabilitas"
                    item-value="disabilitas"
                    color="teal"
                    label="Pilih Disabilitas"
                    filled
                    multiple
                    dense
                  ></v-select
                ></v-col>
              </template>

              <v-col cols="4">
                <v-select
                  :rules="wajibRules"
                  v-model="editedItem.stanting"
                  :items="itemsyatidak"
                  item-text="optional"
                  item-value="optional"
                  color="teal"
                  label="stanting"
                  filled
                  dense
                ></v-select
              ></v-col>
            </v-row>
            <h4 class="cyan--text">4. Foto KTP dan Foto Selfie</h4>
            <br />
            <v-row dense>
              <v-col cols="12" md="6">
                <v-file-input
                  v-model="imageDataKtp"
                  accept=".png, .jpeg, .jpg"
                  hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                  prepend-icon=""
                  prepend-inner-icon="mdi-camera"
                  label="Update Foto KTP"
                  filled
                  dense
                  @click:clear="urlktp = ''"
                  @change="Preview_imageKtp"
                />
                <template v-if="urlktp === ''">
                  <v-card
                    align="center"
                    class="col-7"
                    max-height="400"
                    elevation="0"
                    color="grey lighten-4"
                    tile
                  >
                    <img
                      v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoKTP/${editedItem.foto_ktp}`"
                      style="width: 100%; height: 400px"
                    />
                  </v-card>
                </template>
                <template v-else>
                  <v-card
                    align="center"
                    class="col-7"
                    max-height="400"
                    elevation="0"
                    color="grey lighten-4"
                    tile
                  >
                    <v-img
                      aspect-ratio="1.6"
                      :cover="true"
                      class="img"
                      :src="urlktp"
                    ></v-img>
                  </v-card>
                </template>
              </v-col>

              <v-col cols="12" md="6">
                <v-file-input
                  v-model="imageDataSelfie"
                  accept=".png, .jpeg, .jpg"
                  hint="File Format (.png, .jpg, .jpeg) File Size (max: 2mb)"
                  prepend-icon=""
                  prepend-inner-icon="mdi-camera"
                  label="Update Foto Selfie"
                  filled
                  dense
                  @click:clear="urlselfie = ''"
                  @change="Preview_imageSelfie"
                />
                <template v-if="urlselfie === ''">
                  <v-card
                    align="center"
                    class="col-7"
                    max-height="400"
                    elevation="0"
                    color="grey lighten-4"
                    tile
                  >
                    <img
                      v-bind:src="`https://apidata.satudatapariamankota.id/public/FotoSelfie/${editedItem.foto_selfie}`"
                      style="width: 100%; height: 400px"
                    />
                  </v-card>
                </template>
                <template v-else>
                  <v-card
                    align="center"
                    class="col-7"
                    max-height="400"
                    elevation="0"
                    color="grey lighten-4"
                    tile
                  >
                    <v-img
                      aspect-ratio="1.6"
                      :cover="true"
                      class="img"
                      :src="urlselfie"
                    ></v-img>
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined @click="closeEdit">Batal</v-btn>

            <v-btn
              dialogMeninggal="true"
              :loading="loading"
              :disabled="!valid"
              @click="validate"
              type="submit"
              class="white--text"
              color="teal"
              >Update Data</v-btn
            >
            <br />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>

        <v-col cols="12" md="4" class="mb-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            hide-details
            color="teal"
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>

      <v-dialog v-model="dialogDelete" max-width="400px">
        <v-card>
          <v-card-title
            ><h5>Apakah anda yakin untuk mengahpus data ?</h5>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-list-item-content>
              <v-list-item-title>
                <h6 class="teal--text">NIK</h6></v-list-item-title
              >
              <v-list-item-subtitle>
                <h3 class="teal--text">
                  {{ editedItem.nik }}
                </h3></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>
                <h6 class="teal--text">Nama Lengkap</h6></v-list-item-title
              >
              <v-list-item-subtitle>
                <h3 class="teal--text">
                  {{ editedItem.nama }}
                </h3></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-spacer></v-spacer>

            <br />
            <p class="red--grey" small>
              peringatan : jika anda menghapus data penduduk, akan berpengaruh
              ke data seperti : data penduduk, data anggota kartu keluarga
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
            <v-btn outlined color="red" text @click="deleteItemConfirm()"
              >Hapus Data</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogMeninggal" persistent max-width="500px">
        <v-form
          @submit.prevent="meninggalSubmit"
          v-model="valid"
          lazy-validation
          ref="form"
        >
          <v-card>
            <v-toolbar color="white" class="black--text">
              <strong class="pl-2">Form Penduduk Meninggal</strong>
            </v-toolbar>
            <v-card-text>
              <v-row dense class="pt-5">
                <v-col cols="12" md="12"
                  ><v-text-field
                    color="teal"
                    label="Nomor Induk Kependudukan (NIK)"
                    :value="editedItem.nik"
                    filled
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12"
                  ><v-text-field
                    color="teal"
                    label="Nama Lengkap"
                    :value="editedItem.nama"
                    filled
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12"
                  ><v-text-field
                    color="teal"
                    label="Tanggal Lahir"
                    :value="editedItem.tgl_lahir"
                    filled
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.tgl_meninggal"
                        :rules="wajibRules"
                        label="Tanggal Meninggal"
                        readonly
                        filled
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      v-model="editedItem.tgl_meninggal"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" outlined @click="dialogMeninggal = false"
                >Batal</v-btn
              >

              <v-btn
                dialogMeninggal="true"
                :loading="loading"
                :disabled="!valid"
                @click="validate"
                type="submit"
                class="white--text"
                color="teal"
                >Proses</v-btn
              >
              <br />
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-skeleton-loader
        v-if="firstLoad"
        :loading="sloading"
        type="table"
      ></v-skeleton-loader>

      <v-data-table
        v-else
        :headers="headers"
        :items="hasildatas"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center">
            <v-btn
              class="lighten-4 mx-1"
              @click="showdetailPenduduk(item)"
              outlined
              small
              color="cyan"
            >
              <v-icon dense center> mdi-information </v-icon>
            </v-btn>

            <v-btn
              class="lighten-4 mx-1"
              @click="meninggalItem(item)"
              outlined
              small
              color="black"
            >
              <v-icon dense center> mdi-grave-stone </v-icon>
            </v-btn>

            <v-btn
              class="lighten-4 mx-1"
              @click="editItem(item)"
              outlined
              small
              color="primary"
            >
              <v-icon dense center> mdi-pencil </v-icon>
            </v-btn>
            <v-btn @click="deleteItem(item)" outlined small color="error">
              <v-icon dense center> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService.js";

export default {
  name: "PendudukWarga",
  data() {
    return {
      // UPDATE FOTO KTP
      imageDataKtp: null,
      urlktp: "",
      imageDataSelfie: null,
      urlselfie: "",

      // TANGGAL LAHIR

      hasildatas: [],
      // TANGGAL MATI
      activePicker: null,
      date: null,
      menu: false,
      tgl_meninggal: "",

      // IMage Preview KK
      imageData: null,
      url: "",

      // GET DATA EDIT
      itemstatuskk: {},
      itemsmiskin: {},
      itemsjenis: {},
      itemsdusun: {},
      detailusers: {},
      itemsjk: {},
      status_kk: "",
      status_miskin: "",
      jenis_kk: "",
      kota: "",
      kecamatan: "",
      kelurahan: "",
      id_dusun: "",
      // itemsss
      itemsstatus_tinggal: {},
      itemsagama: {},
      itemskawin: {},
      itemskewarganegaraan: {},
      itemsyatidak: {},
      itemskerja: {},
      itemspekerjaan: {},
      itemspendidikan: {},
      itemsid_jkn: {},
      itemspenyakit: [{ penyakitcek: "Ada" }, { penyakitcek: "Tidak" }],
      itemsid_penyakit: {},
      itemsdisabilitas: {},

      // GET DATA HITUNG
      hasilhitung: [],
      sloadinghitung: true,
      firstLoadhitung: true,

      // GET DATA WARGA KK

      itemsjkn: {},
      items: ["foo", "bar", "fizz", "buzz"],
      value: ["foo", "bar", "fizz", "buzz"],
      sloading: true,
      firstLoad: true,

      // DIALOG ANGGOTA KELUARGA

      // DIALOG PENDUDUK
      dialogpenduduk: false,

      dialog: false,
      dialogDelete: false,
      dialogMeninggal: false,
      dialogEdit: false,

      search: "",
      provinsi: "",

      valid: true,

      loader: null,
      loading: false,

      // VALIDASI KK
      nokkRules: [
        (v) => !!v || "No Kartu Keluarga tidak boleh kosong",
        (v) => (v && v.length == 16) || "No Kartu Keluarga harus 16 digit",
        (v) => Number.isInteger(Number(v)) || "No Kartu Keluarga harus angka",
      ],
      imageRules: [(v) => !!v || "Foto Kartu Keluarga tidak boleh kosong"],
      imageRulesKtp: [(v) => !!v || "Foto KTP tidak boleh kosong"],
      imageRulesSelfie: [(v) => !!v || "Foto Selfie tidak boleh kosong"],

      jeniskkRules: [(v) => !!v || "Jenis Kartu Keluarga tidak boleh kosong"],
      statuskkRules: [(v) => !!v || "Status Kartu Keluarga tidak boleh kosong"],
      miskinRules: [(v) => !!v || "Status Kemiskinan tidak boleh kosong"],
      nobangRules: [
        (v) => !!v || "No Bangunan tidak boleh kosong",
        (v) => (v && v.length <= 6) || "No Bangunan tidak boleh lebih 6 digit",
        (v) => Number.isInteger(Number(v)) || "No Bangunan harus angka",
      ],
      dusunRules: [(v) => !!v || "Dusun tidak boleh kosong"],
      wajibRules: [(v) => !!v || "bidang ini tidak boleh kosong"],

      namaKecRules: [(v) => !!v || "Nama provinsi tidak boleh kosong"],

      headers: [
        { text: "No Kartu Keluarga", value: "nokk" },
        {
          text: "NIK",
          value: "nik",
        },
        { text: "Nama Lengkap", value: "nama" },
        { text: "Jenis Kelamin", value: "jk" },
        { text: "Hubungan Keluarga", value: "StatusKeluarga" },

        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      editedIndex: -1,
      editedItem: {
        id: 0,
        provinsi: "",
        nokk: "",
        id_dusun: "",
        status_kk: "",
        status_miskin: "",
        no_bangunan: "",
        jenis_kk: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
        tgl_meninggal: "",
        menu: false,

        //
        nik: "",
        nama: "",
        jk: "",
        tmpt_lahir: "",
        tgl_lahir: "",
        status_tinggal: "",
        agama: "",
        status_keluarga: "",
        status_kawin: "",
        tahun_kawin: "",
        kewarganegaraan: "",
        hak_politik: "",
        status_pekerjaan: "",
        pekerjaan: "",
        pendidikan: "",
        status_jkn: "",
        id_jkn: "",
        penyakit: "",
        id_penyakit: "",
        disabilitas: "",
        id_disabilitas: "",
        stanting: "",
      },
      defaultItem: {
        provinsi: "",
        nokk: "",
        id_dusun: "",
        status_kk: "",
        status_miskin: "",
        no_bangunan: "",
        jenis_kk: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
        tgl_meninggal: "",
        menu: false,
      },
      recipes: [
        {
          id: "1",
          name: "Kepala Keluarga",
          type: "kk",
          disabled: false,
        },
        {
          id: "2",
          name: "Istri",
          type: "istri",
        },
        {
          id: "3",
          name: "Anak",
          type: "anak",
        },
        {
          id: "4",
          name: "Menantu",
          type: "menantu",
        },
        {
          id: "5",
          name: "Cucu",
          type: "cucu",
        },
        {
          id: "6",
          name: "Orang Tua",
          type: "ot",
        },
        {
          id: "7",
          name: "Mertua",
          type: "mertua",
        },
        {
          id: "8",
          name: "Famili Lain",
          type: "famili",
        },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Form Tambah Data KK"
        : "Form Edit Data Kartu Keluarga";
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1950 },
        (value, index) => 1951 + index
      );
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  created() {
    this.getPenduduk();
    this.getStsWarga();
    this.getJK();
    this.status_tinggal();
    this.getAgama();
    this.getStatusPerkawinan();
    this.getKewarganegaraan();
    this.getYatidak();
    this.getKerja();
    this.getpekerjaan();
    this.getPendidikan();
    this.getJKN();
    this.getJenisPenyakit();
    this.getDisabilitas();
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.imageData);
    },
    Preview_imageKtp(e) {
      this.urlktp = URL.createObjectURL(this.imageDataKtp);
    },
    Preview_imageSelfie(e) {
      this.urlselfie = URL.createObjectURL(this.imageDataSelfie);
    },
    clear() {
      this.url = this.$refs.theFile = "";
    },
    getJKN: function () {
      AuthService.getJKN().then(
        function (response) {
          this.itemsjkn = response.data.data;
        }.bind(this)
      );
    },
    getPenduduk: function () {
      AuthService.getDataWargaAll().then(
        function (response) {
          this.hasildatas = response.data.data;
          this.firstLoad = false;
          this.sloading = false;
        }.bind(this)
      );
    },
    getStsWarga: function () {
      AuthService.dataStatistikWarga().then(
        function (response) {
          this.hasilhitung = response.data.data;
          this.firstLoadhitung = false;
          this.sloadinghitung = false;
        }.bind(this)
      );
    },

    detailUsers: function () {
      AuthService.detailUsers().then(
        function (response) {
          this.detailusers = response.data.data;
          (this.kota = this.detailusers.kota),
            (this.kecamatan = this.detailusers.kecamatan),
            (this.kelurahan = this.detailusers.kelurahan),
            console.log(this.detailusers.provinsi);
        }.bind(this)
      );
    },
    getJK: function () {
      AuthService.getJK().then(
        function (response) {
          this.itemsjk = response.data.data;
        }.bind(this)
      );
    },
    status_tinggal: function () {
      AuthService.getStatusTinggal().then(
        function (response) {
          this.itemsstatus_tinggal = response.data.data;
        }.bind(this)
      );
    },

    getAgama: function () {
      AuthService.getAgama().then(
        function (response) {
          this.itemsagama = response.data.data;
        }.bind(this)
      );
    },
    getStatusPerkawinan: function () {
      AuthService.getStatusPerkawinan().then(
        function (response) {
          this.itemskawin = response.data.data;
        }.bind(this)
      );
    },
    getKewarganegaraan: function () {
      AuthService.getKewarganegaraan().then(
        function (response) {
          this.itemskewarganegaraan = response.data.data;
        }.bind(this)
      );
    },
    getYatidak: function () {
      AuthService.getYaTidak().then(
        function (response) {
          this.itemsyatidak = response.data.data;
        }.bind(this)
      );
    },
    getKerja: function () {
      AuthService.getKondisiPekerjaan().then(
        function (response) {
          this.itemskerja = response.data.data;
        }.bind(this)
      );
    },
    getpekerjaan: function () {
      AuthService.getDetailPekerjaan().then(
        function (response) {
          this.itemspekerjaan = response.data.data;
        }.bind(this)
      );
    },
    getPendidikan: function () {
      AuthService.getPendidikan().then(
        function (response) {
          this.itemspendidikan = response.data.data;
        }.bind(this)
      );
    },
    getJenisPenyakit: function () {
      AuthService.getJenisPenyakit().then(
        function (response) {
          this.itemsid_penyakit = response.data.data;
        }.bind(this)
      );
    },
    getDisabilitas: function () {
      AuthService.getDisabelitas().then(
        function (response) {
          this.itemsdisabilitas = response.data.data;
        }.bind(this)
      );
    },

    editItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    meninggalItem(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogMeninggal = true;
    },

    cekKeluarga(e) {
      if (e.length == 0) {
        this.recipes.forEach((item) => (item.disabled = false));
      } else {
        let chosen = this.recipes.filter((item) => item.id == e[0]);

        this.textRows.forEach((cekisi) => {
          console.log(cekisi.status_keluarga);
        });

        this.recipes.forEach((item) => {
          if (chosen[0].id == this.textRows[0]["status_keluarga"]) {
            if (chosen[0].id == 1) {
              chosen[0].disabled = true;
              // console.log(chosen[0].disabled);
              console.log("iko kk");
            } else if (chosen[0].id == 2) {
              const newArray = this.textRows.filter((value, index) => {
                if (value.status_keluarga == 1) {
                  chosen[0].disabled = false;
                  console.log(value.status_keluarga);
                } else {
                  item.disabled = false;
                  console.log("iko istri");
                  console.log(chosen[0].id);
                }
              });
            }
          } else {
            if (chosen[0].id == 2 && this.textRows[0]["status_keluarga"] == 1) {
              // chosen[0].disabled = false;
              console.log("istri lanjut");
              // console.log(chosen[0].id);
            } else if (
              chosen[0].id == 3 &&
              this.textRows[0]["status_keluarga"] == 1
            ) {
              // chosen[0].disabled = false;
              console.log("anak lanjut");
              // console.log(chosen[0].id);
            } else {
              const newArray = this.textRows.filter((value, index) => {
                if (value.status_keluarga == 1) {
                  chosen[0].disabled = true;
                  console.log("kk");
                }
              });
            }
          }
        });
      }
    },
    showdetailPenduduk(item) {
      this.editedIndex = this.hasildatas.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogpenduduk = true;
      console.log(item.nama);
    },

    async deleteItemConfirm() {
      console.log(this.editedItem.id);

      const credentials = {
        id: this.editedItem.id,
      };
      const response = await AuthService.deleteWarga(credentials);
      this.message = response.message;
      if (response.success === true) {
        this.$notification.success(response.message, {
          timer: 5,
        });
        this.hasildatas.splice(this.editedIndex, 1);
        this.getPenduduk();
        this.getStsWarga();
        this.$router.push("/admin-kelurahan/pendudukwarga").catch(() => {});

        this.dialogDelete = false;
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
      }
      this.closeDelete();
    },
    closeEdit() {
      this.dialogEdit = false;
      this.urlktp = "";
      this.urlselfie = "";
      this.$refs.form.reset();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      console.log("ini");
    },

    validate() {
      this.$refs.form.validate();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        // proses simpan atau update

        if (this.editedIndex === -1) {
          //proses simpan
          const credentials = {
            provinsi: this.editedItem.provinsi,
          };
          const response = await AuthService.postCamat(credentials);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/dashboard/provinsi").catch(() => {});
            this.dialog = false;
            this.secretMessage = await AuthService.getCamat();
            this.hasildatas = this.secretMessage.data.data;
            this.$refs.form.reset();
            console.log("berhasil simpan");
            console.log(this.secretMessage.data);
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
          }
        } else {
          //proses EDIT KK
          let formData = new FormData();
          formData.append("id", this.editedItem.id);
          formData.append("foto_kk", this.imageData);
          formData.append("nokk", this.editedItem.nokk);
          formData.append("status_kk", this.editedItem.status_kk);
          formData.append("jenis_kk", this.editedItem.jenis_kk);
          formData.append("no_bangunan", this.editedItem.no_bangunan);
          formData.append("id_dusun", this.editedItem.id_dusun);
          formData.append("status_miskin", this.editedItem.status_miskin);

          const response = await AuthService.editKK(formData);
          this.message = response.message;
          if (response.success === true) {
            this.$notification.success(response.message, {
              timer: 5,
            });

            this.loading = false;
            this.$router.push("/admin-kelurahan/penduduk/kk").catch(() => {});
            this.dialog = false;
            this.secretMessage = await AuthService.getDataWargaAll();
            this.hasildatas = this.secretMessage.data.data;
            this.secretMessagedua = await AuthService.dataStatistikWarga();
            this.hasilhitung = this.secretMessagedua.data.data;
            this.$refs.form.reset();

            console.log("berhasil UPDATE KK");
          } else {
            this.$notification.error(response.message, {
              timer: 5,
            });

            this.loading = false;
            // this.$refs.form.reset();
          }
        }
      } catch (error) {
        this.message = error.response.data.message;
        this.$notification.error(error.response.message, {
          timer: 5,
        });
        this.loading = false;
      }
    },

    async meninggalSubmit() {
      this.loading = true;

      console.log(this.editedItem.id);
      const credentials = {
        id: this.editedItem.id,
        datetime: this.editedItem.tgl_meninggal,
      };
      const response = await AuthService.meniggalWarga(credentials);
      this.message = response.message;
      if (response.success === true) {
        this.$notification.success(response.message, {
          timer: 5,
        });

        this.loading = false;
        this.$router.push("/admin-kelurahan/pendudukwarga").catch(() => {});
        this.dialogMeninggal = false;

        this.getPenduduk();
        this.getStsWarga();

        this.$refs.form.reset();
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
        this.close();
      }
    },

    async editPendudukSubmit() {
      console.log(this.editedItem.id);
      this.loading = true;

      let formData = new FormData();
      formData.append("id", this.editedItem.id);
      formData.append("foto_ktp", this.imageDataKtp);
      formData.append("foto_selfie", this.imageDataSelfie);

      formData.append("nik", this.editedItem.nik);
      formData.append("nama", this.editedItem.nama);
      formData.append("jk", this.editedItem.jk);
      formData.append("tmpt_lahir", this.editedItem.tmpt_lahir);
      formData.append("tgl_lahir", this.editedItem.tgl_lahir);
      formData.append("status_tinggal", this.editedItem.status_tinggal);

      formData.append("agama", this.editedItem.agama);
      formData.append("status_keluarga", this.editedItem.status_keluarga);
      formData.append("status_kawin", this.editedItem.status_kawin);
      formData.append("tahun_kawin", this.editedItem.tahun_kawin);
      formData.append("kewarganegaraan", this.editedItem.kewarganegaraan);
      formData.append("hak_politik", this.editedItem.hak_politik);
      formData.append("status_pekerjaan", this.editedItem.status_pekerjaan);
      formData.append("pekerjaan", this.editedItem.pekerjaan);
      formData.append("pendidikan", this.editedItem.pendidikan);

      formData.append("status_jkn", this.editedItem.status_jkn);
      formData.append("id_jkn", this.editedItem.id_jkn);
      formData.append("penyakit", this.editedItem.penyakit);
      formData.append("id_penyakit", this.editedItem.id_penyakit);
      formData.append("disabilitas", this.editedItem.disabilitas);
      formData.append("id_disabilitas", this.editedItem.id_disabilitas);
      formData.append("stanting", this.editedItem.stanting);

      const response = await AuthService.editWarga(formData);
      this.message = response.message;
      if (response.success === true) {
        this.$notification.success(response.message, {
          timer: 5,
        });

        this.loading = false;
        this.getPenduduk();
        this.getStsWarga();
        this.$router.push("/admin-kelurahan/pendudukwarga").catch(() => {});
        this.dialogEdit = false;

        this.closeEdit();

        console.log("berhasil UPDATE Warga");
      } else {
        this.$notification.error(response.message, {
          timer: 5,
        });

        this.loading = false;
        // this.$refs.form.reset();
      }
    },
  },

  async mounted() {
    // this.initialize();
    // this.detailUsers();
    // this.getJKN();
    // Data Penduduk
    // this.secretMessage = await AuthService.getDataWargaAll();
    // this.hasildatas = this.secretMessage.data.data;
    // Hitung warga
    // this.secretMessagedua = await AuthService.dataStatistikWarga();
    // this.hasilhitung = this.secretMessagedua.data.data;
  },
};
</script>
