<template>
  <v-container centered class="pl-12 pr-12 pt-12">
    <v-row>
      <template>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/lahan"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              LAHAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/akses-jalan"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              AKSES JALAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/bangunan"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              BANGUNAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/bangunan/rumah-isi"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              RUMAH TINGGAL ISI <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/bangunan/rumah-kosong"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              RUMAH KOSONG <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/bangunan/wirausaha"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              WIRAUSAHA <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/bangunan/sarana-umum"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              SARANA UMUM <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/sppt-pbb"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              SPPT PBB <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/penduduk"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              KEPENDUDUKAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/umur"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              UMUR <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/kesehatan"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              KESEHATAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/pendidikan"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              PENDIDIKAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/sagasaja"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              SAGASAJA <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/bansos"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              BANTUAN SOSIAL <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/dasawisma"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              DASAWISMA PKK <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/posyandu"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              POSYANDU <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/pertanian"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              PERTANIAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/peternakan"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              PETERNAKAN <br />
            </h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" sm="6" xs="6">
          <v-card
            to="rumah-data/perikanan"
            class="mt-6 pl-3 pr-3 pb-8 pt-8 rounded-card"
          >
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img
                  max-height="50"
                  max-width="40"
                  src="@/assets/prm.png"
                ></v-img>
              </v-col>
            </v-row>
            <h5 class="text-center black--text text--accent-3 pt-4">
              PERIKANAN <br />
            </h5>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RumahData",
  data: () => {
    return {
      sloading: true,
    };
  },
  methods: {},
};
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}
</style>
