import { render, staticRenderFns } from "./StatistikData.vue?vue&type=template&id=4de20c18&scoped=true&"
import script from "./StatistikData.vue?vue&type=script&lang=js&"
export * from "./StatistikData.vue?vue&type=script&lang=js&"
import style0 from "./StatistikData.vue?vue&type=style&index=0&id=4de20c18&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de20c18",
  null
  
)

export default component.exports